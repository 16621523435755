import React from "react";

const SideNavWrapper = ({iconSVG, label, onClick, href="#", color=""}) => {
    return (
        <a className="hover-item d-flex flex-row p-3 align-items-center" onClick={onClick}>
            <div className="figr-icon">
                {iconSVG}
            </div>
            <div className="mx-3" style={{fontSize: "12px"}}>
                {label}
            </div>
        </a>
    )   
}

export default SideNavWrapper