import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";

const SenateTrading = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
    };
    const ENDPOINT = '/senate-trading/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState([]);
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);

    const [currentPage, setCurrentPage] = useState(1); // Current page state

  
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data
                    const status = responseData.status
                    const data = responseData.data

                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                    } else if (status === "error") {
                        setError(responseData.message)
                    }

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const currentResults = (data || []).slice(
                (currentPage - 1) * constants.TABLE_MAX_ROWS,
                currentPage * constants.TABLE_MAX_ROWS
            );
        
        // Calculate total pages
    const totalPages = Math.ceil(data.length / constants.TABLE_MAX_ROWS);

    // Change page
    const changePage = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePageInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            changePage(value);
        }
    };

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                    id={'symbol'}
                    ref={autoSuggestRef}
                    defaultValue={params.symbol}
                    handleSubmitCustom={requestData}
                />
                <WindowPieces.ConfigContainer>
                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                </WindowPieces.ConfigContainer>
            </WindowPieces.OptionsContainer>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    <>
                        <WindowPieces.Content>
                            <p className='text-primary fw-light px-2 py-1'>Viewing page {currentPage} of {totalPages}</p>
                            <ZenithTable1.Table
                                tablehead={
                                    <tr>
                                        <th>Transaction Type</th>
                                        <th>Transaction Date</th>
                                        <th>Date Received</th>
                                        <th>Amount</th>
                                        <th>Name</th>
                                        <th>Owner</th>
                                        <th>Filing URL</th>
                                    </tr>
                                }
                                tablebody={
                                    <>
                                        {currentResults.map((item, index)=>{
                                            return (
                                            <tr key={index}>
                                                <td>{item.type}</td>
                                                <td>{item.transactionDate}</td>
                                                <td>{item.dateRecieved}</td>
                                                <td>{item.amount}</td>
                                                <td>{item.firstName} {item.lastName}</td>
                                                <td>{item.owner}</td>
                                                <td><a style={{color: "var(--primary)"}}  target="_blank" rel="noopener noreferrer" href={item.link}>Filing URL</a></td>                                            
                                            </tr>
                                        )})}
                                    </>
                                }
                            />

                        </WindowPieces.Content>

                    
                        <WindowPieces.PageViewer 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            changePageFunc={changePage}
                            handlePageInput={handlePageInputChange}
                        />
                    </>
                 
                    
               
                    

              
                    
                </>
            )}
        </>
    );
};

export default SenateTrading;