import React, { useReducer } from "react";
import constants from "../../constants";
import getRootColorString from "../../functions/helpers/getRootColorString";

const UserPlanBadge = ({groupName}) => {
    const PREMIUM_COLOR = getRootColorString("--primary-c")
    const FREE_COLOR = '#212121'
    const ADMIN_COLOR = '#00FF00'

    const colorMap = {
        'level1_user': {
            bgColor: FREE_COLOR,
            textColor: 'white',
            title: 'FIGR Free',
        },
        'anonymous': {
            bgColor: FREE_COLOR,
            textColor: 'white',
            title: 'FIGR Free',
        },
        'level2_user': {
            bgColor: `${PREMIUM_COLOR}50`,
            textColor: PREMIUM_COLOR,
            title: 'FIGR Premium',
        },
        'admin': {
            bgColor: `${ADMIN_COLOR}50`,
            textColor: ADMIN_COLOR,
            title: 'Admin',
        }
    }

    const mappedObject = colorMap[groupName]
    return (
        <div className="text-nowrap py-1 px-2" style={{border: "none", borderRadius: '0px', background: mappedObject.bgColor, color: "var(--text)"}}>
            {mappedObject.title}
        </div>
    )
}

export default UserPlanBadge