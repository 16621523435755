import React from 'react';
import ReactDOM from 'react-dom';
import PremiumPromptModal from '../components/modals/PremiumPromptModal';

// Function to render the alert dynamically
export const showPremiumPrompt = (bodyMessage="Upgrade to Premium!") => {
    const promptContainer = document.createElement('div');
    document.body.appendChild(promptContainer);
    
    const closeModal = () => {
        ReactDOM.unmountComponentAtNode(promptContainer);
        document.body.removeChild(promptContainer);
    };

    ReactDOM.render(<PremiumPromptModal body={bodyMessage} closeModal={closeModal} />, promptContainer);
};

export default showPremiumPrompt