import App from './App';
import ReactDOM from 'react-dom';
import React from 'react';
import ReactGA from "react-ga4"

ReactGA.initialize('G-ZG5854K50L');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
