import features from "../catalogs/features"
import { showAlert } from "./showAlert";
import ReactGA4 from "react-ga4";

export default async function handleCommandEntry(command, symbol="AAPL", newWindow=true, existingWindowID) {

    const suggestion = features.find(feature => feature.code === command);
    
    ReactGA4.event({
        category: "User Commands",
        action: "Entered Command",
        label: `${symbol} ${command}`,
        value: 1  
    });

    if (suggestion && suggestion.handler) {
        if (newWindow){ 
            await suggestion.handler(symbol); 
        }
        else {
            await suggestion.handler(symbol, false, existingWindowID)
        }
    } else {
        console.error(`Unknown command: ${command}`);
        showAlert("error", `Unknown Command: ${command}`)
    }
}