import React from 'react';
import SitePieces from '../ui/small/SitePieces';
import Button1 from '../ui/small/buttons/Button1';
import Button2 from '../ui/small/buttons/Button2';

const CustomModal1B = ({ show, title, body, onClose, onConfirm, showCancel }) => {
    if (!show) return null;

    return (
        <div 
            className='p-0'
            style={{
                zIndex: 99,
                position: 'absolute',
                left: "0px",
                top: "0px",
                bottom: "0px",
                width: '100%',  
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflowY: 'auto',
                overflowX: "hidden",
                backgroundColor: "var(--theme)"
            }}
        >
            <div className='d-flex flex-column w-100 h-100 px-2 py-3'>
                <div>
                    {body}
                </div>
                <div className='d-flex flex-row-reverse my-3'>
                    <div className='m-1 d-flex'>
                        <Button1 onClick={onConfirm}>Confirm</Button1>
                    </div>
                    {showCancel && (
                        <div className='m-1'>
                            <Button2 onClick={onClose}>Cancel</Button2>
                        </div>
                    )}
                </div>
            </div>
            
        </div>
    );
};

export default CustomModal1B;
