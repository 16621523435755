import React, {useState, useEffect, useRef} from "react";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import LineChart from "../../charting/chartjs/LineChart";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import TearSheetSectionHeader from "../13_TearSheet/TearSheetSectionHeader";
import LineWrappers from "../13_TearSheet/LineWrappers";
import PieChart from "../../charting/chartjs/PieChart";

const ETFHoldings = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
    };
    const ENDPOINT = '/etf-holdings/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [data, setData] = useState({holdings: [], info: {}});
    const [initialRender, setInitialRender] = useState(true);

    const [currentPage, setCurrentPage] = useState(1); // Current page state

    const autoSuggestRef = useRef(null);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };

            updateLayoutItem(windowID, {
                windowParameters: params,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data
                    const status = responseData.status
                    const data = responseData.data
    
                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                        console.log(data)
                        setCurrentPage(1)
                    } else if (status === "error") {
                        setError(responseData.message)
                    }
    
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });

            return newParams
        })

        

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, [params.name]);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const holdings = data.holdings || [];
    const info = data.info || {};

    console.log(holdings)
    const currentResults = (holdings || []).slice(
                    (currentPage - 1) * constants.TABLE_MAX_ROWS,
                    currentPage * constants.TABLE_MAX_ROWS
                );
            
            // Calculate total pages
    const totalPages = Math.ceil(holdings.length / constants.TABLE_MAX_ROWS);

    // Change page
    const changePage = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePageInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            changePage(value);
        }
    };

    const formatLargeDollarFigure = (number) => {
        if (number >= 1000000000) {
            return `${(number / 1000000000).toFixed(2)}B`;
        } else if (number >= 1000000) {
            return `${(number / 1000000).toFixed(2)}M`;
        }
        return number.toFixed(0);
    };
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    
    const unavailable = <td></td>

    const sectorExposureChartContainer = useRef()

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                    id={'symbol'}
                    ref={autoSuggestRef}
                    defaultValue={params.symbol}
                    handleSubmitCustom={requestData}
                />
                <WindowPieces.ConfigContainer>
                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                </WindowPieces.ConfigContainer>
                    
                        
            </WindowPieces.OptionsContainer>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    <WindowPieces.Content>
                        <div className="container-fluid m-0 w-100 p-3">
                            <div className="row">
                                <h1>{params.symbol.toUpperCase()} - {info.name}</h1>
                                <div><p className="m-0">Description: {info.description}</p></div>
                            </div>
                            <hr></hr>
                            
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <TearSheetSectionHeader text="INFORMATION"/>
                                    <LineWrappers.LineWrapperNoFormatting name={"AUM"} value={formatLargeDollarFigure(info.aum)} />
                                    <LineWrappers.LineWrapperNoFormatting name={"NAV"} value={`${info.nav} (${info.navCurrency})`} />
                                    <LineWrappers.LineWrapperNoFormatting name={"# Holdings"} value={info.holdingsCount} />
                                    <LineWrappers.LineWrapperNoFormatting name={"Asset Class"} value={info.assetClass} />
                                    <LineWrappers.LineWrapperNoFormatting name={"Average Volume"} value={formatLargeDollarFigure(info.avgVolume)} />
                                    <LineWrappers.LineWrapperNoFormatting name={"Domicile"} value={info.domicile} />
                                    <LineWrappers.LineWrapperNoFormatting name={"ETF Company"} value={info.etfCompany} />
                                    <LineWrappers.LineWrapperNoFormatting name={"Expense Ratio"} value={info.expenseRatio} />
                                    <LineWrappers.LineWrapperNoFormatting name={"Inception Date"} value={info.inceptionDate} />
                                    <LineWrappers.LineWrapperNoFormatting name={"CUSIP"} value={info.cusip} />
                                    <LineWrappers.LineWrapperNoFormatting name={"ISIN"} value={info.isin} />
                                    
                                </div>

                                <div className="col-md-6">
                                    <div>
                                        <div className="d-flex justify-content-between mb-1">
                                            <TearSheetSectionHeader text="SECTOR EXPOSURE" />
                                        </div>
                                    </div>
                                    <div ref={sectorExposureChartContainer} className="d-flex justify-content-center align-items-center" style={{height: '200px'}}>
                                        <PieChart data={info.sectorsList} />
                                    </div>
                                </div>
                            </div>
                            <hr></hr>

                            <div className="row mt-2">
                                <p className='text-primary fw-light px-2 py-1'>Viewing page {currentPage}</p>
                                <ZenithTable1.Table 
                                    tablehead={
                                        <tr>
                                            <th>Symbol</th>
                                            <th>Name</th>
                                            <th># Shares</th>
                                            <th>Weight (%)</th>
                                            <th>Market Value</th>
                                            <th>Last Updated</th>
                                        </tr>
                                    }
                                    tablebody={
                                        <>
                                            {currentResults.map((item, index)=>{
                                
                                                return (
                                                <tr key={index}>
                                                    {item.asset? <td>{item.asset}</td>: unavailable}   
                                                    {item.name? <td>{item.name}</td>: unavailable}
                                                    {item.sharesNumber? <td>{item.sharesNumber}</td>: unavailable}
                                                    {item.weightPercentage? <td>{item.weightPercentage.toFixed(2)}</td>: unavailable}
                                                    {item.marketValue? <td>{formatLargeDollarFigure(item.marketValue)}</td>: unavailable}
                                                    {item.updated? <td>{item.updated}</td>: unavailable}                                      
                                                </tr>
                                            )})}
                                        </>
                                    }
                                />  
                              
                            </div>


                        </div>

                    </WindowPieces.Content>                 
                    <WindowPieces.PageViewer 
                        currentPage={currentPage}
                        totalPages={totalPages}
                        changePageFunc={changePage}
                        handlePageInput={handlePageInputChange}
                    />                 
                </>
            )}
        </>
    );
};

export default ETFHoldings;
