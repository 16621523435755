import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";

import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import constants from "../../../constants";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";

const EarningsTranscripts = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'year': 2024,
        'quarter': 4
    };
    const ENDPOINT = '/earnings-transcripts/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data
                    const status = responseData.status
                    const data = responseData.data

                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                    }

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }


    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                    id={'symbol'}
                    ref={autoSuggestRef}
                    defaultValue={params.symbol}
                    handleSubmitCustom={requestData}
                />
                <WindowPieces.ConfigContainer>
                    <WindowPieces.SquareInput 
                        id={'year'}
                        type={'number'}
                        placeholder={'Year'}
                        value={params.year}
                        onChange={updateParams}
                    />
                    <WindowPieces.SquareSelect
                        id={'quarter'}
                        innerHTMLList={['Q1', 'Q2', 'Q3', 'Q4']} 
                        valueList={[1, 2, 3, 4]}
                        value={params.quarter}
                        onChange={updateParams}

                    />   
                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                
                </WindowPieces.ConfigContainer>
            </WindowPieces.OptionsContainer>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error? (
                <WindowError message={error} />
            ) : (
                <>
                    


                    <WindowPieces.Content>
                            {data.map((paragraph, index)=>(
                                <p key={index} style={{backgroundColor: "var(--secondary)", color: "var(--text)"}} className='text-start shadow p-1 m-2'>{paragraph}</p>
                            ))}
                    </WindowPieces.Content>
                </>
            )}
        </>
    );
};

export default EarningsTranscripts;