const requestFullScreenMode = () => {
    const elem = document.documentElement; // Target the entire document

    if (elem.requestFullscreen) {
        elem.requestFullscreen(); // Standard method
    } else if (elem.webkitRequestFullscreen) { // Safari
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE/Edge
        elem.msRequestFullscreen();
    } else {
    }
}

export default requestFullScreenMode