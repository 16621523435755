import React from "react";

// const SettingLineWrapper = ({label, children}) => {
//     return (
//         <div className='row d-flex align-items-center mt-2'>
//             <div className="text-nowrap col-2">
//                 <label>{label}</label>
//             </div>
//             <div className="ms-3 col-2 d-flex align-items-center">
//                 {children}
//             </div>
//         </div>
//     )
// }
const SettingLineWrapper = ({ label, children }) => {
    return (
      <div className="row mb-3 align-items-center">
        <div className="col-12 col-sm-4 col-md-3">
          <label className="form-label mb-0">{label}</label>
        </div>
        <div className="col">
          {children}
        </div>
      </div>
    );
  };

export default SettingLineWrapper