import React from 'react';

const SuggestionListForSecurities = ({ securitySuggestions, handleSuggestionClick }) => {
    function getSuggestionTypeString(suggestionObject) {
        let suggestionTypeString = 'EQUITY';

        if (suggestionObject.suggestionType === 'security') {
            if (suggestionObject.exchangeShortName === 'ECONOMY') {
                suggestionTypeString = 'ECONOMICS'; 
            } else if (suggestionObject.exchangeShortName === 'CRYPTO') {
                suggestionTypeString = 'CRYPTO';
            } else if (suggestionObject.exchangeShortName === 'FOREX') {
                suggestionTypeString = 'FOREX';
            } else if (suggestionObject.exchangeShortName === "COMMODITY") {
                suggestionTypeString = "COMMODITY"
            }
        } else if (suggestionObject.suggestionType === 'command') {
            suggestionTypeString = 'FUNCTION'; // function is more clear than command
        }

        return suggestionTypeString;
    }

    return (
        <div 
            className='ms-1 border'
            style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                overflowY: 'auto',
                overflowX: 'hidden',
                maxWidth: '100%',
                width: '700px',
                zIndex: 1000,
                textAlign: 'left',
                backgroundColor: "var(--theme)",
                borderColor: "var(--secondary)",
                pointerEvents: 'auto'
            }} >
                <div>
                    {
                        securitySuggestions.length > 0 ? (
                            securitySuggestions.map((suggestionObject, index) => {
                                const suggestionTypeString = getSuggestionTypeString(suggestionObject);
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={suggestionObject.suggestionType} 
                                        data-suggestion-value={suggestionObject.symbol} 
                                        className="row suggestion-item p-1 d-flex align-items-center"
                                        tabIndex={0}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleSuggestionClick(suggestionObject.symbol, 'security');
                                        }}
                                        style={{
                                            color: "var(--text)",
                                            cursor: 'pointer',
                                            userSelect: 'none',
                                            pointerEvents: 'auto',
                                            position: 'relative',
                                            zIndex: 1001
                                        }}
                                        >
                                        <div className="col-3" style={{fontSize: "0.7rem"}}>
                                            <span className="fw-bold ">{suggestionObject.symbol}</span>
                                        </div>
                                        <div className="col-6" style={{fontSize: "0.7rem"}}>
                                            <span className='text-primary '>{suggestionObject.name}</span>
                                        </div>
                                        <div className="col-3 text-end " style={{fontSize: "0.7rem"}}>
                                            <span>{suggestionTypeString === "EQUITY"? `${suggestionTypeString}: ${suggestionObject.exchangeShortName}`: suggestionTypeString} </span>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{backgroundColor: "var(--theme)", color: "var(--negative-c)"}}>No Valid Symbol!</div>
                        )
                    }
                    
            </div> 
        </div>
    );
}

export default SuggestionListForSecurities;
