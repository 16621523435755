import React, { useState } from "react";
import FormattingComponents from "../../formatting/FormattingComponents";
import EmptyRow from "./EmptyRow";
import constants from "../../../constants";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import FigrTooltip from "../../ui/small/FigrTooltip";
import tooltipFinancialDefinitions from "./tooltipFinancialDefinitions";

const FinancialStatementRow = ({ onThClick, rowname, statementID, arrayType, array, isActive, activeRowIndex }) => {
    const [showChartIcon, setShowChartIcon] = useState(false)
    
    // Can Receive 6 different arrayTypes
    // 1. calculation1
    // 2. calculation2
    // 3. calculation3
    // 4. calculation4
    // 5. percent
    // 6. ratio
    // 7. nonnumber
    // 8. heading
    // 9. number (which is everything else)
    const INDENT_SIZE = 10
    const SPARK_LINES = false

    const cellStylingMap = {
        'percent': {fontStyle: 'italic'},
        'calculation': {borderTop: "1px solid var(--secondary)"},
        'calculation2': {borderTop: "1px solid var(--secondary)"},
        'calculation3': {borderTop: "1px solid var(--secondary)"},
    }

    const thStylingMap = {
        'calculation': {textIndent: `${INDENT_SIZE*1}px`, fontWeight: 'bold', borderTop: "1px solid var(--secondary)"},
        'calculation2': {textIndent: `${INDENT_SIZE*2}px`, fontWeight: 'bold', borderTop: "1px solid var(--secondary)"},
        'calculation3': {textIndent: `${INDENT_SIZE*3}px`, fontWeight: 'bold', borderTop: "1px solid var(--secondary)"},
        'percent': {fontStyle: 'italic'},
        'heading': {color: 'var(--primary)', textDecoration: "underline"}
    }

    const plotTypeMap = {
        'percent': 'percent',
        'ratio': 'number',
        'number': 'number',
        'calculation': 'number',
        'calculation2': 'number',
        'calculation3': 'number',
    }

    // 1. Getting Mapped Style & data type for plotting
    const mappedCellStyle = cellStylingMap[arrayType]? cellStylingMap[arrayType]: {}
    const mappedThStyle = thStylingMap[arrayType]? thStylingMap[arrayType]: {}
    const mappedPlotType = plotTypeMap[arrayType]? plotTypeMap[arrayType]: 'no-plot'
    
    
    // 2. Creating Sparklines
    let cells = []
    const firstVal = array[0]
    const lastVal = array[array.length-1]
    const sparkLineColor = lastVal > firstVal? "var(--positive)": (lastVal < firstVal? "var(--negative)": 'white')
    const sparkLine = (
        <td style={{width: '100px', height: "10px"}}>
            <Sparklines style={{minWidth: '75px', height: '12px', padding: '0px'}} data={array} >
                <SparklinesLine color={sparkLineColor   } />
            </Sparklines>
        </td>
    )
    if (SPARK_LINES) {
        cells.push(sparkLine)
    }
    cells=cells.concat(array.map((cell, index)=>{
        let formattedCell;
        if (arrayType == 'percent') {
            formattedCell=<FormattingComponents.Percentage numDecimals={2} value={cell}/>
        }
        else if (arrayType == 'nonnumber') {
            formattedCell=cell
        }
        else if (arrayType == "heading") {
            formattedCell=""
        }
        else {
            formattedCell=<FormattingComponents.AccountingFormat numDecimals={2} value={cell}/>
        }
     
        return (
            <td style={{borderColor: "var(--secondary)", textAlign: "right", ...mappedCellStyle}}  className="text-nowrap p-0 border-start" key={index}>
                {formattedCell}
            </td>
        )
    }))

    const colorPallete = constants.getColorPallete()

    return (
        <tr onClick={()=>onThClick(statementID, rowname, array, mappedPlotType)}  data-series-type={mappedPlotType} onMouseOver={()=>setShowChartIcon(true)} onMouseLeave={()=>setShowChartIcon(false)}>
            <th 
                className={`position-relative p-0`} 
                style={{
                    borderRight: "1px solid var(--secondary)",
                    minWidth: "200px"
                }}
            >
                {isActive && (
                    <div style={{
                        position: 'absolute', 
                        top: 0, 
                        height: '100%', 
                        width: '5px', 
                        backgroundColor: colorPallete[activeRowIndex % colorPallete.length]
                    }}></div>
                )}
                
                <div className="ms-3 pe-4" style={{...mappedThStyle}}>  {/* Added pe-4 for right padding */}
                    {rowname}
                </div>

                {showChartIcon && !['nonnumber', 'heading'].includes(arrayType) && (
                    <div 
                        className="d-flex flex-row" 
                        style={{
                            position: 'absolute',
                            top: "50%",
                            right: "8px",
                            transform: "translateY(-50%)"  // Center vertically
                        }}
                    >
                        <div className="mx-2" title="Plot">
                            <svg style={{height: '12px', width: '12px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                                <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"/>
                            </svg>
                        </div>
                        <div style={{fill: "var(--text)", zIndex:"100"}}>
                            <FigrTooltip direction="right" maxWidth="400px">
                                <p className="text-wrap">{tooltipFinancialDefinitions[rowname] || "#N/A"}</p>
                            </FigrTooltip>
                        </div>
                    </div>
                )}
            </th>
            {cells}
        </tr>
    ) 
}

export default FinancialStatementRow