import React from "react";
import CloseWindowButton from "../ui/small/buttons/CloseWIndowButton";

const ScreenSizeWarning = ({ show, closeModal }) => {
  if (!show) return null;

  return (
    <>
      {/* Backdrop */}
      <div
        className="d-block d-sm-none"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
          zIndex: 999, // Ensure it's behind the modal but above other content
        }}
        onClick={closeModal} // Close modal when clicking on the backdrop
      ></div>

      {/* Modal */}
      <div
        style={{
          position: "fixed",
          backgroundColor: "var(--theme)",
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          color: "var(--text)",
          borderColor: "var(--secondary)",
          zIndex: 1001, // Ensure it's above the backdrop
        }}
        className="border d-block d-sm-none modal-content-animate modal-slide-up" // Visible only on small screens
      >
        <div className='w-100 d-flex flex-row-reverse justify-content-between align-items-center'>
            <CloseWindowButton onClick={closeModal} title='Close'/>
        </div>
    
        <div className="px-5" style={{ width: "400px", textAlign: "center" }}>
          <h1 style={{ fontWeight: "600", marginTop: "1em" }}>
            Screen Too Small!
          </h1>
          <div 
            style={{
                backgroundColor: `#ffd70050`, // Circle background color
                width: "120px",
                height: "120px",
                borderRadius: "50%", // Makes it a circle
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "3rem auto",
                position: "relative", // To position flames
                animation: "floatRocket 4s infinite ease-in-out",
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#ffd700" class="bi bi-laptop" viewBox="0 0 16 16">
                <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"/>
            </svg>
                        
        </div>
          <p style={{ marginTop: "1.5em", fontSize: "1.2rem" }}>
            FIGR Terminal is optimized for larger screens. Please switch to a
            device with a wider display for the best experience.
          </p>
        </div>

        <div
          className="d-flex flex-row justify-content-center gap-3"
          style={{ marginBlock: "2em" }}
        >
        </div>
      </div>
    </>
  );
};

export default ScreenSizeWarning;
