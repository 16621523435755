import React from 'react';

function WindowError({message}) {
    return (
        <div style={{fontSize: "14px", color: "var(--negative-c)", backgroundColor: "var(--theme)"}} className='d-flex justify-content-center fw-bold align-items-center w-100 h-100 p-5'>
            {message}
        </div>
    )
}

export default WindowError