import React, {useState, useEffect, useRef} from "react";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import { getGlobalLayout } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import handleTearSheet from "../../../functions/requestFuncs/handling/handleTearSheet"
import getRootColorString from "../../../functions/helpers/getRootColorString";
import handleCompanyNews from "../../../functions/requestFuncs/handling/handleCompanyNews";
import deferExecution from "../../../functions/deferExecution";
import WindowErrorReload from "../../misc/WindowErrorReload";

const TopGainersLosers = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
    
    };
    const ENDPOINT = '/top-gla/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    const tabs = [
        { label: "Most Volume", targetID: "most-volume" },
        { label: "Top Gainers", targetID: "top-gainers" },
        { label: "Top Losers", targetID: "top-losers" },
    ];
    const DEFAULT_TAB='most-volume'

    const positiveColor = getRootColorString("--positive")
    const negativeColor = getRootColorString("--negative")

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);
    const [activeTabID, setActiveTabID] = useState(DEFAULT_TAB)
    const [hoveredTicker, setHoveredTicker] = useState(null);

    const autoSuggestRef = useRef(null);
    const pollingInterval = useRef(null)

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

  

    const fetchData = () => {
        setError(null);
        
        
        constants.api.post(constants.BACKEND_URL+ENDPOINT, {}) // Use newParams here
            .then(response => {
                const responseData = response.data

                const status = responseData.status
                const data = responseData.data

                if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                    setError(responseData.message)
                    showPremiumPrompt(responseData.message)
                } else if (status === "success") {
                    setData(data)
                }
            })
            .catch(error => {
                setError("Failed to load data.");
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });


    }

    useEffect(() => {
        setLoading(true)
        fetchData();  // Initial request

        pollingInterval.current = setInterval(fetchData, 60000*2);
        
        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
            }
        };


    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const handleTabClick = (targetID) => {
        setActiveTabID(targetID); 
        
    };

    function formatTimestampToTime(timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time (0 becomes 12)
        const minutesPadded = minutes.toString().padStart(2, '0'); // Ensure two-digit minutes
    
        return `${hours}:${minutesPadded}${ampm}`;
    }
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    
    
    

    const tableHead = (
        <tr>
            <th className='text-nowrap'>Ticker</th>
            <th className='text-nowrap'>Last Price</th>
            <th className='text-nowrap'>Change</th>
            <th className='text-nowrap'>Change %</th>
        </tr>
    )

    const invalidSymbol = (
        <td style={{color: "var(--negative)"}} title="Error">#ERROR</td>
    )

    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowErrorReload message={error} reload={fetchData} />
                // <WindowError message={error} />
            ) : (
                <>
                    <WindowPieces.TabContainer tabs={tabs} activeTabID={activeTabID} onTabClick={handleTabClick} />
                    <WindowPieces.TabContent activeTabID={activeTabID}>
                        <div id="most-volume">
                            {/* <div style={{ width: "100%", maxWidth: "600px", height: "150px" }}>
                                <FIGRSparkLine data={Array.from({ length: 300 }, () => Math.random() * 100)} height={150} strokeColor="red" />
                            </div> */}
                            <ZenithTable1.Table 
                                tablehead={
                                    tableHead
                                }
                                tablebody={
                                    <>
                                        {data.topActives.map((stock, index)=> (
                                            <tr key={index}>
                                                <td
                                                    onMouseEnter={() => setHoveredTicker(stock.symbol)}
                                                    onMouseLeave={() => setHoveredTicker(null)}
                                                    style={{ position: 'relative', minWidth: "80px" }}
                                                    title={stock.name}
                                                >
                                                    {stock.symbol}
                                                    {hoveredTicker === stock.symbol && (
                                                        <span>
                                                            <span
                                                                title="News"
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '30px',
                                                                    color: 'var(--primary)',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => deferExecution(()=>handleCompanyNews(stock.symbol))}
                                                            >
                                                                N
                                                            </span>
                                                            <span
                                                                title="Tear Sheet"
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    color: 'var(--primary)',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => deferExecution(()=>handleTearSheet(stock.symbol))}
                                                            >
                                                                &#8594;
                                                            </span>
                                                        </span>
                                                            )
                                                    }
                                                   
                                                </td>
                                                {stock.price? <td title={formatTimestampToTime(data.timestamp*1000)}>{stock.price.toFixed(2)}</td>: invalidSymbol}
                                                {stock.change? <td title={formatTimestampToTime(data.timestamp*1000)} style={{ color: stock.change >= 0 ? positiveColor : negativeColor }}>{stock.change.toFixed(2)}</td>: invalidSymbol}
                                                {stock.changesPercentage? <td title={formatTimestampToTime(data.timestamp*1000)} style={{ color: stock.changesPercentage >= 0 ? positiveColor : negativeColor }}>{stock.changesPercentage.toFixed(2)}%</td>: invalidSymbol}
                                            </tr>
                                        ))}

                                    </>
                                }
                            
                            
                            />

                        </div>
                        <div id="top-gainers">
                            <ZenithTable1.Table 
                                    tablehead={
                                        tableHead
                                    }
                                    tablebody={
                                        <>
                                            {data.topGainers.map((stock, index)=> (
                                                <tr key={index}>
                                                    <td
                                                        onMouseEnter={() => setHoveredTicker(stock.symbol)}
                                                        onMouseLeave={() => setHoveredTicker(null)}
                                                        style={{ position: 'relative', minWidth: "80px" }}
                                                        title={stock.name}
                                                    >
                                                        {stock.symbol}
                                                        {hoveredTicker === stock.symbol && (
                                                                <span
                                                                    title="News"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '30px',
                                                                        color: 'var(--primary)',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleCompanyNews(stock.symbol)}
                                                                >
                                                                    N
                                                                </span>
                                                            )}
                                                        {hoveredTicker === stock.symbol && (
                                                            <span
                                                                title="Tear Sheet"
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    color: 'var(--primary)',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => handleTearSheet(stock.symbol)}
                                                            >
                                                                &#8594;
                                                            </span>
                                                        )}
                                                    </td>
                                                    {stock.price? <td title={formatTimestampToTime(data.timestamp*1000)}>{stock.price.toFixed(2)}</td>: invalidSymbol}
                                                    {stock.change? <td title={formatTimestampToTime(data.timestamp*1000)} style={{ color: stock.change >= 0 ? positiveColor : negativeColor }}>{stock.change.toFixed(2)}</td>: invalidSymbol}
                                                    {stock.changesPercentage? <td title={formatTimestampToTime(data.timestamp*1000)} style={{ color: stock.changesPercentage >= 0 ? positiveColor : negativeColor }}>{stock.changesPercentage.toFixed(2)}%</td>: invalidSymbol}
                                                </tr>
                                            ))}

                                        </>
                                    }
                                
                                
                                />
                        </div>
                        <div id="top-losers">
                            <ZenithTable1.Table 
                                        tablehead={
                                            tableHead
                                        }
                                        tablebody={
                                            <>
                                                {data.topLosers.map((stock, index)=> (
                                                    <tr key={index}>
                                                        <td
                                                            onMouseEnter={() => setHoveredTicker(stock.symbol)}
                                                            onMouseLeave={() => setHoveredTicker(null)}
                                                            style={{ position: 'relative', minWidth: "80px" }}
                                                            title={stock.name}
                                                        >
                                                            {stock.symbol}
                                                            {hoveredTicker === stock.symbol && (
                                                                <span
                                                                    title="News"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '30px',
                                                                        color: 'var(--primary)',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleCompanyNews(stock.symbol)}
                                                                >
                                                                    N
                                                                </span>
                                                            )}
                                                            {hoveredTicker === stock.symbol && (
                                                                <span
                                                                    title="Tear Sheet"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '10px',
                                                                        color: 'var(--primary)',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleTearSheet(stock.symbol)}
                                                                >
                                                                    &#8594;
                                                                </span>
                                                            )}
                                                            
                                                        </td>
                                                        {stock.price? <td title={formatTimestampToTime(data.timestamp*1000)}>{stock.price.toFixed(2)}</td>: invalidSymbol}
                                                        {stock.change? <td title={formatTimestampToTime(data.timestamp*1000)} style={{ color: stock.change >= 0 ? positiveColor : negativeColor }}>{stock.change.toFixed(2)}</td>: invalidSymbol}
                                                        {stock.changesPercentage? <td title={formatTimestampToTime(data.timestamp*1000)} style={{ color: stock.changesPercentage >= 0 ? positiveColor : negativeColor }}>{stock.changesPercentage.toFixed(2)}%</td>: invalidSymbol}
                                                    </tr>
                                                ))}

                                            </>
                                        }
                                    
                                    
                                    />
                        </div>
                    </WindowPieces.TabContent>
                                
                
                </>
            )}
        </>
    );
};

export default TopGainersLosers;
