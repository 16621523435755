import React, {useState, useEffect} from "react";

const Button1 = ({children, onClick, fullWidth=false}) => {
    const [textColor, setTextColor] = useState("white");

    useEffect(() => {
        const primaryColor = getComputedStyle(document.documentElement)
            .getPropertyValue("--primary")
            .trim();

        if (primaryColor) {
            const rgb = hexToRgb(primaryColor) || { r: 255, g: 255, b: 255 }; // Fallback to white
            const brightness = calculateBrightness(rgb.r, rgb.g, rgb.b);
            setTextColor(brightness > 128 ? "black" : "white");
        }
    }, []);

    // Helper: Convert HEX to RGB
    const hexToRgb = (hex) => {
        let formattedHex = hex;
        if (hex.startsWith("#")) formattedHex = hex.slice(1);

        if (formattedHex.length === 3) {
            formattedHex = formattedHex
                .split("")
                .map((char) => char + char)
                .join("");
        }

        const bigint = parseInt(formattedHex, 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    };

    // Helper: Calculate brightness
    const calculateBrightness = (r, g, b) => {
        return (r * 299 + g * 587 + b * 114) / 1000;
    };


    return (
        <button
            style={{
                backgroundColor: "var(--primary)",
                color: textColor,
            }}
            type="button"
            className={`text-nowrap rounded-0 btn ${fullWidth ? "w-100" : ""}`}
            onClick={onClick}
        >
            {children}
        </button>
    )
}  

export default Button1