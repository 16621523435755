import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import CustomModal2 from "../CustomModal2";
import ajaxRequestFuncs from "../../../functions/requestFuncs/ajaxRequestFuncs";
import { showAlert } from "../../../functions/showAlert";
import axios from "axios";
import SitePieces from "../../ui/small/SitePieces";
import Button2 from "../../ui/small/buttons/Button2";
import constants from "../../../constants";
import LoadingSpinnerMini from "../../misc/LoadingSpinnerMini";
import GoogleSignInButton from "../../ui/small/buttons/GoogleSignInButton";
import RedditSignInButton from "../../ui/small/buttons/RedditSignInButton";

const RegisterModal = ({show, setShow, setShowLogin}) => {
  const [formData, setFormData] = useState({
      username: '',
      email: '',
      password1: '',
      password2: '',
    });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken')
    setLoading(true);



    try {
      const response = await axios.post(constants.BACKEND_URL+'/auth/register/', formData, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        withCredentials: true,
      });
      
      setLoading(false);
      setShow(false);
      navigate(constants.REACT_APP_REDIRECT_ENDPOINT);
      setShowLogin(true);
      showAlert("success", "Account Successfully Created! Please log in.");

    } catch (error) {
      setLoading(false);
      if (error.response.data.redirect) {
        window.location.href = error.response.data.redirect;
        return;
      }
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
    }

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
      <CustomModal2 
          show={show}
          title={"Register"}
          body={
              <div style={{width: '400px'}}>
                  <form onSubmit={handleSubmit}>
                      <div className="mt-2">
                        <div>
                          <GoogleSignInButton />
                        </div>
                        <div className="mt-2">
                          <RedditSignInButton />
                        </div>
                      </div>
                      <hr></hr>
                      <div className="d-flex justify-content-center">
                        OR
                      </div>
                      <div className="mb-3">
                          
                          <label htmlFor="username">Username</label>
                          <SitePieces.ZenithInput
                          type={"text"}
                          id={"username"}
                          name={"username"}
                          value={formData.username}
                          onChange={handleChange}
                          placeholder={"Username"}
                          />
                          {errors.username && <div className="text-danger">{errors.username}</div>}
                      </div>

                      <div className="mb-3">
                          <label htmlFor="email">Email</label>
                          <SitePieces.ZenithInput
                          type={"email"}
                          id={"email"}
                          name={"email"}
                          value={formData.email}
                          onChange={handleChange}
                          placeholder={"Email"}
                          />
                          {errors.email && <div className="text-danger">{errors.email}</div>}
                      </div>
                  
                      <div className="mb-3">
                          <label htmlFor="password1">Password</label>
                          <SitePieces.ZenithInput
                          type={"password"}
                          id={"password1"}
                          name={"password1"}
                          value={formData.password1}
                          onChange={handleChange}
                          placeholder={"Password"}
                          />
                          {errors.password1 && <div className="text-danger">{errors.password1}</div>}
                      </div>

                      <div className="mb-3">
                          <label htmlFor="password2">Confirm Password</label>
                          <SitePieces.ZenithInput
                          type={"password"}
                          id={"password2"}
                          name={"password2"}
                          value={formData.password2}
                          onChange={handleChange}
                          placeholder={"Confirm Password"}
                          />
                          {errors.password2 && <div className="text-danger">{errors.password2}</div>}
                      </div>
                      <Button2 onClick={handleSubmit} fullWidth={true} isDisabled={loading}>
                        {loading ? <LoadingSpinnerMini /> : "Register"}
                      </Button2>
                  </form>
                  <div className="text-center my-3 border py-3" style={{borderColor: "var(--secondary)"}}>
                      <p className="m-0">Already have an account? <a style={{color: "var(--primary-c)"}} href="#" onClick={() => { setShow(false); setShowLogin(true); }}>Log In</a></p>
                  </div>
              </div>
          }
          onClose={()=>setShow(false)}
      />
  )
}

export default RegisterModal