import React from "react";
import constants from "../../../constants";

const verticalPadding = constants.WINDOWPIECES_VERTICAL_PADDING
const borderRadius = constants.WINDOWPIECES_BORDER_RADIUS


function ZenithInput({type, id, name, value, onChange, placeholder}) {
    return (
        <div className="m-0">
            <input  
            style={{
                borderRadius: borderRadius, 
                paddingBlock: verticalPadding,
                height: '30px',
                borderColor: "var(--secondary)",
                backgroundColor: "var(--theme)",
                color: "var(--text)"
            
            }} 
            autoComplete="off"
            type={type} 
            id={id} 
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className="form-control" 
            ></input>
        </div>
    )
}



function ZenithButtonPrimary({label, onClick}) {
    return (
        <button 
        className="btn btn-primary m-1 border-0" 
        style={{borderRadius: borderRadius, paddingBlock: verticalPadding}} 
        onClick={onClick}>
            {label}
        </button>
    )
}

function ZenithButtonSecondary({label, onClick}) {
    return (
        <button 
        className="btn btn-black m-1 border" 
        style={{borderRadius: borderRadius, paddingBlock: verticalPadding, borderColor: "var(--secondary)"}} 
        onClick={onClick}>
            {label}
        </button>
    )
}


export default {
    ZenithInput,
    ZenithButtonPrimary,
    ZenithButtonSecondary

}

