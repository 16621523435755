import React, { useState, forwardRef } from 'react';
import constants from '../../../constants';
import FigrIconWrapper from './FigrIconWrapper';

const verticalPadding = constants.WINDOWPIECES_VERTICAL_PADDING
const borderRadius = constants.WINDOWPIECES_BORDER_RADIUS


function LoadButton({label, onClick, fullWidth=false}) {
    return (
        <button className={`btn btn-primary load border-0 ${fullWidth? 'w-100': 'm-1'}  `} style={{height: '24px', borderRadius: borderRadius, paddingBlock: verticalPadding}} onClick={onClick}>{label}</button>
    )
}

function LoadButtonSecondary({label, onClick, fullWidth=false}) {
    return (
        <button className={`btn btn-secondary load border-0 ${fullWidth? 'w-100': ''}  `} style={{borderRadius: borderRadius, paddingBlock: verticalPadding}} onClick={onClick}>{label}</button>
    )
}

function SquareRadioButton({label, onClick}) {
    return (
        <button style={{ borderRadius: "0px", fontSize: '10px', height: '18px', padding: '1px', color: "var(--text)", borderColor: "var(--secondary)"}} className="btn btn-small border m-0" onClick={onClick}>
            {label}
        </button>

    )
    
}

function SquareInput({title='Periods', type, id, placeholder, defVal, value, onChange, onKeyDown, fullWidth=false}) {
    return (

        <input  
            title={title}    
            style={{
                height: '28px', 
                borderRadius: borderRadius, 
                paddingBlock: verticalPadding, 
                fontSize: '12px',
                borderColor: "var(--secondary)",
                backgroundColor: "var(--theme)",
                color: "var(--text)",
                width: `${fullWidth? '100%': ''}`
            
            }} 
            value={value || undefined} // Only pass value if it exists
            onChange={onChange} 
            onKeyDown={onKeyDown} 
            defaultValue={defVal || undefined} 
            type={type} 
            id={id} 
            min={0}
            max={100}
            className="me-1 submit-on-enter border" 
            // placeholder={placeholder}
            
            >
        </input>
      
 
    )
}

function SquareSelect({valueList, innerHTMLList, name, id, value, onChange, fullWidth=false}) {
    return (
  
        <select style={{
            height: '28px', 
            borderRadius: borderRadius, 
            paddingBlock: verticalPadding,
            borderColor: "var(--secondary)",
            backgroundColor: "var(--theme)",
            color: "var(--text)",
            width: `${fullWidth? '100%': ''}`
            
            }} onChange={onChange} value={value}  name={name} id={id} className="me-1 submit-on-enter border">
            {valueList.map((value, index) => (
                    <option key={index} value={value}>
                        {innerHTMLList[index]}
                    </option>
                ))}
        </select>

    )
}


function SquareOptionButton({label, value, onClick}) {
    return (
        <button className="mx-1 btn btn-outline-black m-1 hist-timeframe-btn border align-middle" value={value} style={{maxHeight:"32px", borderRadius: borderRadius, fontSize: "16px", padding: "1px 12px", maxHeight: "24px", color: "var(--text)", borderColor: "var(--secondary)"}} onClick={onClick}>{label}</button>
    )
}


function SquareWindowTab({ label, targetID }) {

    return (
        <button
            style={{color: "var(--text)"}}
            className="col btn-sm text-nowrap tab-switch-btn tab-link"
            data-target={targetID}
        
        >
            {label}
        </button>
    );
}




//////////////////////////////////////////////////////////////////////////// AFTER RESTRUCTURING

const OptionsContainer = ({ children, securitySearch=false }) => {
    return (
        <div style={{backgroundColor: "var(--theme)"}} className='position-relative d-flex flex-row justify-content-between border-bottom'>
            {children}
        </div>
    )
}

const ConfigContainer = ({ children }) => {
    return (
        <div className="d-flex flex-row align-items-center">
            {children}
        </div>
    )
}









const Tab = ({ label, targetID, onClick, isActive }) => {
    const styles = isActive? {
        fontWeight: "bold",
        borderBottom: "2px solid var(--primary)"
    }: {}

    return (
        <button
            style={{
                backgroundColor: "var(--theme)",
                color: "var(--text)",
                ...styles
            }}
            className={`py-1 btn-sm text-nowrap tab-switch-btn tab-link `}
            onClick={() => onClick(targetID)}
        >
            {label}
        </button>
    );
};

// TabContainer component to manage the tabs and content
const TabContainer = ({ tabs, activeTabID, onTabClick }) => {
    return (
        <div className='
        d-flex 
        flex-row 
        justify-content-around 
        tab-container
        border-dark2 
        border-bottom 
       '>
            {tabs.map(tab => (
                <Tab 
                    key={tab.targetID} 
                    label={tab.label} 
                    targetID={tab.targetID} 
                    onClick={onTabClick}
                    isActive={activeTabID === tab.targetID}
                />
            ))}
        </div>
    );
};

const TabContent = ({ children, activeTabID, useKey = false , paddingBottom}) => {

    return (
        <div 
        style={{paddingBottom: paddingBottom}}
        className="
        tab-content 
        overflow-x-auto 
        overflow-y-auto 
        flex-grow-1">
            {children.map((child, index) => {
                // Use child.key or child.id based on useKey
                const identifier = useKey ? child.key : child.props.id;
                return (
                    <div 
                        key={index} 
                        className={`tab-pane ${identifier === activeTabID ? 'd-block' : 'd-none'}`}
                    >
                        {child}
                    </div>
                );
            })}
        </div>
    );
};

const Content = forwardRef(({ children, paddingBottom }, ref) => {
    return (
        <div ref={ref} 
        style={{paddingBottom: paddingBottom}}
        className="
        overflow-x-auto 
        overflow-y-auto 
        flex-1
        h-100
        
        ">
            {children}
        </div>
    );
});

const ChartJSContainer = forwardRef(({ children, paddingBottom }, ref) => {
    return (
        <div ref={ref} 
        style={{paddingBottom: paddingBottom}}
        className="
        d-flex
        flex-column
        overflow-x-auto 
        overflow-y-auto 
        flex-1
        h-100
        
        ">
            {children}
        </div>
    );
});

const ChartContainer = forwardRef(({ children, paddingBottom }, ref) => {
    return (
        <div ref={ref} 
        style={{paddingBottom: paddingBottom}}
        className="
        
        overflow-x-hidden 
        overflow-y-hidden 
        flex-1
        h-100
        w-100
        ">
            {children}
        </div>
    );
});






const PageViewer = ({currentPage, totalPages=100, changePageFunc, handlePageInput}) => {
    return (
        <div style={{marginBottom: '0px'}} className="window-page-viewer d-flex justify-content-center align-items-center border-top border-dark2 p-2">
            <button 
                id='page-down' 
                style={{ width: "30px", aspectRatio: '1', borderRadius: "0px", borderColor: "var(--secondary)" }} 
                className="btn btn-small border me-2" 
                onClick={() => changePageFunc(currentPage - 1)}
                disabled={currentPage === 1} // Disable if on the first page
            >
                &larr;
            </button>
            <div
                className='text-center mx-2 figr-form-control d-flex flex-row align-items-center justify-content-center'
                style={{
                    backgroundColor: "var(--theme)",
                    borderColor: "var(--secondary)",
                    color: "var(--text)",
                    width: "30px",
                    aspectRatio: '1',
                    borderRadius: "0px"
                }}
            >
                {currentPage}
            </div>
            {/* <input
                type="number" 
                value={currentPage}
                style={{
                    backgroundColor: "var(--theme)",
                    borderColor: "var(--secondary)",
                    color: "var(--text)",
                    width: "30px", 
                    aspectRatio: '1', 
                    borderRadius: "0px"
                }}
                className="submit-on-enter figr-form-control text-center mx-2"
                placeholder="0"
                min="1"
                max={totalPages}
                onChange={handlePageInput}
            /> */}
            <button 
                id='page-up' 
                style={{ width: "30px", aspectRatio: '1', borderRadius: "0px", borderColor: "var(--secondary)"  }} 
                className="btn btn-small border ms-2" 
                onClick={() => changePageFunc(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable if on the last page
            >
                &rarr;
            </button>
        </div>
    )
}

const DownloadBox = ({onClick, title}) => {
    return (
        <div className='d-flex flex-row-reverse align-items-center' style={{padding: "5px", borderTop: "1px solid var(--secondary)"}}>
            <span onClick={onClick}>
                <FigrIconWrapper title={title}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"/>
                        <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"/>
                    </svg>
                </FigrIconWrapper>
            </span>
        </div>
    )
}









export default {
    SquareLoadButton: LoadButton, 
    SquareLoadButtonSecondary: LoadButtonSecondary,
    SquareRadioButton, 
    SquareInput, 
    SquareSelect, 
    SquareOptionButton, 
    SquareWindowTab, 
    OptionsContainer,
    ConfigContainer,
    Tab,
    TabContainer, 
    TabContent,
    ChartJSContainer,
    Content,
    ChartContainer,
    PageViewer,
    DownloadBox,
    borderRadius, 
    verticalPadding}