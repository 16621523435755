import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import constants from '../../../constants';
import axios from 'axios';
import CustomModal1 from '../../modals/CustomModal1';
import SettingLineWrapper from '../../ui/small/wrappers/settings/SettingLineWrapper';
import SettingsSection from '../../ui/small/wrappers/settings/SettingsSection';
import ColorPicker from '../../ui/small/ColorPicker';
import CheckBox from '../../ui/small/CheckBox';
import Button2 from '../../ui/small/buttons/Button2';
import SettingsConfigButton from '../../ui/small/buttons/SettingsConfigButton';
import getRootColorString from '../../../functions/helpers/getRootColorString';

function logWithTime(message) {
    const currentTime = new Date().toLocaleString();
    console.log(`[${currentTime}] ${message}`);
}

const LineAreaChart = ({ lineData, symbol, params, windowContainer, contentContainerRef }) => {
    const [showSettings, setShowSettings] = useState(false)

    /////////////////////////////////////////////////////////////////////////// CHART SETTINGS STATES AND HELPER FUNCTIONS
    const [currentSettings, setCurrentSettings] = useState({
        chartBackground: getRootColorString("--theme"),
        textColor: getRootColorString("--text"),
        lineColor: getRootColorString("--primary"),
        showArea: true,
        showGridLines: false,
        gridColor: 'white',
        showWatermark: false,
        watermarkColor: getRootColorString("--text")
    })
    const [adjustedSettings, setAdjustedSettings] = useState({
    })

    const setAdjustedSetting = (key, value) => {
        setAdjustedSettings((prev) => ({
            ...prev,
            [key]: value,
        }));
    };
    
    // Example setter functions
    const setLineColor = (color) => setAdjustedSetting('lineColor', color);
    const setShowArea = (bool) => setAdjustedSetting('showArea', bool);
    const setGridColor = (color) => setAdjustedSetting('gridColor', color);
    const setShowGridLines = (bool) => setAdjustedSetting('showGridLines', bool);
    const setShowWatermark = (bool) => setAdjustedSetting('showWatermark', bool);
    const setWatermarkColor = (color) => setAdjustedSetting('watermarkColor', color);

    /////////////////////////////////////////////////////////////////////////// CHART SETTINGS STATES AND HELPER FUNCTIONS



    const chartContainerRef = useRef();
    const chartRef = useRef(null); // Ref for chart instance
    const seriesRef = useRef(null); // Ref for chart instance
    const lineSeriesRef = useRef(null); // Ref for chart instance
    const pollingInterval = useRef(null);
    let resizeAnimationFrame;

    const [candlestickSeries, setCandlestickSeries] = useState(lineData);
    const [latestCandlestickData, setLatestCandlestickData] = useState(null);
    const [currentOHLC, setCurrentOHLC] = useState({
        time: lineData[lineData.length-1].time,
        value: lineData[lineData.length-1].value
    })

    const [tooltipValues, setTooltipValues] = useState({time: null, value: null})
   

    const initializeChart = () => {
        const chart = createChart(chartContainerRef.current, {
            rightPriceScale: {
                scaleMargins: {
                    top: 0.4, // leave some space for the legend
                    bottom: 0.15,
                },
            },
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            timeScale: {
                timeVisible: true,
                secondsVisible: false
            },
            layout: {
                background: { color: currentSettings.chartBackground },
                textColor: currentSettings.textColor,
                fontFamily: 'Inter',
            },
            grid: {
                vertLines: { visible: currentSettings.showGridLines, color: currentSettings.gridColor },
                horzLines: { visible: currentSettings.showGridLines, color: currentSettings.gridColor },
            },
            watermark: {
                visible: currentSettings.showWatermark,
                fontSize: 24,
                horzAlign: 'center',
                vertAlign: 'center',
                color: currentSettings.watermarkColor,
                text: symbol.toUpperCase(),
            },
            rightPriceScale: {
                minimumWidth: 30,
                visible: true,
            },
        });

        const lineSeries = chart.addLineSeries({
            color: currentSettings.lineColor,
            lineWidth: 1,
            lastValueVisible: false,
            priceLineVisible: false,
        });
        const series = chart.addAreaSeries({
            lastValueVisible: false, // hide the last value marker for this series
            crosshairMarkerVisible: false, // hide the crosshair marker for this series
            lineColor: "transparent", // hide the line
            topColor: currentSettings.showArea? currentSettings.lineColor: "transparent",
            bottomColor: currentSettings.showArea? `${currentSettings.lineColor}00`: "transparent",
          });

        lineSeriesRef.current=lineSeries
        seriesRef.current = series
        chartRef.current = chart; // Store chart in the ref

        const getLastBar = series => {
            const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
            return series.dataByIndex(lastIndex);
        };
        const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);
        const setTooltip = (time, value) => {
            setTooltipValues({
                time: time,
                value: formatPrice(value)
            })
        };

        const updateLegend = param => {
            const validCrosshairPoint = !(
                param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
            );
            const bar = validCrosshairPoint ? param.seriesData.get(series) : currentOHLC;
            const time = bar.time;
            const value = bar.value !== undefined ? bar.value : bar.value;

            setTooltip(time, value);
        };
        

        chart.subscribeCrosshairMove(updateLegend);
        
        

        return [series, lineSeries];
    };

    const handleResize = () => {
        if (!chartRef.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (entries.length === 0 || entries[0].target !== contentContainerRef.current) return;
            const { width, height } = entries[0].contentRect;

            if (height > 0 && width > 0) { // Only resize if dimensions are valid
                if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
                resizeAnimationFrame = requestAnimationFrame(() => {
                    chartRef.current.resize(width, height - 5);
                });
            }
        });

        resizeObserver.observe(contentContainerRef.current);

        return () => {
            resizeObserver.disconnect();
            if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
        };
    };


    
    useEffect(() => {
        const [series, lineSeries] = initializeChart();

        series.setData(candlestickSeries);
        lineSeries.setData(candlestickSeries)
        chartRef.current.timeScale().fitContent();
        
        const fetchData =() => {
            constants.api.post(constants.BACKEND_URL+'/historical-price/', params)
                .then(response => {
                    const responseData = response.data
    
                    const status = responseData.status
                    const data = responseData.data
                
                    if (status === 'success') {
                        setCandlestickSeries(data); // Update state  
                    }    

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
            });
        }

        pollingInterval.current = setInterval(fetchData, 60000); 
        
        const cleanupResize = handleResize();
        return () => {
            cleanupResize();
            chartRef.current.remove();
            if (pollingInterval.current) clearInterval(pollingInterval.current);
        };
    }, [currentSettings]);

    useEffect(() => {
        if (latestCandlestickData && chartRef.current) {
            seriesRef.current.setData(candlestickSeries)
            lineSeriesRef.current.setData(candlestickSeries)
        }
    }, [candlestickSeries]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    
    
    return (
        <>
            <div className='h-100 w-100' ref={chartContainerRef}>
                <div className='legend'
                    style={{
                        position: 'absolute',
                        left: '6px',
                        top: '70px',
                        zIndex: '3',
                        fontSize: '14px',
                        lineHeight: "18px",
                        fontWeight: '300'
                    }}
                >
                    <div style={{fontSize: '16px', color: "var(--text)"}}>{symbol.toUpperCase()}</div>
                    {/* <div>{formatTimestamp(tooltipValues.time)}</div> */}
                    <div>
                        <span style={{color: currentSettings.lineColor}}>C:<strong style={{color: "var(--text)"}}>{tooltipValues.value} </strong></span>
                    
                    </div>
                </div>
                <div
                style={{
                    position: 'absolute',
                    left: '3px',
                    top: '38px',
                    zIndex: '3',
                    fontSize: '14px',
                    lineHeight: "18px",
                    fontWeight: '300'
                }}
                >
                    <SettingsConfigButton label={"SETTINGS"} onClick={()=>setShowSettings(true)}/>
                </div>
            </div>
            <CustomModal1 
                show={showSettings}
                body={
                    <>
                        <h3>Chart Settings</h3>
                        <p>The main chart color is defined by the 'Primary' color in global settings. However, you can override it here to set a default that is specific to this function.</p>
                        <hr></hr>
                        <SettingsSection sectionTitle={"Line & Area"}>
                            <SettingLineWrapper label={"Line Color"}>
                                <ColorPicker 
                                    value={adjustedSettings.lineColor || currentSettings.lineColor}
                                    setColor={setLineColor}
                                />
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Show Area"}>
                                <CheckBox 
                                    setBool={setShowArea}
                                    initialValue={adjustedSettings.showArea ?? currentSettings.showArea}
                                />
                            </SettingLineWrapper>
                        </SettingsSection>
                        <SettingsSection sectionTitle={"Grid"}>
                            <SettingLineWrapper label={"Show Grid"}>
                                <CheckBox 
                                    setBool={setShowGridLines}
                                    initialValue={adjustedSettings.showGridLines ?? currentSettings.showGridLines}
                                />
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Grid Color"}>
                                <ColorPicker 
                                    value={adjustedSettings.gridColor || currentSettings.gridColor}
                                    setColor={setGridColor}
                                />
                            </SettingLineWrapper>
                        </SettingsSection>
                        <SettingsSection sectionTitle={"Other Configurations"}>
                            <SettingLineWrapper label={"Show Watermark"}>
                                <CheckBox 
                                    setBool={setShowWatermark}
                                    initialValue={adjustedSettings.showWatermark ?? currentSettings.showWatermark}
                                />
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Watermark Color"}>
                                <ColorPicker 
                                    value={adjustedSettings.watermarkColor || currentSettings.watermarkColor}
                                    setColor={setWatermarkColor}
                                />
                            </SettingLineWrapper>
                        </SettingsSection>
                    </>
                    
                }
                onClose={()=>setShowSettings(false)}
                onConfirm={() => {
                    setCurrentSettings((prev) => ({
                        ...prev,
                        ...adjustedSettings,
                    }));
                    setAdjustedSettings({}); // Reset adjusted settings
                    setShowSettings(false);
                }}
            
            />
        
        </>
    )
};

export default LineAreaChart;