import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import CustomModal1B from '../../modals/CustomModal1B'
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v4";
import GroupAnalysisTimeTable from "./GroupAnalysisTimeTable";
import WindowError from "../../misc/WindowError";
import FileTabStructure from "../../misc/FileTabStructure";
import fundamentals from "../../../catalogs/fundamentals";
import Button2 from "../../ui/small/buttons/Button2";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import SettingsSection from "../../ui/small/wrappers/settings/SettingsSection";
import SettingLineWrapper from "../../ui/small/wrappers/settings/SettingLineWrapper";
import CloseWindowButton from "../../ui/small/buttons/CloseWIndowButton";
import { showAlert } from '../../../functions/showAlert';
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import TipSection from "../../ui/small/TipSection";
import BarChart from "../../charting/chartjs/BarChart";


const GroupAnalysisByTime = ({symbol, windowID}) => {
  


  const DEFAULT_PARAMS = {
    'symbols': [],
    'metric': 'netIncome',
    'periods': 3,
    'periodType': 'annual'
  };
  const ENDPOINT = '/group-time/'

  /////////////////////////////////////////////////////////////////////////////////////// STATES AND REFS

  const [loading, setLoading] = useState(true); // New loading state
  const [intiialLoad, setInitialLoad] = useState(true); // New loading state
  const [loadingPeers, setLoadingPeers] = useState(false); // New loading state
  const [error, setError] = useState(null); // Error state
  const [showConfigModal, setShowConfigModal] = useState(true)
  const [params, setParams] = useState(DEFAULT_PARAMS)
  const [data, setData] = useState([])
  
  const [tickerToAdd, setTickerToAdd] = useState('')

  const [financialsPlottingData, setFinancialsPlottingData] = useState({})
  const [showFinPlot, setShowFinPlot] = useState(false)
  const [dateArray, setDateArray] = useState([])
  const [rowsPlotted, setRowsPlotted] = useState([])

  const autoSuggestFundamentalRef = useRef(null);
  const autoSuggestAddSymbolRef=useRef(null)


  const getPeers= async()=>{
    setParams((prevParams)=>{
      const updatedSymbols = prevParams.symbols.filter(stock=>stock.type === 'target')

      return {
        ...prevParams,
        symbols: updatedSymbols
      }
    })
    const targetSymbol=symbol

    setLoadingPeers(true);
    constants.api.post(constants.BACKEND_URL+'/get-peers/', {'symbol': targetSymbol})
      .then(response=>{
        const responseData = response.data
        const status = responseData.status

        if (status === "success") {
          const peers = responseData.data;
  

          setParams((prevParams)=>{
            const updatedSymbols = [
              // ...prevParams.symbols, // Keep existing symbols
              ...peers.map(peer => ({
                symbol: peer.symbol,
                companyName: peer.name, // Provide a fallback if companyName is missing
                exchange: peer.exchange,
                type: peer.symbol.toUpperCase() === symbol.toUpperCase()? 'target': 'peer',
              }))
            ];

    
            return {
              ...prevParams,
              symbols: updatedSymbols, // Update the symbols array
            };
          })
        }
      })
      .catch(error => {
        console.error('Error during POST request:', error);
        // setError("Failed to load data.");
      })
      .finally(() => {
          setLoadingPeers(false);
      });
  }

  useEffect(()=>{
    getPeers()

  }, [])

  const requestGroupTime = async () => {
    setLoading(true); 
    setError(null); 

    constants.api.post(constants.BACKEND_URL+'/group-time/', params)
      .then(response=>{
        const responseData = response.data
        const status = responseData.status

        if (status==="error" && responseData.code && responseData.code=== "ACCESS_DENIED") {
          setError(responseData.message)
          showPremiumPrompt(responseData.message)
        } else if (status === "success") {
          const data = responseData.data;
          setData(data)
          setDateArray(Array.from({ length: params.periods }, (_, i) => (
            `${params.periodType === "annual"? "Year": "Quarter"} n-${params.periods - i}`
        )))

          
        }
      })
      .catch(error => {
        console.error('Error during POST request:', error);
        setError("Failed to load data.");
      })
      .finally(() => {
        setLoading(false); 
        setInitialLoad(false)
      });

  }



  const handleAddTicker=()=>{
    const paramsSymbolsList = params.symbols.map(stock=>stock.symbol)
    if (paramsSymbolsList.includes(tickerToAdd) || paramsSymbolsList.includes(tickerToAdd.toUpperCase())){
      showAlert("warning", `${tickerToAdd.toUpperCase()} already exists in group!`)
    }

    else if  (tickerToAdd) {

      constants.api.post(constants.BACKEND_URL+'/company-info/', {'symbol': tickerToAdd})
        .then(response=>{
          const responseData = response.data
          const status = responseData.status

          if (status === "success") {
            const companyInfo = responseData.data;
            setParams((prevParams)=>{
              const updatedSymbols = [
                ...prevParams.symbols,
                {
                  symbol: tickerToAdd.toUpperCase(),
                  companyName: companyInfo.name,
                  exchange: companyInfo.exchange,
                  type: 'peer'
                }
              ]
              closeFinPlotChart();
              return {
                ...prevParams,
                symbols: updatedSymbols, // Update the symbols array
              };
            })
            showAlert("success", `Added ${tickerToAdd.toUpperCase()} to Group`)
          }
          else {
            const companyInfo = responseData.data;
            setParams((prevParams)=>{
              const updatedSymbols = [
                ...prevParams.symbols,
                {
                  symbol: tickerToAdd.toUpperCase(),
                  companyName: companyInfo.name,
                  exchange: companyInfo.exchange,
                  type: 'peer'
                }
              ]
              return {
                ...prevParams,
                symbols: updatedSymbols, // Update the symbols array
              };
            })

            showAlert("error", `Invalid Symbol - No company found with symbol: ${tickerToAdd.toUpperCase()}`)
          }







        })
    }
  }
  const onFundamentalChange = (newState) => {
    setParams((prevParams)=>{
      const newParams={
        ...prevParams,
        ['metric']: frontEndToBackendName(newState)
      }
      return newParams
    })
  }

  const groupedFundamentals = fundamentals.reduce((acc, item) => {
    // Only process items where isNumber is true
    if (item.sourceName !== "Information") {
        const { sourceName } = item;
        if (!acc[sourceName]) {
            acc[sourceName] = [];
        }
        acc[sourceName].push(item);
    }
    return acc;
}, {});


  const backendToFrontendName = (backendName) => {
    return fundamentals.find((item)=> backendName === item.fmpName)?.name
  }
  const frontEndToBackendName = (frontendName) => {
    return fundamentals.find((item)=> frontendName === item.name)?.fmpName
  }
  const updateParams = (e) => {
    const { id, value } = e.target;

    // Update the params by setting the new value for the key that matches the input's id
    setParams((prevParams) => ({
        ...prevParams,
        [id]: value
    }));
  }

  const handleRowClick = (symbol, array) => {
  
    setFinancialsPlottingData((prevFinData) => {
      const symbolUpper = symbol.toUpperCase();
      const symbolAlreadyPlotted = Object.keys(prevFinData).includes(symbolUpper);
  
      let updatedFinPlotData;
  
      // If the symbol is already in the object, remove it
      if (symbolAlreadyPlotted) {
        const { [symbolUpper]: _, ...remainingData } = prevFinData; // Omit the symbol
        updatedFinPlotData = remainingData;
      } else {
        // Otherwise, add it
        updatedFinPlotData = {
          ...prevFinData,
          [symbolUpper]: array,
        };
      }
  
      // Only show the plot if there are remaining keys
      setShowFinPlot(Object.keys(updatedFinPlotData).length > 0);
  
      return updatedFinPlotData;
    });
  };
  

  const closeFinPlotChart = () => {
    setFinancialsPlottingData({})
    setRowsPlotted([]);
    setShowFinPlot(false)
  }

  const symbolToCompany = params.symbols.reduce((acc, item) => {
    acc[item.symbol.toUpperCase()] = item.companyName;
    return acc;
  }, {});

  return (
    <> 
      {loading ? ( 
          <LoadingSpinner />
      ) : error ? (
        <>
          <WindowError message={error}/>
          <div className="d-flex flex-row-reverse p-2">
            <Button2 onClick={()=>setShowConfigModal(true)}>Open Configuration Menu</Button2>
          </div>
        </>
      ) : (
          <>


                  {/* <TipSection message={"Click any company name in the 'Company' column to plot."}/> */}
                  <p className="m-0 p-2">
                    Displaying data for the selected group: <strong>{backendToFrontendName(params.metric)} </strong> 
                     (in millions of USD, converted at the current spot rate).
                  </p>
                  {showFinPlot && (
                      <>  
                          <div className="m-1">
                              <Button2 fullWidth={true} onClick={()=>closeFinPlotChart()}>Close Figure</Button2>
                          </div>
                          <div style={{borderColor: "var(--secondary)"}}  className="border m-1">
                              <BarChart windowID={windowID} data={financialsPlottingData} labels={dateArray} params={params}/>
                          </div>
                          
                      </>
                  )}
                  
                  <GroupAnalysisTimeTable 
                    data={data}
                    mappedSymbolNames={symbolToCompany}
                    onRowClick={handleRowClick}
                    plottedSymbols={Object.keys(financialsPlottingData)}
                    params={params}
                    />
                  <div className="d-flex flex-row-reverse p-2">
                    <Button2 onClick={()=>setShowConfigModal(true)}>Open Configuration Menu</Button2>
                  </div>
                 
              

              {/* <SortableTable data={sampleData} columns={sampleColumns} /> */}
              
          </>     
      )}
      <CustomModal1B
        show={showConfigModal}
        body={
          <>
            <h3>Group Time-Series Analysis Configuration</h3>
            <SettingsSection sectionTitle={"Add a symbol"}>
              <div className="position-relative d-flex flex-row w-100 justify-content-between">
                <AutosuggestSecurities
                      id={'symbol'}
                      ref={autoSuggestAddSymbolRef}
                      defaultValue={symbol}
                      handleSetValue={setTickerToAdd}
                      handleSubmitCustom={()=>handleAddTicker()}
                />
                <div className="ms-1">
                  <Button2 onClick={()=>handleAddTicker()}>Add Symbol</Button2>
                </div>
              </div>
            </SettingsSection>

            <SettingsSection sectionTitle={"Current Group"}>
              {loadingPeers ? (
                <>
                  <LoadingSpinner />
                  <p className="text-center mt-3" style={{color: "var(--primary)"}}><strong>Loading Default Peers...</strong></p>
                </>
              ) : (
                <ZenithTable1.Table 
                  tablehead={
                    <tr>
                      <th>Symbol</th>
                      <th>Name</th>
                      <th>Exchange</th>
                      <th>Delete Symbol</th>
                    </tr>
                  }
                  tablebody={
                    <>
                      {params.symbols.map((stock, index) => (
                        <tr key={index}>
                          <td>{stock.symbol.toUpperCase()}</td>
                          <td>{stock.companyName}</td>
                          <td>{stock.exchange}</td>
                          <td className="text-center">
                            {stock.type==='peer' && (
                              <CloseWindowButton 
                                title="Remove Symbol" 
                                onClick={() => {
                                  setParams((prevParams) => {
                                    const updatedSymbols = prevParams.symbols.filter(
                                      (item) => item.symbol !== stock.symbol
                                    );
    
                                    return {
                                      ...prevParams,
                                      symbols: updatedSymbols,
                                    };
                                  });
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              )
              
              
              
              }
              
            </SettingsSection>




            <SettingsSection sectionTitle={"Select Fundamental"}>
              <div className='text-center my-3'>Use the menu to select fundamental metrics.</div>
              <FileTabStructure
                  selectedMetric={params.metric} 
                  tabsObject={groupedFundamentals}
                  onClick={(metric)=> setParams((prevParams=>{
                    const newParams = {
                      ...prevParams,
                      ['metric']: metric
                    };
                    return newParams
                  }))}
                  />
            </SettingsSection>




            <SettingsSection sectionTitle={"Interval & Number of Periods"}>
              <SettingLineWrapper label={"Period"}>
                <WindowPieces.SquareInput 
                  id={'periods'}
                  type={'number'}
                  placeholder={'Periods'}
                  value={params.periods}
                  onChange={updateParams}
              />
              </SettingLineWrapper>
              <SettingLineWrapper label={"Period Type"}>
                <WindowPieces.SquareSelect
                  id={'periodType'}
                  innerHTMLList={['Annual', 'Quarter']} 
                  valueList={['annual', 'quarter']}
                  value={params.periodType}
                  onChange={updateParams}

                />
              </SettingLineWrapper>
            </SettingsSection>
            <SettingsSection sectionTitle={"Summary"}>
              <ul style={{color: "var(--text)"}}>
                <li>Selected Metric: {backendToFrontendName(params.metric)}</li>
                <li>Number of Periods: {params.periods}</li>
                <li>Period Type: {params.periodType.toUpperCase()}</li>
              </ul>
            </SettingsSection>

     
          </>
          
        }
        onConfirm={()=>{
          requestGroupTime()
          setShowConfigModal(false)
          setFinancialsPlottingData({})
          setRowsPlotted([]);
          setShowFinPlot(false)

        
        }}
        onClose={()=>setShowConfigModal(false)}
        showCancel={!intiialLoad}

      />
    </>
  )
}

export default GroupAnalysisByTime;
