
import React from "react";

const FIGRSparkLine = ({ 
  data, 
  width = 400, 
  height = 100, 
  strokeColor = "white", 
  strokeWidth = 1 
}) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  // Generate unique ID for this sparkline's gradient
  const gradientId = `sparkline-gradient-${Math.random().toString(36).substr(2, 9)}`;

  const createSparklinePoints = (data) => {
    const maxData = Math.max(...data);
    const minData = Math.min(...data);

    return data.map((value, index) => {
      const x = (index / (data.length - 1)) * width;
      const y = height - ((value - minData) / (maxData - minData)) * height;
      return { x, y };
    });
  };

  const pointsArray = createSparklinePoints(data);
  const sparklinePoints = pointsArray.map(point => `${point.x},${point.y}`).join(" ");

  const polygonPoints = `
    ${sparklinePoints} 
    ${width},${height} 
    0,${height}
  `;

  return (
    <svg width={width} height={height}>
      <defs>
        <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={strokeColor} stopOpacity="0.5" />
          <stop offset="100%" stopColor={strokeColor} stopOpacity="0" />
        </linearGradient>
      </defs>

      <polygon 
        points={polygonPoints} 
        fill={`url(#${gradientId})`} 
      />
  
      <polyline
        points={sparklinePoints}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default FIGRSparkLine;



