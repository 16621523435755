import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import ZenithTable1Sortable from "../../ui/small/tables/ZenithTable1Sortable";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import LoadButton from "../../ui/small/buttons/LoadButton";

const InstitutionalPortfolioComposition = ({ cik, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'cik': cik,
        'year': 2024,
        'quarter': 'Q3',
        'page': 1
    };
    const ENDPOINT = '/institutional-portfolio-composition/';
    
    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {

        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
            };

            updateLayoutItem(windowID, {
                symbol: cik ? cik : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
                
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data
                    const status = responseData.status
                    const data = responseData.data

                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                    } else if (status === "error") {
                        setError(responseData.message)
                    }

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
                
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, [params.page]);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }
    const changePage = (pageVal) => {
        if (pageVal > 0 && pageVal <= 100) {
            setParams((prevParams) => ({
                ...prevParams,
                page: pageVal
            }));
        }
    };

    const handlePageInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            changePage(value);
        }
    };

   
    const cols = [
        { key: "symbol", label: "Symbol" },
        { key: "securityName", label: "Symbol Name" },
        { key: "industryTitle", label: "Industry" },
        { key: "weight", label: "Weight" },
        { key: "sharesNumber", label: "# of Shares" },
        { key: "avgPricePaid", label: "Average Price" },
        { key: "ownership", label: "% Shares Outstanding" },
        { key: "firstAdded", label: "First Added" },
    ];        
    

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <div className="d-flex">

                </div>
                <WindowPieces.ConfigContainer>
                    <WindowPieces.SquareInput 
                        id={'year'}
                        type={'number'}
                        placeholder={'Year'}
                        value={params.year}
                        onChange={updateParams}
                    />
                    <WindowPieces.SquareSelect
                        id={'quarter'}
                        innerHTMLList={['Q1', 'Q2', 'Q3', 'Q4']} 
                        valueList={['Q1', 'Q2', 'Q3', 'Q4']}
                        value={params.quarter}
                        onChange={updateParams}

                    />  

                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                </WindowPieces.ConfigContainer>
            </WindowPieces.OptionsContainer>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>
                    <WindowPieces.Content>
                        <div className="d-flex flex-row justify-content-between">
                            <div className="p-2" style={{color: "var(--primary)"}}>{data[0].investorName} Holdings</div>
                            <div className="p-2" style={{color: "var(--primary)"}}>Filing Date: {data[0].date}</div>
                        </div>
                        <p className='text-primary fw-light px-2 py-1'>Viewing page {params.page}</p>
                        <ZenithTable1.Table 
                            tablehead={
                                <tr>
                                    <th>Symbol</th>
                                    <th>Name</th>
                                    <th>Industhy</th>
                                    <th>Weight</th>
                                    <th># Of Shares</th>
                                    <th>Average Price</th>
                                    <th>% of Shares Outstanding</th>
                                    <th>First Added</th>
                                </tr>
                            }
                            tablebody={
                                <>
                                    {data.map((item,index)=>{
                                        return (
                                            <tr key={index}>
                                                <td>{item.symbol}</td>
                                                <td>{item.securityName}</td>
                                                <td>{item.industryTitle}</td>
                                                <td>{`${item.weight.toFixed(2)}%`}</td>
                                                <td>{item.sharesNumber}</td>
                                                <td>{`$${item.avgPricePaid.toFixed(2)}`}</td>
                                                <td>{`${item.ownership.toFixed(2)}%`}</td>
                                                <td>{item.firstAdded}</td>
                                            </tr>
                                        )


                                    })}
                                </>
                            }
                        
                        
                        />
                        
                    </WindowPieces.Content>
                    <WindowPieces.PageViewer
                        currentPage={params.page}
                        changePageFunc={changePage}
                        handlePageInput={handlePageInputChange}
                    />
                </>
            )}
        </>
    );
};

export default InstitutionalPortfolioComposition;