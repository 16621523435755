import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import TopGainersLosers from "../../../components/FeatureComponents/26_TopGainersLosers/26_TopGainersLosers";

async function handleTopGainersLosers(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-top-gainers-losers';
    const TITLE_PREFIX = 'Most Active, Top Gainers & Losers';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<TopGainersLosers windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleTopGainersLosers ;
