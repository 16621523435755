import React from 'react';
import FigrIconWrapper2 from '../../ui/small/FigrIconWrapper2';
const SuggestionMenu = ({ securitySuggestions, functionSuggestions, nonSecurityFunctionSuggestions, handleSuggestionClick }) => {
    function getSuggestionTypeString(suggestionObject) {
        let suggestionTypeString = 'EQUITY';

        if (suggestionObject.suggestionType === 'security') {
            if (suggestionObject.exchangeShortName === 'ECONOMY') {
                suggestionTypeString = 'ECONOMICS'; 
            } else if (suggestionObject.exchangeShortName === 'CRYPTO') {
                suggestionTypeString = 'CRYPTO';
            } else if (suggestionObject.exchangeShortName === 'FOREX') {
                suggestionTypeString = 'FOREX';
            } else if (suggestionObject.exchangeShortName === "COMMODITY") {
                suggestionTypeString = "COMMODITY"
            }
        } else {
            suggestionTypeString = 'FUNCTION'; // function is more clear than command
        }

        return suggestionTypeString;
    }

    return (
        <div 
            className='border'
            style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                overflowY: 'auto',
                overflowX: 'hidden',
                minWidth: '100%',
                width: '700px',
                zIndex: 1000,
                textAlign: 'left',
                backgroundColor: "var(--theme)",
                borderColor: "var(--secondary)",
                pointerEvents: 'auto'
            }} >
            {securitySuggestions.length > 0 ? (
                <>
                    {(securitySuggestions && securitySuggestions.length > 0) && 
                    <div className='p-2'>
                        <h5 style={{color: "var(--text)"}} className="fw-bold">SECURITIES</h5>
                        <div>
                            {securitySuggestions.map((suggestionObject, index) => {
                                const suggestionTypeString = getSuggestionTypeString(suggestionObject);
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={'security'} 
                                        data-suggestion-value={suggestionObject.symbol} 
                                        tabIndex={0}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleSuggestionClick(suggestionObject.symbol, 'security');
                                        }}
                                        className="row suggestion-item p-1 d-flex align-items-center"
                                        style={{
                                            color: "var(--text)",
                                            cursor: 'pointer',
                                            userSelect: 'none',
                                            pointerEvents: 'auto',
                                            position: 'relative',
                                            zIndex: 1001
                                        }}
                                        >
                                        <div className="col-3">
                                            <span className="fw-bold text-nowrap">{suggestionObject.symbol}</span>
                                        </div>
                                        <div className="col-6">
                                            <span className='text-primary text-nowrap' >{suggestionObject.name}</span>
                                        </div>
                                        <div className="col-3 text-end">
                                            <span>{suggestionTypeString=='EQUITY'? `${suggestionTypeString}: ${suggestionObject.exchangeShortName}`: suggestionTypeString}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div> }

                    {(functionSuggestions && functionSuggestions.length > 0) && 
                    <div className='p-2'>
                        <h5 style={{color: "var(--text)"}} className="fw-bold">SECURITY-RELATED FUNCTIONS</h5>
                        <div>
                            {functionSuggestions.map((suggestionObject, index) => {
                                const suggestionTypeString = getSuggestionTypeString(suggestionObject);
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={'function'} 
                                        data-suggestion-value={`${securitySuggestions[0].symbol} ${suggestionObject.code}`}
                                        tabIndex={0}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSuggestionClick(`${securitySuggestions[0].symbol} ${suggestionObject.code}`, 'function');
                                        }}
                                        className="row suggestion-item p-1 d-flex align-items-center"
                                        style={{
                                            color: "var(--text)",
                                            cursor: 'pointer',
                                            userSelect: 'none',
                                            pointerEvents: 'auto',
                                            position: 'relative',
                                            zIndex: 1001
                                        }}
                                        >
                                        <div className="col-3">
                                            <span className="fw-bold text-nowrap">{securitySuggestions[0].symbol} {suggestionObject.code}</span>
                                        </div>
                                        <div className="col-6">
                                            <span className='text-primary text-nowrap'>{suggestionObject.featureName}: {securitySuggestions[0].name}</span>
                                        </div>
                                        <div className="col-3 text-end">
                                            <span>{suggestionTypeString}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}

                    {(nonSecurityFunctionSuggestions && nonSecurityFunctionSuggestions.length > 0) && 
                    <div className='p-2'>
                        <h5 style={{color: "var(--text)"}}  className="fw-bold">NON-SECURITY RELATED FUNCTIONS</h5>
                        <div>
                            {nonSecurityFunctionSuggestions.map((suggestionObject, index) => {
                                const suggestionTypeString = getSuggestionTypeString(suggestionObject);
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={'function'} 
                                        data-suggestion-value={`${suggestionObject.code}`}
                                        tabIndex={0}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSuggestionClick(`${suggestionObject.code}`, 'function');
                                        }}
                                        className="row suggestion-item p-1 d-flex align-items-center"
                                        style={{
                                            color: "var(--text)",
                                            cursor: 'pointer',
                                            userSelect: 'none',
                                            pointerEvents: 'auto',
                                            position: 'relative',
                                            zIndex: 1001
                                        }}
                                        >
                                            
                                        <div className="col-3">
                                            <span className="fw-bold text-nowrap">{suggestionObject.code}</span>
                                        </div>
                                        <div className="col-6">
                                            <span className='text-primary text-nowrap'>{suggestionObject.featureName}</span>
                                        </div>
                                        <div className="col-3 text-end">
                                            <span>{suggestionTypeString}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}
                    <div className='m-2 p-1 d-flex justify-content-between' style={{border: "0.5px solid var(--secondary)"}}>
                        <span className='d-flex align-items-center'>
                            <div className='m-1'>
                                <FigrIconWrapper2 title={"Up"}>
                                    <svg style={{height: "12px", width: "12px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960"><path d="M480-528 296-344l-56-56 240-240 240 240-56 56z"/></svg>
                                </FigrIconWrapper2>
                            </div>
                            <div className='m-1'>
                                <FigrIconWrapper2 title={"Down"}>
                                    <svg style={{height: "12px", width: "12px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960"><path d="M480-344 240-584l56-56 184 184 184-184 56 56z"/></svg>
                                </FigrIconWrapper2>
                            </div>
                            <span className='m-2'>
                                to navigate
                            </span>
                        </span>
                        <span className='d-flex align-items-center'>
                            <FigrIconWrapper2 title={"Enter/Return"}>
                                <svg style={{height: "12px", width: "12px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960"><path d="M360-240 120-480l240-240 56 56-144 144h488v-160h80v240H272l144 144z"/></svg>
                            </FigrIconWrapper2>
                            <span className='m-2'>
                                to select
                            </span>
                        </span>
                    </div>    
                </>
            ): (
                <div className='p-1' style={{color: "var(--negative-c)"}}>No Valid Symbol!</div>
            )
            }
            
            
        </div>
    );
}

export default SuggestionMenu;
