const economicIndicators = {
    "GDP": "Gross Domestic Product, measures the total economic output of a country.",
    "realGDP": "Real GDP, adjusts GDP for inflation to reflect real value changes.",
    "nominalPotentialGDP": "Nominal Potential GDP, the estimated value of GDP without inflation adjustment.",
    "realGDPPerCapita": "Real GDP Per Capita, the average economic output per person adjusted for inflation.",
    "federalFunds": "Federal Funds Rate, the interest rate at which banks lend to each other overnight.",
    "CPI": "Consumer Price Index, measures changes in the price level of a basket of goods and services.",
    "inflationRate": "Inflation Rate, the percentage change in price levels over time.",
    "inflation": "Inflation, the overall increase in prices across an economy.",
    "retailSales": "Retail Sales, measures consumer spending in retail stores.",
    "consumerSentiment": "Consumer Sentiment, gauges consumer confidence in the economy.",
    "durableGoods": "Durable Goods, orders for long-lasting manufactured items like cars and appliances.",
    "unemploymentRate": "Unemployment Rate, the percentage of the labor force without a job.",
    "totalNonfarmPayroll": "Total Nonfarm Payroll, total employment in non-agricultural industries.",
    "initialClaims": "Initial Jobless Claims, the number of people filing for unemployment benefits for the first time.",
    "industrialProductionTotalIndex": "Industrial Production Index, measures output in the industrial sector.",
    "newPrivatelyOwnedHousingUnitsStartedTotalUnits": "Housing Starts, the number of new privately-owned residential buildings started.",
    "totalVehicleSales": "Total Vehicle Sales, measures the number of vehicles sold in a given period.",
    "retailMoneyFunds": "Retail Money Funds, measures money held in retail money market funds.",
    "smoothedUSRecessionProbabilities": "Recession Probability, estimates the likelihood of a U.S. recession.",
    "3MonthOr90DayRatesAndYieldsCertificatesOfDeposit": "3-Month CD Rate, the interest rate on 3-month certificates of deposit.",
    "commercialBankInterestRateOnCreditCardPlansAllAccounts": "Credit Card Interest Rate, the average interest rate on credit card accounts.",
    "30YearFixedRateMortgageAverage": "30-Year Mortgage Rate, the average interest rate for a 30-year fixed-rate mortgage.",
    "15YearFixedRateMortgageAverage": "15-Year Mortgage Rate, the average interest rate for a 15-year fixed-rate mortgage."
  };

export default economicIndicators  
