import React, { useState, useRef } from 'react';
import WindowPieces from "../../ui/small/WindowPieces";
import axios from 'axios';
import constants from '../../../constants';
import LoadingSpinner from '../../misc/LoadingSpinner';
import fundamentals from '../../../catalogs/fundamentals';
import { showAlert } from '../../../functions/showAlert';
import CustomModal1 from '../../modals/CustomModal1';
import FileTabStructure from '../../misc/FileTabStructure';
import handleTearSheet from '../../../functions/requestFuncs/handling/handleTearSheet';
import getRootColorString from '../../../functions/helpers/getRootColorString';
import ZenithTable1 from '../../ui/small/tables/ZenithTable1';

//OIUWFEHFIUWENEWBNIUBN


const ScreenerCriteriaLine = ({ onRemove, onChange, index, criteria }) => {
    const [thresholdType, setThresholdType] = useState(criteria.thresholdType); 
    const [threshold, setThreshold] = useState(criteria.threshold || ''); 

    // Modal Related States
    const [showSelectFundamentalModal, setShowSelectFundamentalModal] = useState(false);
    const [selectedFundamental, setSelectedFundamental] = useState("");
    const criteriaFundamentalID = `criteria-fundamental-${index}`

    

    const handleSelectFundamental = () =>{
        setShowSelectFundamentalModal(true)
    }

    const handleConfirmSelectFundamentalFromSearch = () => {
        const criteriaFundamentalValue = document.getElementById(criteriaFundamentalID).value;

        // Find the corresponding `fmpName` based on the selected `name`
        const selectedFundamental = fundamentals.find(
            (item) => item.name === criteriaFundamentalValue
        )?.fmpName;

        // Use `fmpName` for the API and backend
        setSelectedFundamental(criteriaFundamentalValue);  // Set the clean name for the UI
        onChange(index, { ...criteria, fundamental: selectedFundamental }); // Send `fmpName` to the backend

        setShowSelectFundamentalModal(false);
    };

    const handleConfirmSelectFundamentalFromSelect = (fundamentalName) => {
        // Find the corresponding `fmpName` based on the selected `name`
        const selectedFundamental = fundamentals.find(
            (item) => item.name === fundamentalName
        )?.fmpName;

        // Use `fmpName` for the API and backend
        setSelectedFundamental(fundamentalName);  // Set the clean name for the UI
        onChange(index, { ...criteria, fundamental: selectedFundamental }); // Send `fmpName` to the backend

        setShowSelectFundamentalModal(false);
    };


    const groupedFundamentals = fundamentals.reduce((acc, item) => {
        const { sourceName } = item;
        if (!acc[sourceName]) {
          acc[sourceName] = []; // Initialize array if key doesn't exist
        }
        acc[sourceName].push(item);
        return acc;
      }, {});

    const handleThresholdTypeChange = (event) => {
        const newThresholdType = event.target.value;
        setThresholdType(newThresholdType); 
        onChange(index, { ...criteria, thresholdType: newThresholdType, threshold: newThresholdType === 'industry-average' ? '' : threshold }); 
    };

    const handleThresholdChange = (event) => {
        const newThreshold = event.target.value;
        setThreshold(newThreshold);
        onChange(index, { ...criteria, threshold: newThreshold }); 
    };

    return (
        <div className='row d-flex flex-row align-items-center'>
            <div className='col-6'>
                {/* <AutoSuggestFundamentals
                    onChange={(e) => onChange(index, { ...criteria, fundamental: e.target.value })}
                    submitOnEnter={false}
                /> */}
                <div style={{borderColor: "var(--secondary)"}}  className='d-flex flex-row justify-content-between border'>
                    <div>{selectedFundamental}</div>
                    <WindowPieces.SquareLoadButton label={"BROWSE FUNDAMENTALS"} onClick={handleSelectFundamental}/>
                </div>
            </div>

            <div className='col'>
                <WindowPieces.SquareSelect
                    valueList={['>', '<', '>=', '<=', '=', '!=']}
                    innerHTMLList={['> (More Than)', '< (Less Than)', '>= (More Than or Equal to)', '<= (Less Than or Equal to)', '= (Equal to)', '!= (Not Equal to)']}
                    name={"operator"}
                    id={"operator"}
                    onChange={(e) => onChange(index, { ...criteria, operator: e.target.value })} // Update operator
                />
            </div>

            <div className='col'>
                <WindowPieces.SquareSelect
                    valueList={['absolute', 'industry-average']}
                    innerHTMLList={['Absolute Value', 'Use Industry Average']}
                    name={"threshold-type"}
                    id={"threshold-type"}
                    onChange={handleThresholdTypeChange} // Handle change event
                />
            </div>
            
            {/* Conditionally render the threshold input based on the selected threshold type */}
            {thresholdType === 'absolute' && (
                <div className='col'>
                    <WindowPieces.SquareInput
                        type={"number"}
                        id={"threshold"}
                        placeholder={"Threshold"}
                        value={threshold} 
                        onChange={handleThresholdChange} 
                    />
                </div>
            )}

            {/* Remove button */}
            <div className='col-auto'>
                <button onClick={onRemove} style={{ color: 'red' }}>X</button>
            </div>
            <CustomModal1
                show={showSelectFundamentalModal}
                title="Select a Fundamental"
            
            />
            {/* <CustomModal1
                show={showSelectFundamentalModal}
                title="Select A Fundamental"
                body={
                    <>
                        <AutoSuggestFundamentals
                            id={criteriaFundamentalID}
                            submitOnEnter={false}
                            
                        />
                        <div className='text-center'>OR use the menu to make a selection...</div>
                        <FileTabStructure 
                            tabsObject={groupedFundamentals}
                            onClick={handleConfirmSelectFundamentalFromSelect}
                            />
                        
                    </>
                }
                onClose={() => setShowSelectFundamentalModal(false)}
                onConfirm={handleConfirmSelectFundamentalFromSearch}
            /> */}
        </div>
        
    );
};


// export default Screener;
const ITEMS_PER_PAGE = constants.TABLE_MAX_ROWS; // Number of items to display per page


const Screener = () => {
    const [criteria, setCriteria] = useState([{
        fundamental: '', 
        operator: '>', 
        thresholdType: 'absolute', 
        threshold: "3"
    }]); // Initialize with one criteria object
    const [screenerResults, setScreenerResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const [loading, setLoading] = useState(false); // Loading state
    const [hoveredSymbol, setHoveredSymbol] = useState(null)

    const fetchScreener = async () => {
        setLoading(true)
        try {
            const response = await constants.api.post(constants.BACKEND_URL+'/screener/', criteria);
            const parsedResponse = await JSON.parse(response.data.data);
            setScreenerResults(parsedResponse);
            setCurrentPage(1); // Reset to first page on new results
        } catch (error) {
            console.error('Error fetching screener data:', error);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    const addCriteriaLine = () => {
        setCriteria([...criteria, { fundamental: '', operator: '>', thresholdType: 'absolute', threshold: "3" }]); // Add a new criteria object
    };

    const removeCriteriaLine = (index) => {
        if (criteria.length == 1) {
            showAlert("warning", "Screener has to have at minimum 1 criteria!")
        } else {
            setCriteria(criteria.filter((_, i) => i !== index)); // Remove the criteria object at the specified index

        }
    };

    const updateCriteriaLine = (index, newCriteria) => {
        const updatedCriteria = criteria.map((item, i) => (i === index ? newCriteria : item));
        setCriteria(updatedCriteria); // Update criteria with modified object
    };

    // Calculate the current page results
    const currentResults = screenerResults.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    // Calculate total pages
    const totalPages = Math.ceil(screenerResults.length / ITEMS_PER_PAGE);

    // Change page
    const changePage = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePageInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            changePage(value);
        }
    };

    return (
        <div className='p-1 h-100 d-flex flex-column '>
            <div>
                <h5>CRITERIA MANAGEMENT</h5>
                <div>
                    <div className='container-fluid p-0 d-flex flex-column'>
                        {criteria.map((item, index) => (
                            <ScreenerCriteriaLine 
                                key={index} 
                                criteria={item} 
                                onRemove={() => removeCriteriaLine(index)} 
                                onChange={updateCriteriaLine} 
                                index={index} 
                            />
                        ))}
                    </div>
                    <WindowPieces.SquareLoadButtonSecondary label={"Add Criteria"} onClick={addCriteriaLine} fullWidth={true}/>
                </div>
                <WindowPieces.SquareLoadButton label={"Run Screener"} onClick={fetchScreener} fullWidth={true}/>
            </div>
            <div>
                <h5>SCREENER RESULTS</h5>
                {loading ? ( // Render the loading spinner if loading
                    <LoadingSpinner />
                ) : (
                    <>
                        {screenerResults.length > 0 ? (
                            <>
                                <p className='text-primary fw-light'>Viewing page {currentPage} of {totalPages}</p>
                                <ZenithTable1.Table
                                    tablehead={
                                        <tr>
                                            {
                                            Object.keys(screenerResults[0]).map((key, idx) => {
                                                const fundamentalName=fundamentals.find((item)=>item.fmpName==key)?.name

                                                return (
                                                <th key={idx}>{fundamentalName}</th> // Capitalize first letter
                                                )
                                            
                                            })}
                                        </tr>
                                    }
                                    tablebody={
                                        <>
                                            {currentResults.map((item, index) => (
                                            <tr key={index}>
                                                <td
                                                    onMouseEnter={() => setHoveredSymbol(item.index)}
                                                    onMouseLeave={() => setHoveredSymbol(null)}
                                                    style={{ position: 'relative' }}

                                                >
                                                    {item.index}
                                                    {hoveredSymbol === item.index && (
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                color: "var(--primary)",
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleTearSheet(item.index)}
                                                        >
                                                            &#8594;
                                                        </span>
                                                    )}
                                                </td>
                                                {Object.keys(item).filter(key => key !== 'index').map((key, idx) => (
                                                    <td key={idx}>{item[key]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                        </>
                                    }
                                />
                                {/* Pagination Controls */}
                                <WindowPieces.PageViewer
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    changePageFunc={changePage}
                                    handlePageInput={handlePageInputChange}
                                />
                            </>
                        ) : (
                            <>
                                <p>No results found</p>
                                <pre>{JSON.stringify(criteria)}</pre>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Screener;