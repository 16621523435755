import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import FinancialStatementRow from "./FinancialStatementRow";
import WindowPieces from "../../ui/small/WindowPieces";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import EmptyRow from "./EmptyRow";
import ComboChart from "../../charting/chartjs/ComboChart";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import LoadButton from "../../ui/small/buttons/LoadButton";
import Button2 from "../../ui/small/buttons/Button2";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import TipSection from "../../ui/small/TipSection";

const FinancialStatements = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'periods': 3,
        'periodType': 'annual',
        'divider': 'millions'
    };
    const ENDPOINT = '/financial-statement/';
    const tabs = [
        { label: "Income Statement", targetID: "incomeStatement" },
        { label: "Balance Sheet", targetID: "balanceSheet" },
        { label: "Cash Flow Statement", targetID: "cashflowStatement" },
        { label: "Ratio Sheet", targetID: "ratioSheet" },
    ];
    const DEFAULT_TAB='incomeStatement'
    

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

  
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [activeTabID, setActiveTabID] = useState(DEFAULT_TAB)
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);
    
    const [financialsPlottingData, setFinancialsPlottingData] = useState({})
    const [showFinPlot, setShowFinPlot] = useState(false)
    const [dateArray, setDateArray] = useState([])
    const [rowsPlotted, setRowsPlotted] = useState([])

    const autoSuggestRef = useRef(null);

    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const updateFinancialPlottingData = (allStatementData) => {
        setFinancialsPlottingData(prevFinPlotData=>{
            const fpData = rowsPlotted.reduce((acc, row) => {
                const rowName = row.rowName;
                const statementID = row.statementID;
                const targetStatement = Object.values(allStatementData).find(statement => statement.statementID === statementID)?.statementData;
                if (targetStatement) {
                    acc[rowName] = targetStatement[rowName];
                }
            
                return acc; 
            }, {}); 
            return fpData
    
        })
    }



    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            
            // Update Database only after the initial render
            ///////////////////////////////////////////////////////////////////////////////// FIX THIS ISSUE
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);

            ///////////////////////////////////////////////////////////////////////////////// FIX THIS ISSUE
            
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 

            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data

                    const status = responseData.status
                    const data = responseData.data

                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                        setDateArray(data.incomeStatement.statementData.Date.dataContent)
                        updateFinancialPlottingData(data)
                    }
                })
                .catch(error => {
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });
        

        if (initialRender) setInitialRender(false);
    };

    const downloadData = () => {
        constants.api.post(
            "/download-financials/", 
            { downloadData: data, symbol: params.symbol }, 
            { responseType: 'blob' }
        )
        .then(async (response) => {
            const contentType = response.headers['content-type'];
            
            // Check if response contains JSON (indicates error)
            if (contentType && contentType.includes('application/json')) {
                const text = await response.data.text(); // Convert blob to text
                const json = JSON.parse(text); // Parse JSON
                
                const { status, code, message } = json;
    
                if (status === "error" && code === "ACCESS_DENIED") {
                    showPremiumPrompt(message);
                } else {
                    console.error('Unexpected error response:', json);
                }
            } else if (response.data.size > 0) {
                // Handle Blob response for file download
                const url = URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${params.symbol.toUpperCase()}_Financials.xlsx`; // Name of the downloaded file
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url); // Clean up the URL.createObjectURL reference
            } else {
                console.error('Received an empty file.');
            }
        })
        .catch(error => {
            console.error('Error downloading the file:', error);
        })
        .finally(() => {
          
        });
    };
    
    

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const handleTabClick = (targetID) => {
        setActiveTabID(targetID); 
        
    };

    const handleRowClick = (statementID, arrayName, array, dataType) => {
        if (dataType == 'no-plot') {
            return 
        }

        setRowsPlotted((prevRowsPlotted)=>{
            const newRowsPlotted = prevRowsPlotted.map((row)=>row.rowName).includes(arrayName)
            ? prevRowsPlotted.filter(item => item.rowName !== arrayName) 
            : [...prevRowsPlotted, {
                rowName: arrayName,
                statementID: statementID
            }]

            setFinancialsPlottingData(prevFinPlotData=>{
                const fpData = newRowsPlotted.reduce((acc, row) => {
                    const rowName = row.rowName;
                    const statementID = row.statementID;
                    const targetStatement = Object.values(data).find(statement => statement.statementID === statementID)?.statementData;
                    if (targetStatement) {
                        acc[rowName] = targetStatement[rowName];
                    }   
                
                    return acc; 
                }, {}); 
                return fpData
        
            })
            if (newRowsPlotted.length === 0) {
                setShowFinPlot(false);
                return []
            } else {
                setShowFinPlot(true);
            }

            return newRowsPlotted
        })
        
    }

    
    
    

    

    const closeFinPlotChart = () => {
        setFinancialsPlottingData({})
        setRowsPlotted([]);
        setShowFinPlot(false)
    }
    
    const handleChartDownload = (chartID) => {
        // https://dev.to/noemelo/how-to-save-chart-as-image-chart-js-2l0i
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>  
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                    id={'symbol'}
                    ref={autoSuggestRef}
                    defaultValue={params.symbol}
                    handleSubmitCustom={requestData}
                />
                <WindowPieces.ConfigContainer>
                    <WindowPieces.SquareInput 
                        id={'periods'}
                        type={'number'}
                        placeholder={'Periods'}
                        value={params.periods}
                        onChange={updateParams}
                    />
                    <WindowPieces.SquareSelect
                        id={'periodType'}
                        innerHTMLList={['Annual', 'Quarter']} 
                        valueList={['annual', 'quarter']}
                        value={params.periodType}
                        onChange={updateParams}

                    />
                    <WindowPieces.SquareSelect
                        id={'divider'}
                        innerHTMLList={['Billions', 'Millions', 'Thousands']} 
                        valueList={['billions', 'millions', 'thousands']}
                        value={params.divider}
                        onChange={updateParams}

                    />
                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                </WindowPieces.ConfigContainer>
                    
                        
            </WindowPieces.OptionsContainer>
            
            
            
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>

                    <div className="overflow-y-auto flex-grow-1">
                        {/* <TipSection message={"Click any line item (ex. Revenue) to plot."} /> */}
                        {showFinPlot && (
                            <>  
                                <div className="m-1">
                                    <Button2 fullWidth={true} onClick={()=>closeFinPlotChart()}>Close Figure</Button2>
                                </div>
                                <div style={{borderColor: "var(--secondary)"}}  className="border m-1">
                                    <ComboChart windowID={windowID} data={financialsPlottingData} labels={dateArray} ticker={params.symbol} params={params}/>
                                </div>
                                
                            </>
                        )}
                        <WindowPieces.TabContainer tabs={tabs} activeTabID={activeTabID} onTabClick={handleTabClick} />
                            <WindowPieces.TabContent activeTabID={activeTabID} useKey={true} paddingBottom={"0px"}>
                                {Object.entries(data).map(([key, financialStatementData]) => {
                                    const statementData = financialStatementData.statementData
                                    const statementID = financialStatementData.statementID
                                    
                                    const allRows = Object.entries(statementData).map(([dict_key, value], index)=>{
                                        const dataType = value.dataType
                                        const dataContent = value.dataContent
                                
                                        return <FinancialStatementRow onThClick={handleRowClick} key={index} rowname={dict_key} statementID={statementID} arrayType={dataType} array={dataContent} isActive={rowsPlotted.map(row=>row.rowName).includes(dict_key)} activeRowIndex={rowsPlotted.map(row=>row.rowName).indexOf(dict_key)} />
                                    })
                                
                                    const headerRow = allRows.slice(0,2)
                                    const bodyRows = allRows.slice(2); 
                                    return (
                                        <div key={key} id={statementID}>
                                            <ZenithTable1.Table 
                                            tablehead={headerRow}
                                            tablebody={
                                                <>
                                                    {bodyRows}
                                                </>
                                            }
                                            /> 
                                        </div>
                                                                    
                                    )
                                })}
                        </WindowPieces.TabContent>
                    </div>
                    <WindowPieces.DownloadBox onClick={()=>downloadData()} title={"Download Financials"}
                    />
                </>
                

                    
            )}
        </>
    );
};

export default FinancialStatements;