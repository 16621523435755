import React from "react";

// const SettingsSection = ({sectionTitle, children}) => {
//     return (
//         <>
//             <div className="w-100">
//                 <h6 className="fw-bold">{sectionTitle}</h6>
//                 <div className="container-fluid p-0">
//                     {children}
//                 </div>
//             </div>
//             <hr></hr>
//         </>
//     )
// }

const SettingsSection = ({ sectionTitle, children }) => {
    return (
      <div className="mb-4">
        <h2 className="h5 fw-bold mb-3">{sectionTitle}</h2>
        <div className="mb-3">
          {children}
        </div>
        <hr />
      </div>
    );
  };

export default SettingsSection