import React, { useState, useEffect } from "react";
import CustomModal2 from "../CustomModal2";
import SettingsSection from "../../ui/small/wrappers/settings/SettingsSection";
import SettingLineWrapper from "../../ui/small/wrappers/settings/SettingLineWrapper";
import Button2 from "../../ui/small/buttons/Button2";
import createCustomerPortal from "../../../pages/CustomerPortal";
import axios from "axios";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import UserPlanBadge from "../../misc/UserPlanBadge";
import getRootColorString from "../../../functions/helpers/getRootColorString";
import handleCheckout from "../../../functions/handleCheckout";
import constants from "../../../constants";
import ajaxRequestFuncs from "../../../functions/requestFuncs/ajaxRequestFuncs";
// const BillingModal = ({ user, show, setShow }) => {
//   const [invoices, setInvoices] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (show) {
//       fetchInvoices();
//     }
//   }, [show]);

//   const fetchInvoices = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get("/get-invoices/");
//       setInvoices(response.data.invoices);
//       setLoading(false);
//     } catch (err) {
//       setError("Failed to fetch invoices. Please try again later.");
//       setLoading(false);
//     }
//   };
//   const primColor = getRootColorString("--primary-c")

//   return (
//     <CustomModal2
//       show={show}
//       title={"Billing"}
//       body={
//         <div style={{ width: "800px" }}>
//           <SettingsSection sectionTitle={"Plan"}>
//             <SettingLineWrapper label={"Current Plan"}>
//               <div className="d-flex justify-content-between align-items-center w-100">
//                 <UserPlanBadge groupName={user.group}/>
//                 {user.group === 'level1_user' && (
//                       <button 
//                       className="ms-2 btn border-none py-1 px-2 w-100 text-nowrap fw-bold" 
//                       style={{ borderRadius: '0px', background: `${primColor}50`, color: primColor}}
//                       onClick={()=>handleCheckout()}
//                       >
//                           Upgrade to Premium!
//                       </button>
//                   )}
//               </div>
//             </SettingLineWrapper>
//           </SettingsSection>
          
//           {(user && user.group !== "level1_user") && (
//             <SettingsSection sectionTitle={"Subscription Management"}>
//               <SettingLineWrapper label={"Cancel Subscription"}>
//                 <Button2 onClick={createCustomerPortal}>Cancel Subscription</Button2>
//               </SettingLineWrapper>
//             </SettingsSection>
//           )}

//           <SettingsSection sectionTitle={"Invoices"}>
    
//             {loading ? (
//               <div>Loading invoices...</div>
//             ) : error ? (
//               <div style={{ color: "var(--negative-c)" }}>{error}</div>
//             ) : invoices.length === 0 ? (
//               <div>No invoices found.</div>
//             ) : (

//               <ZenithTable1.Table 
//                 tablehead={
//                   <tr>
//                     <th>Date</th>
//                     <th>Amount Due</th>
//                     <th>Amount Paid</th>
//                     <th>Status</th>
//                     <th>Invoice</th>
//                   </tr>
//                 }
//                 tablebody={
//                   <>
//                     {invoices.map((invoice) => (
//                     <tr key={invoice.id}>
//                       <td>
//                         {new Date(invoice.created * 1000).toLocaleDateString()}
//                       </td>
//                       <td>
//                         ${invoice.amount_due.toFixed(2)}
//                       </td>
//                       <td>
//                         ${invoice.amount_paid.toFixed(2)}
//                       </td>
//                       <td>
//                         {invoice.status.toUpperCase()}
//                       </td>
//                       <td>
//                         <a href={invoice.url} target="_blank" rel="noopener noreferrer">
//                           View Invoice
//                         </a>
//                       </td>
//                     </tr>
//                   ))}
//                   </>
//                 }
              
//               />
//             )}
//           </SettingsSection>
//         </div>
//       }
//       onClose={() => setShow(false)}
//     />
//   );
// };

// export default BillingModal;

const BillingModal = ({ user, show, setShow }) => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (show && user) {
      fetchInvoices();
    }
  }, [show, user]);

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
      const response = await axios.post(constants.BACKEND_URL+'/get-invoices/', {}, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
        },
        withCredentials: true,
      });
      setInvoices(response.data.invoices);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch invoices. Please try again later.");
      setLoading(false);
    }
  };
  
  const primColor = getRootColorString("--primary-c");

  // If user is null, show a loading state or error message
  if (!user) {
    return (
      <CustomModal2
        show={show}
        title="Billing"
        body={
          <div className="p-4 text-center">
            <div>Unable to load user information. Please try again later.</div>
          </div>
        }
        onClose={() => setShow(false)}
      />
    );
  }

  return (
    <CustomModal2
      show={show}
      title="Billing"
      body={
        <div style={{ width: "800px" }}>
          <SettingsSection sectionTitle="Plan">
            <SettingLineWrapper label="Current Plan">
              <div className="d-flex justify-content-between align-items-center w-100">
                <UserPlanBadge groupName={user?.group || 'no_plan'}/>
                {user?.group === 'level1_user' && (
                  <button 
                    className="ms-2 btn border-none py-1 px-2 w-100 text-nowrap fw-bold" 
                    style={{ border: "none", borderRadius: '0px', background: `${primColor}50`, color: primColor}}
                    onClick={()=>handleCheckout()}
                  >
                    Upgrade to Premium!
                  </button>
                )}
              </div>
            </SettingLineWrapper>
          </SettingsSection>
          
          {(user?.group && user.group !== "level1_user") && (
            <SettingsSection sectionTitle="Subscription Management">
              <SettingLineWrapper label="User Portal">
                <Button2 onClick={createCustomerPortal}>User Portal</Button2>
              </SettingLineWrapper>
            </SettingsSection>
          )}

          <SettingsSection sectionTitle="Invoices">
            {loading ? (
              <div>Loading invoices...</div>
            ) : error ? (
              <div style={{ color: "var(--negative-c)" }}>{error}</div>
            ) : invoices.length === 0 ? (
              <div>No invoices found.</div>
            ) : (
              <ZenithTable1.Table 
                tablehead={
                  <tr>
                    <th>Date</th>
                    <th>Amount Due</th>
                    <th>Amount Paid</th>
                    <th>Status</th>
                    <th>Invoice</th>
                  </tr>
                }
                tablebody={
                  <>
                    {invoices.map((invoice) => (
                      <tr key={invoice.id}>
                        <td>
                          {new Date(invoice.created * 1000).toLocaleDateString()}
                        </td>
                        <td>
                          ${invoice.amount_due.toFixed(2)}
                        </td>
                        <td>
                          ${invoice.amount_paid.toFixed(2)}
                        </td>
                        <td>
                          {invoice.status.toUpperCase()}
                        </td>
                        <td>
                          <a href={invoice.url} target="_blank" rel="noopener noreferrer">
                            View Invoice
                          </a>
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            )}
          </SettingsSection>
        </div>
      }
      onClose={() => setShow(false)}
    />
  );
};

export default BillingModal;