import React, {useState, useEffect, useRef} from "react";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import LineChart from "../../charting/chartjs/LineChart";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import TearSheetSectionHeader from "../13_TearSheet/TearSheetSectionHeader";
import LineWrappers from "../13_TearSheet/LineWrappers";
import PieChart from "../../charting/chartjs/PieChart";
import FormattingComponents from "../../formatting/FormattingComponents";

const EarningsCalendar = ({ windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': "#N/A",
    };
    const ENDPOINT = '/earnings-calendar/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [data, setData] = useState([]);
    const [initialRender, setInitialRender] = useState(true);

    const [currentPage, setCurrentPage] = useState(1); // Current page state

    const autoSuggestRef = useRef(null);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };

            updateLayoutItem(windowID, {
                windowParameters: params,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data
                    const status = responseData.status
                    const data = responseData.data
    
                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                        setCurrentPage(1)
                    } else if (status === "error") {
                        setError(responseData.message)
                    }
    
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });

            return newParams
        })

        

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, [params.name]);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }
    const formatLargeDollarFigure = (number) => {
        if (number >= 1000000000) {
            return `${(number / 1000000000).toFixed(2)}B`;
        } else if (number >= 1000000) {
            return `${(number / 1000000).toFixed(2)}M`;
        }
        return number.toFixed(0);
    };

    const currentResults = (data || []).slice(
                    (currentPage - 1) * constants.TABLE_MAX_ROWS,
                    currentPage * constants.TABLE_MAX_ROWS
                );
            
            // Calculate total pages
    const totalPages = Math.ceil(data.length / constants.TABLE_MAX_ROWS);

    // Change page
    const changePage = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePageInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            changePage(value);
        }
    };

    
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    
    const unavailable = <td></td>


    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    <WindowPieces.Content>
                        <ZenithTable1.Table 
                            tablehead={
                                <tr>
                                    <th>Date</th>
                                    <th>Symbol</th>
                                    <th>EPS</th>
                                    <th>EPS Estimated</th>
                                    <th>EPS Suprise</th>
                                    <th>Revenue</th>
                                    <th>Revenue Estimated</th>
                                    <th>Revenue Suprise</th>
                                    <th>Fiscal Period Ending</th>
                                </tr>
                            }
                            tablebody={
                                <>
                                    {currentResults.map((item, index)=>{
                        
                                        const supriseRel = (!item.eps && !item.epsEstimated)? 'N/A':  (item.eps/item.epsEstimated) - 1
                                        const epsColor = item.eps == item.epsEstimated? '': (item.eps > item.epsEstimated? "var(--positive)": "var(--negative)")

                                        const supriseRelRevenue = (!item.revenue && !item.revenueEstimated)? 'N/A':  (item.revenue/item.revenueEstimated) - 1
                                        const revenueColor = item.revenue == item.revenueEstimated? '': (item.revenue > item.revenueEstimated? "var(--positive)": "var(--negative)")

                                        return (
                                        <tr key={index}>
                                            {item.date? <td>{item.date}</td>: unavailable}   
                                            {item.symbol? <td>{item.symbol}</td>: unavailable}
                                            {item.eps? <td>{item.eps.toFixed(2)}</td>: unavailable}
                                            {item.epsEstimated? <td>{item.epsEstimated.toFixed(2)}</td>: unavailable}
                                            <td style={{color: epsColor}}><FormattingComponents.NumberRounded numDecimals={2} value={supriseRel}/></td>
                                            {item.revenue? <td>{formatLargeDollarFigure(item.revenue)}</td>: unavailable}
                                            {item.revenueEstimated? <td>{formatLargeDollarFigure(item.revenueEstimated)}</td>: unavailable}
                                            <td style={{color: revenueColor}}><FormattingComponents.NumberRounded numDecimals={2} value={supriseRelRevenue}/></td>
                                            {item.fiscalDateEnding? <td>{item.fiscalDateEnding}</td>: unavailable}                                      
                                        </tr>
                                    )})}
                                </>
                            }
                        />        
                    </WindowPieces.Content>
                    <WindowPieces.PageViewer 
                        currentPage={currentPage}
                        totalPages={totalPages}
                        changePageFunc={changePage}
                        handlePageInput={handlePageInputChange}
                    />
                </>
            )}
        </>
    );
};

export default EarningsCalendar;
