import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import ETFHoldings from "../../../components/FeatureComponents/28_ETFHoldings/28_ETFHoldings";

async function handleETFHoldings(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-etf-analysis';
    const TITLE_PREFIX = 'ETF Analysis';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<ETFHoldings symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleETFHoldings ;
