import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';
import getRootColorString from '../../../functions/helpers/getRootColorString';
import SettingsConfigButton from '../../ui/small/buttons/SettingsConfigButton';
import fundamentals from '../../../catalogs/fundamentals';

const BarChart = ({ windowID, data, labels, params}) => {
    const [showSettings, setShowSettings] = useState(false)

    Chart.defaults.font.family =  'Inter'
    const textColor = getRootColorString("--text")
    const chartContainerRef = useRef(null)

    const colors = constants.getColorPallete()


    const dataset = Object.entries(data).map(([symbol, dataArray], index)=>{
        const color = colors[index % colors.length];
        
        return {
            type: 'bar',
            label: symbol,
            data: dataArray,
            borderColor: '',
            borderWidth: 0,
            backgroundColor: color,
            barPercentage: 1,
        }
    })

    const backendToFrontendName = (backendName) => {
        return fundamentals.find((item)=> backendName === item.fmpName)?.name
      }

    useEffect(() => {
        if (!data || !labels) return;

        const chart = new Chart(chartContainerRef.current, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: dataset
            },
            options: {
                animation: false,
                scales: {
                    x: {
                        ticks: {
                            color: textColor,
                        },
                        grid: {
                            display: false,
                            color: '#444',
                        }
                    }
                },
                plugins: {
                    title: {
                        display: true, // Show the title
                        text: `${backendToFrontendName(params.metric)}`, // Title text
                        font: {
                          size: 18, // Font size
                        },
                        color: getRootColorString("--text"), // Font color
                        padding: {
                          top: 10,
                          bottom: 10,
                        },
                        align: 'center', 
                      },
                    htmlLegend: {
                        containerID: windowID+' '+'legend-container',
                      },
                    legend: {
                        display: false,
                        },
                    tooltip: {
                        ...constants.CHARTJS_TOOLTIP_STYLE,
                        callbacks: {
                            label: function (tooltipItem) {
                                const datasetIndex = tooltipItem.datasetIndex;
                                const yAxisID = tooltipItem.chart.data.datasets[datasetIndex].yAxisID;
                                
                                return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;

                            },
                        },
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            plugins: [constants.CHARTJS_HTML_LEGEND_PLUGIN],
        });

        return () => {
            chart.destroy();
        };
    }, [data, labels]);

    return (
        <>
            <div id={windowID+' '+'legend-container'}></div>
            <div>
                <canvas ref={chartContainerRef} style={{ width: '200px', height: '300px'}}></canvas>
            </div>
        </>
    );
};

export default BarChart;