const DropdownWrapper = ({iconSVG, label, onClick}) => {
    return (
        <a className="dropdown-item hover-item d-flex flex-row  px-3 py-2 align-items-center" onClick={onClick}>
            <div className="figr-icon">
                {iconSVG}
            </div>
            <div className="mx-3" style={{fontSize: "12px"}}>
                {label}
            </div>
        </a>
    )   
  }

export default DropdownWrapper;