import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import EarningsCalendar from "../../../components/FeatureComponents/29_EarningsCalendar/29_EarningsCalendar";

async function handleEarningsCalendar(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-earnings-calendar';
    const TITLE_PREFIX = 'Earnings Calendar';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<EarningsCalendar windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleEarningsCalendar ;
