import axios from "axios";
import constants from "../../constants";
import ajaxRequestFuncs from "../requestFuncs/ajaxRequestFuncs";

function checkPermissions(featureName) {
    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken')
    return axios.post(constants.BACKEND_URL+'/check-permissions/', { feature_name: featureName }, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,  
        },
        withCredentials: true,  
        })
        .then(response => {
            if (response.data.status === "success") {
                return true;
            }
            return false;
        })
        .catch(error => {
            console.error("Permission check failed:", error.response?.data?.message || error.message);
            return false;
        });
}

export default checkPermissions