import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import handleInstitutionalPortfolioComposition from "../../../functions/requestFuncs/handling/handleInstitutionalPortfolioComposition";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import ZenithTable1Sortable from "../../ui/small/tables/ZenithTable1Sortable";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import deferExecution from "../../../functions/deferExecution";


const InstitutionalOwnershipByStock = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'year': 2024,
        'quarter': 'Q3',
        'date': '2024-06-30'
    };
    const ENDPOINT = '/institutional-ownership-by-stock/';
    
    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);

    const [hoveredCIK, setHoveredCIK] = useState(null)

    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data
                    const status = responseData.status
                    const data = responseData.data

                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                    }

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const cols = [
        { key: "investorName", label: "Investor" },
        { key: "marketValue", label: "Market Value" },
        { key: "sharesNumber", label: "# Shares" },
        { key: "avgPricePaid", label: "Average Price" },
        { key: "ownership", label: "% Shares Outstanding Owned" },
        { key: "date", label: "Date of Filing" },
        { key: "firstAdded", label: "First Added" },
        ];        
    

    const totalOwnership = ({}) => {
        const totalOwnership = Array.from(data.map(item=>item.ownership))
        const sum = totalOwnership.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        return sum
    }
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    
    const PutCallShareBox = ({putCallShare}) => {
        const color = putCallShare === "PUT" ? "var(--negative)" : putCallShare === "CALL" ? "var(--positive)" : "var(--text)"

        return (
            <div className="p-1" style={{color: color}}>
                {putCallShare.toUpperCase()}
            </div>
        )
    }

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                    id={'symbol'}
                    ref={autoSuggestRef}
                    defaultValue={params.symbol}
                    handleSubmitCustom={requestData}
                />
                <WindowPieces.ConfigContainer>
                    <WindowPieces.SquareInput 
                        id={'year'}
                        type={'number'}
                        placeholder={'Year'}
                        value={params.year}
                        onChange={updateParams}
                    />
                    <WindowPieces.SquareSelect
                        id={'quarter'}
                        innerHTMLList={['Q1', 'Q2', 'Q3', 'Q4']} 
                        valueList={['Q1', 'Q2', 'Q3', 'Q4']}
                        value={params.quarter}
                        onChange={updateParams}

                    />   


                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                </WindowPieces.ConfigContainer>

            </WindowPieces.OptionsContainer>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>

                    <WindowPieces.Content>
                        {/* <ZenithTable1Sortable
                            columns={cols}
                            data={data}
                        /> */}
                        {/* We need to have the onHover click to go to instittutional portfolio */}

                        <ZenithTable1.Table 
                            tablehead={
                                <tr>
                                    <th>Investor Name</th>
                                    <th>Market Value (MM)</th>
                                    <th># of Shares</th>
                                    <th>Put/Call/Share</th>
                                    <th>Average Price Paid</th>
                                    <th>% of Shares Outstanding Owned</th>
                                    <th>Date of Filing</th>
                                    <th>Date first Added</th>
                                    
                                </tr>
                            }
                            tablebody={
                                <>
                                {data.map((item, index)=>{
                                    return (
                                    <tr key={index}>
                                        <td
                                            onMouseEnter={()=>setHoveredCIK(item.cik)}
                                            onMouseLeave={()=>setHoveredCIK(null)}
                                            style={{ position: 'relative' }}
                                        
                                        >
                                            {item.investorName}
                                            {hoveredCIK === item.cik && (
                                                <span
                                                    title={`${item.investorName} Portfolio Composition`}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        color: "var(--primary)",
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={()=>deferExecution(()=>handleInstitutionalPortfolioComposition(hoveredCIK))}
                                                >
                                                    &#8594;
                                                </span>
                                            )}    
                                    
                                        </td>
                                        <td>{`$${(item.marketValue/1000000).toFixed(2)}`}</td>
                                        <td>{item.sharesNumber}</td>
                                        <td>
                                            <PutCallShareBox putCallShare={item.putCallShare.toUpperCase()}/>
                                        </td>
                                        <td>{item.avgPricePaid}</td>
                                        <td>{item.ownership}%</td>
                                        <td>{item.date}</td>
                                        <td>{item.firstAdded}</td>                                    
                                    </tr>
                                )})}
                                </>
                            }
                        
                        />
                    </WindowPieces.Content>
                </>
            )}
        </>
    );
};

export default InstitutionalOwnershipByStock;