import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";

const AnalystRatings = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
    };
    const ENDPOINT = '/analyst-ratings/';
    const tabs = [
        { label: "Ratings", targetID: "ratings" },
        { label: "Consensus", targetID: "consensus" },
    ];
    const DEFAULT_TAB='ratings'

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [activeTabID, setActiveTabID] = useState(DEFAULT_TAB)
    const [data, setData] = useState({ ratingsTable: [] });
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);

    const [currentPage, setCurrentPage] = useState(1); // Current page state
    

    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
            .then(response => {
                const responseData = response.data
                const status = responseData.status
                const data = responseData.data

                if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                    setError(responseData.message)
                    showPremiumPrompt(responseData.message)
                } else if (status === "success") {
                    setData(data)
                    setCurrentPage(1)
                }

            })
            .catch(error => {
                console.error('Error during POST request:', error);
                setError("Failed to load data.");
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };


    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }


    const currentResults = (data.ratingsTable || []).slice(
            (currentPage - 1) * constants.TABLE_MAX_ROWS,
            currentPage * constants.TABLE_MAX_ROWS
        );
    
    // Calculate total pages
    const totalPages = Math.ceil(data.ratingsTable.length / constants.TABLE_MAX_ROWS);

    // Change page
    const changePage = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePageInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            changePage(value);
        }
    };








    const handleTabClick = (targetID) => {
        setActiveTabID(targetID); // Update active tab on click
        
    };

    function formatDateToISO(dateString) {
        const date = new Date(dateString);
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it's zero-based
        const day = String(date.getDate()).padStart(2, '0');
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                        id={'symbol'}
                        ref={autoSuggestRef}
                        defaultValue={params.symbol}
                        handleSubmitCustom={requestData}
                    />
                <WindowPieces.ConfigContainer>
                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                </WindowPieces.ConfigContainer>
            </WindowPieces.OptionsContainer>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    

                    {data.ratingsTable && data.ratingsTable.length > 0? (
                        <>
                            <WindowPieces.Content>
                                <p className='text-primary fw-light px-2 py-1'>Viewing page {currentPage} of {totalPages}</p>
                                <ZenithTable1.Table
                                    tablehead={
                                    <tr>
                                        <th>Action</th>
                                        <th>Grade Change</th>
                                        <th>Price When Posted</th>
                                        <th>Date</th>
                                        <th>Analyst Company</th>
                                        <th>Article URL</th>
                                    </tr>
                                    }
                                    tablebody={
                                        <>
                                            {currentResults.map((rating, index)=>{
                                                const action = rating.action

                                                const textColor = action=="initialise"? "var(--text)" : action == "hold"? "var(--neutral)": action == "upgrade"? "var(--positive)": "var(--negative)"
                                                const backgroundColor = `${textColor}50`


                                                return (
                                                <tr key={index}>
                                                    <td style={{color: textColor}}>{action.toUpperCase()}</td>
                                                    <td style={{color: textColor}}>{rating.previousGrade} &rarr; {rating.newGrade}</td>
                                                    <td>${rating.priceWhenPosted.toFixed(2)}</td>
                                                    <td>{formatDateToISO(rating.publishedDate)}</td>
                                                    <td>{rating.gradingCompany}</td>
                                                    <td><a target="_blank" style={{color: "var(--primary)"}}  rel="noopener noreferrer" href={rating.newsURL}>URL</a></td>                                            
                                                </tr>
                                            )})}
                                        </>
                                    }
                                
                                />
                            </WindowPieces.Content>
                            <WindowPieces.PageViewer 
                                currentPage={currentPage}
                                totalPages={totalPages}
                                changePageFunc={changePage}
                                handlePageInput={handlePageInputChange}
                            />
                        </>

                        
                    ): (
                        <div>No data available</div>
                    )}
                      
                    

              
                    
                </>
            )}
        </>
    );
};

export default AnalystRatings;