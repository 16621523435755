import handleFinancialStatements from '../functions/requestFuncs/handling/handleFinancialStatements';
import handleHistoricalPrice from '../functions/requestFuncs/handling/handleHistoricalPrice';
import handleEarningsTranscripts from '../functions/requestFuncs/handling/handleEarningsTranscripts';
import handleAnalystPriceTargets from '../functions/requestFuncs/handling/handleAnalystPriceTargets';
import handleAnalystRatings from '../functions/requestFuncs/handling/handleAnalystRatings';
import handleCompanyNews from '../functions/requestFuncs/handling/handleCompanyNews';
import handleTearSheet from '../functions/requestFuncs/handling/handleTearSheet';
import handleInsiderTrading from '../functions/requestFuncs/handling/handleInsiderTrading';
import handleSenateTrading from '../functions/requestFuncs/handling/handleSenateTrading';
import handleSenateHouseDisclosure from '../functions/requestFuncs/handling/handleSenateHouseDisclosure';
import handleSECFilings from '../functions/requestFuncs/handling/handleSECFilings';
import handleGroupTime from '../functions/requestFuncs/handling/handleGroupTime';
import handleGroupMetric from '../functions/requestFuncs/handling/handleGroupMetric';
import handleWatchlist from '../functions/requestFuncs/handling/handleWatchlist';
import handleMarketScanner from '../functions/requestFuncs/handling/handleMarketScanner';
import handleScreener from '../functions/requestFuncs/handling/handleScreener';
import handleHelp from '../functions/requestFuncs/handling/handleHelp';
import handleInstitutionalOwnershipByStock from '../functions/requestFuncs/handling/handleInstitutionalOwnershipByStock';
import handleInstitutionalPortfolioComposition from '../functions/requestFuncs/handling/handleInstitutionalPortfolioComposition';
import handleDCF from '../functions/requestFuncs/handling/handleDCF';
import handleBeta from '../functions/requestFuncs/handling/handleBeta';
import handleReturnDistribution from '../functions/requestFuncs/handling/handleReturnDistribution';
import handleEps from '../functions/requestFuncs/handling/handleEps';
import handleWidget from '../functions/requestFuncs/handling/handleWidget';
import handleTopGainersLosers from '../functions/requestFuncs/handling/handleTopGainersLosers';
import handleEconomicSeries from '../functions/requestFuncs/handling/handleEconomicSeries';
import handleETFHoldings from '../functions/requestFuncs/handling/handleETFHoldings';
import handleEarningsCalendar from '../functions/requestFuncs/handling/handleEarningsCalendar';

const features = [
    {
        windowTypeID: 'window-financial-statements',
        handler: handleFinancialStatements,
        code: 'FA',
        featureName: 'Financial Analysis',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 480 ,
        minHeight: 400 ,

    },
    {
        windowTypeID: 'window-historical-price',
        handler: handleHistoricalPrice,
        code: 'C',
        featureName: 'Live Chart',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 400 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-earnings-transcripts',
        handler: handleEarningsTranscripts,
        code: 'ET',
        featureName: 'Earnings Transcripts',
        featureDescription: '',
        securityFunction: true,
        linkable: true ,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-analyst-price-targets',
        handler: handleAnalystPriceTargets,
        code: 'APT',
        featureName: 'Analyst Price Targets',
        featureDescription: '',
        securityFunction: true,
        linkable: true ,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-analyst-upgrades-downgrades',
        handler: handleAnalystRatings,
        code: 'AR',
        featureName: 'Analyst Ratings' ,
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-company-news',
        handler: handleCompanyNews,
        code: 'NEWS' ,
        featureName: 'Company News',
        featureDescription:'' ,
        securityFunction: true,
        linkable: true,
        exportable: false,
        isResizable:true,
        minWidth: 400 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-sec-filings',
        handler: handleSECFilings,
        code: 'SEC',
        featureName: 'SEC Filings',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-tear-sheet',
        handler: handleTearSheet,
        code: 'TEAR',
        featureName: 'Tear Sheet',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 650 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-insider-trading',
        handler: handleInsiderTrading,
        code: 'INSIDER',
        featureName: 'Insider Trading',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-senate-trading',
        handler: handleSenateTrading,
        code: 'SENATE',
        featureName: 'Senate Trading',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    // {
    //     windowTypeID: 'window-senate-house-disclosure',
    //     handler: handleSenateHouseDisclosure,
    //     code: 'HOUSE',
    //     featureName: 'Senate House Disclosures',
    //     featureDescription: '',
    //     securityFunction: true,
    //     linkable: true,
    //     exportable: true,
    //     isResizable:true,
    //     minWidth: 200 ,
    //     minHeight: 200 ,

    // },
    {
        windowTypeID: 'window-group-time',
        handler: handleGroupTime,
        code: 'GROUPT',
        featureName: 'Group Analysis By Time',
        featureDescription: '',
        securityFunction: true,
        linkable: false,
        exportable: true,
        isResizable:true,
        minWidth: 550 ,
        minHeight: 200 ,

    },
    // {
    //     windowTypeID: 'window-group-metric',
    //     handler: handleGroupMetric,
    //     code: 'GROUPM',
    //     featureName: 'Group Analysis By Metric',
    //     featureDescription: '',
    //     securityFunction: true,
    //     linkable: false,
    //     exportable: true,
    //     isResizable:true,
    //     minWidth: 200 ,
    //     minHeight: 200 ,

    // },
    {
        windowTypeID:  'window-watchlist',
        handler: handleWatchlist,
        code: 'WATCH',
        featureName: 'Watchlist',
        featureDescription: '',
        securityFunction: false,
        linkable: false,
        exportable: false,
        isResizable:true,
        minWidth: 285 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-market-scanner',
        handler: handleMarketScanner,
        code: 'SCAN',
        featureName: 'Market Scanner',
        featureDescription: '',
        securityFunction: false,
        linkable: false,
        exportable: false,
        isResizable:true,
        minWidth: 400 ,
        minHeight: 200 ,

    },
    // {
    //     windowTypeID: 'window-screener',
    //     handler: handleScreener,
    //     code: 'SCREEN',
    //     featureName: 'Advanced Equity Screener',
    //     featureDescription: '',
    //     securityFunction: false,
    //     linkable: false,
    //     exportable: true,
    // isResizable:true,    
    // minWidth: 200 ,
    //     minHeight: 200 ,

    // },
    {
        windowTypeID: 'window-help',
        handler: handleHelp,
        code: 'HELP',
        featureName: 'Help',
        featureDescription: '',
        securityFunction: false,
        linkable: false,
        exportable: false,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-institutional-ownership-by-stock',
        handler: handleInstitutionalOwnershipByStock,
        code: 'I',
        featureName: 'Institutional Ownership',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-institutional-portfolio-composition',
        handler: handleInstitutionalPortfolioComposition,
        code: '',   // not accessed from commandline
        featureName: 'Institutional Portfolio Composition',
        featureDescription: '',
        securityFunction: false,
        linkable: false,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    // {
    //     windowTypeID: 'window-dcf',
    //     handler: handleDCF,
    //     code: 'DCF',
    //     featureName: 'Discounted Cash Flow Model',
    //     featureDescription: '',
    //     securityFunction: true,
    //     linkable: false,
    //     exportable: true,
    // isResizable:true,    
    // minWidth: 200 ,
    //     minHeight: 200 ,

    // },
    {
        windowTypeID: 'window-beta',
        handler: handleBeta,
        code: 'BETA',
        featureName: 'Beta Regression',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 200 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-ret-dist',
        handler: handleReturnDistribution,
        code: 'RETDIST',
        featureName: 'Return Distribution',
        featureDescription: '',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 300 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-eps',
        handler: handleEps,
        code: 'EPS',
        featureName: 'Earnings Per Share',
        featureDescription: 'Earnings Per Share',
        securityFunction: true,
        linkable: true,
        exportable: true,
        isResizable:true,
        minWidth: 400 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-widget',
        handler: handleWidget,
        code: 'W',
        featureName: 'Widget',
        featureDescription: 'Widget',
        securityFunction: true,
        linkable: true,
        exportable: false,
        isResizable:false,
        minWidth: 300 ,
        minHeight: 200 ,

    },
    {
        windowTypeID: 'window-top-gainers-losers',
        handler: handleTopGainersLosers,
        code: 'TOP',
        featureName: 'Top Gainers & Losers',
        featureDescription: 'Top Gainers & Losers',
        securityFunction: false,
        linkable: false,
        exportable: false,
        isResizable:true,
        minWidth: 280 ,
        minHeight: 100 ,

    },
    {
        windowTypeID: 'window-economic-series',
        handler: handleEconomicSeries,
        code: 'ECON',
        featureName: 'Economic Series',
        featureDescription: 'Economic Series',
        securityFunction: false,
        linkable: false,
        exportable: false,
        isResizable:true,
        minWidth: 220 ,
        minHeight: 100 ,

    },
    {
        windowTypeID: 'window-etf-analysis',
        handler: handleETFHoldings,
        code: 'ETFA',
        featureName: 'ETF Analysis',
        featureDescription: 'ETF Analysis',
        securityFunction: true,
        linkable: false,
        exportable: false,
        isResizable:true,
        minWidth: 650 ,
        minHeight: 100 ,

    },
    {
        windowTypeID: 'window-earnings-calendar',
        handler: handleEarningsCalendar,
        code: 'ECAL',
        featureName: 'Earnings Calendar',
        featureDescription: 'Earnings Calendar',
        securityFunction: false,
        linkable: false,
        exportable: false,
        isResizable:true,
        minWidth: 220 ,
        minHeight: 100 ,

    },
]

export default features