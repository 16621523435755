import React from "react";

const PageDownButton = ({onClick}) => {
    return (
        <button 
            id='page-up' 
            style={{ width: "30px", aspectRatio: '1', borderRadius: "0px", borderColor: "var(--secondary)" }} 
            className="btn btn-small border ms-2" 
            onClick={onClick}
        >
            &larr;
        </button>
    )
}
export default PageDownButton