import createWindow from "./createWindow";
import dashboardLayout from "../memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../memoryManagement/layoutManager";

export default async function prepareWindow(symbol, windowTypeName, titlePrefix, isInitialDisplay, existingWindowID){
    let uniqueWindow;
    let destinationElement;

    // 1. Creating the Window or finding it, if it already exists.
    if (isInitialDisplay){
        uniqueWindow=await createWindow(windowTypeName)
        destinationElement = uniqueWindow.querySelector('.window-content')
        destinationElement.id = `${uniqueWindow.id}-content`
    }
    else {
        uniqueWindow=document.getElementById(existingWindowID)
        destinationElement=uniqueWindow.querySelector('.window-content')
    }

    const windowTitle = titlePrefix
    uniqueWindow.querySelector('.window-title').innerHTML = windowTitle;

    return uniqueWindow
}
