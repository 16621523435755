import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import constants from '../constants';
import ajaxRequestFuncs from '../functions/requestFuncs/ajaxRequestFuncs';

const createCustomerPortal = async () => {
    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
    try {
        const response = await axios.post(constants.BACKEND_URL+'/create-customer-portal/', {}, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
            withCredentials: true,
        });
        window.location.href = response.data.url; 
        
    } catch (err) {
        console.error("Error creating customer portal session:", err);
    }
};

export default createCustomerPortal
