import React from "react";

const Th = ({children}) => {
    return (
        <th>
            {children}
        </th>
    )
}

const Td = ({children}) => {
    return (
        <td>
            {children}
        </td>
    )
}

const Tr = ({children}) => {
    return (
        <tr>
            {children}
        </tr>
    )
}

// const Table = ({tablehead, tablebody}) => { 
//     return (
       
//         <table style={{backgroundColor: "var(--theme)"}} className="w-100 table table-hover table-borderless m-0">
//             <thead style={{borderColor: "var(--secondary)"}} className="border-bottom">
//                 {tablehead}
//             </thead>
//             <tbody>
//                 {tablebody}
//             </tbody>
//         </table>
   
//     )
// }

const Table = ({tablehead, tablebody}) => { 
    return (
       
        <table style={{backgroundColor: "var(--theme)"}} className="w-100 m-0">
            <thead style={{borderColor: "var(--secondary)"}} className="border-bottom">
                {tablehead}
            </thead>
            <tbody>
                {tablebody}
            </tbody>
        </table>
   
    )
}

export default {
    Th,
    Td,
    Tr,
    Table
}