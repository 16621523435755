import React from "react";

const LoadButton = ({children, onClick}) => {
    return (
        <button

            style={{height: '28px', backgroundColor: "var(--secondary)"}}
            onClick={onClick}
            className="load-button btn text-primary text-nowrap rounded-0 my-1 me-1  py-0 fw-bold"
        >
            {children}
        </button>
    )
}  

export default LoadButton