import React, { useState, useEffect } from "react";
import CustomModal2 from "../CustomModal2";
import SettingsSection from "../../ui/small/wrappers/settings/SettingsSection";
import SettingLineWrapper from "../../ui/small/wrappers/settings/SettingLineWrapper";
import ColorPicker from "../../ui/small/ColorPicker";
import Button2 from "../../ui/small/buttons/Button2";
import userPreferences from "../../../functions/memoryManagement/userPreferences";
import updateRootColor from "../../../functions/helpers/updateRootColor";
import { showAlert } from "../../../functions/showAlert";
import SettingsSelect from "../../ui/small/SettingsSelect";
import createCustomerPortal from "../../../pages/CustomerPortal";

const presetThemes = {
  default: {
    themename: "default",

    primary: "#ffd700",
    grid: "#212121",
    theme: "#000000",
    secondary: "#212121",
    text: "#D3D3D3",

    positive: "#0CB577",
    negative: "#b50c3c", 
  },
  dark: {
    themename: "dark",

    primary: "#ffd700",
    grid: "#000000",
    theme: "#000000",
    secondary: "#121212",
    text: "#D3D3D3",


    positive: "#00FF00",
    negative: "#FF0000", 
  },
  light: {
    themename: "light",

    primary: "#107869",
    grid: "#bababa",
    theme: "#ececec",
    secondary: "#bdbdbd",
    text: "#181818",


    positive: "#0CB577",
    negative: "#b50c3c", 
  },
  sand: {
    themename: "sand",

    primary: "#000000",
    grid: "#898267",
    theme: "#ddd3ae",
    secondary: "#8e8e6c",
    text: "#000000",
    
    positive: "#0CB577",
    negative: "#b50c3c", 

  },
  midnight: {
    themename: "midnight",

    primary: "#1477B2",
    grid: "#0F4A6F",
    theme: "#001F3D",
    secondary: "#16478f",
    text: "#CCDADA",

    positive: "#0CB577",
    negative: "#b50c3c",
  },
};



const SettingsModal = ({ show, setShow }) => {
    const [currentSettings, setCurrentSettings] = useState({
        theme: getComputedStyle(document.documentElement).getPropertyValue('--theme') || '#000000',
        primary: getComputedStyle(document.documentElement).getPropertyValue('--primary') || '#4F9DAC',
        positive: getComputedStyle(document.documentElement).getPropertyValue('--positive') || '#4CAF50',
        negative: getComputedStyle(document.documentElement).getPropertyValue('--negative') || '#F44336',
        grid: getComputedStyle(document.documentElement).getPropertyValue('--grid') || '#212121',
        // textColor: getComputedStyle(document.documentElement).getPropertyValue('--text-color') || '#333333',
        });

  const [adjustedSettings, setAdjustedSettings] = useState({});

  // Function to update the local adjustedSettings
  const setAdjustedSetting = (key, value) => {
    setAdjustedSettings((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  
  const setTheme = (theme) => {
    const selectedTheme = presetThemes[theme] || presetThemes.light; // Default to light if invalid theme
    setAdjustedSettings((prevSettings) => ({
      ...prevSettings,
      ...selectedTheme, // Apply the selected theme's settings
    }));
  };
  const setPrimary = (color) => setAdjustedSetting('primary', color);
  const setPositive = (color) => setAdjustedSetting('positive', color);
  const setNegative = (color) => setAdjustedSetting('negative', color);
  const setGridBg = (color) => setAdjustedSetting('grid', color);
  const setTextColor = (color) => setAdjustedSetting('textColor', color);

  // const preferenceType = 'globalColors'
  // useEffect(() => {
  //   if (show) {
  //     userPreferences.getUserPreferences()
  //       .then((preferences) => {
  //         setCurrentSettings((prevSettings) => {
  //           const mergedSettings = {
  //             ...prevSettings,
  //             [preferenceType]: {
  //               ...prevSettings[preferenceType],
  //               ...preferences[preferenceType]
  //             }
  //           };
  //           setAdjustedSettings(mergedSettings);
  //           return mergedSettings;
  //         });
  //       })
  //       .catch((error) => {
  //         console.error(`Error loading ${preferenceType}:`, error);
  //       });
  //   }
  // }, [show, preferenceType]);
  
  // const saveChanges = () => {
  //   const prefsToSave = {
  //     ...currentSettings,
  //     [preferenceType]: adjustedSettings[preferenceType]
  //   };
  
  //   userPreferences.saveUserPreferences(prefsToSave)
  //     .then(() => {
  //       if (preferenceType === 'globalColors') {
  //         Object.entries(adjustedSettings[preferenceType]).forEach(([key, value]) => {
  //           updateRootColor(key, value);
  //         });
  //       }
  //       showAlert("success", "Settings saved!");
  //       setShow(false);
  //     })
  //     .catch((error) => {
  //       console.error(`Error saving ${preferenceType}:`, error);
  //       showAlert("error", "Failed to save settings");
  //     });
  // };

  useEffect(() => {
    if (show) {
      // Fetch the user preferences from the backend
      userPreferences.getUserPreferences()
        .then((preferences) => {
            setCurrentSettings((prevSettings) => {
                const mergedSettings = {
                    ...prevSettings,
                    ...preferences.preferences,
                }
                setAdjustedSettings(mergedSettings); // Update adjusted settings
                return mergedSettings
            });
        })
        .catch((error) => {
          console.error("Error loading user preferences:", error);
        });
    }
  }, [show]);  // Run this effect when the modal is opened (`show` changes)

  // Function to handle color changes
  const saveChanges = () => {
    Object.entries(adjustedSettings).forEach(([key, value]) => {
      updateRootColor(key, value);
    });
    

    userPreferences.saveUserPreferences(adjustedSettings)
      .then(() => {
        // After saving, update the root colors
        Object.entries(adjustedSettings).forEach(([key, value]) => {
          updateRootColor(key, value);
        });
        showAlert("success", "Settings saved! Please refresh the page for changes to fully take effect.")
        setShow(false); // Close the modal after saving changes

      })
      .catch((error) => {
        console.error("Error saving user preferences:", error);
        showAlert("error", "Failed to save settings")
      });
  };


  return (
    <CustomModal2
      show={show}
      title={"Preferences"}
      body={
        <div style={{ width: "800px" }}>
          <SettingsSection sectionTitle={"Global Colors"}>
            <SettingLineWrapper label={"Themes"}>
              <SettingsSelect 
                innerHTMLList={['FIGR Default', 'Dark', 'Light', 'Midnight', 'Sand']}
                valueList={['default', 'dark', 'light', 'midnight', 'sand']}
                value={adjustedSettings.themename || currentSettings.themename}
                setFunc={setTheme}
              />
            </SettingLineWrapper>
            <SettingLineWrapper label={"Primary"}>
              <ColorPicker
                value={adjustedSettings.primary || currentSettings.primary}
                setColor={setPrimary}
              />
            </SettingLineWrapper>
            <SettingLineWrapper label={"Positive Color"}>
              <ColorPicker
                value={adjustedSettings.positive || currentSettings.positive}
                setColor={setPositive}
              />
            </SettingLineWrapper>
            <SettingLineWrapper label={"Negative Color"}>
              <ColorPicker
                value={adjustedSettings.negative || currentSettings.negative}
                setColor={setNegative}
              />
            </SettingLineWrapper>
            <SettingLineWrapper label={"FIGR Grid Background"}>
              <ColorPicker
                value={adjustedSettings.grid || currentSettings.grid}
                setColor={setGridBg}
              />
            </SettingLineWrapper>
            {/* <SettingLineWrapper label={"Text Color"}>
              <ColorPicker
                value={adjustedSettings.textColor || currentSettings.textColor}
                setColor={setTextColor}
              />
            </SettingLineWrapper> */}
          </SettingsSection>
          <Button2 onClick={saveChanges}>Save Changes</Button2>
        </div>
      }
      onClose={() => setShow(false)}
    />
  );
};

export default SettingsModal;
