const tooltipFinancialDefinitions = {
    // Income Statement
    "Revenue": "Total income generated from sales or services before any expenses are deducted.",
    "Cost of Revenue": "Direct costs attributable to the production of goods or services sold.",
    "Gross Profit": "Revenue minus the cost of revenue; measures profitability after production costs.",
    "Operating Expenses": "Costs incurred to run the core business operations, excluding the cost of revenue.",
    "Operating Income": "Gross profit minus operating expenses; reflects profit from regular business operations.",
    "Interest Income": "Earnings from interest-generating assets like savings or investments.",
    "Interest Expense": "Costs incurred from borrowing or other financial obligations.",
    "Income Before Tax": "Profit calculated after accounting for all operating and non-operating income and expenses, but before taxes.",
    "Income Tax Expense": "The amount of taxes owed based on taxable income.",
    "Net Income": "The final profit after all expenses, including taxes, have been deducted from total revenue.",

    // Balance Sheet
    "Cash and Cash Equivalents": "Highly liquid assets, including cash and short-term investments, readily available for use.",
    "Short Term Investments": "Investments that are expected to be converted into cash within a year.",
    "Cash and Short Term Investments": "Total liquid assets comprising cash and short-term investments.",
    "Net Receivables": "Money owed to the company by its customers minus allowances for doubtful accounts.",
    "Inventory": "The value of goods available for sale or in production.",
    "Other Current Assets": "Short-term assets that are not categorized elsewhere, such as prepaid expenses.",
    "Total Current Assets": "The sum of all assets expected to be converted to cash within a year.",
    "Property Plant Equipment Net": "Long-term tangible assets used in operations, net of depreciation.",
    "Goodwill": "The value of intangible assets acquired through business acquisitions.",
    "Intangible Assets": "Non-physical assets such as patents, trademarks, and copyrights.",
    "Goodwill And Intangible Assets": "Combined value of goodwill and other intangible assets.",
    "Long Term Investments": "Investments held for more than a year, such as stocks or bonds.",
    "Tax Assets": "Amounts recoverable from tax authorities due to overpayment or loss carryforwards.",
    "Other Non Current Assets": "Long-term assets not classified elsewhere, such as deferred charges.",
    "Total Non Current Assets": "The sum of all assets not expected to be converted to cash within a year.",
    "Other Assets": "Miscellaneous assets that don't fit into the current or non-current categories.",
    "Total Assets": "The total value of all current and non-current assets.",
    "Account Payables": "Amounts owed to suppliers for goods or services received.",
    "Short Term Debt": "Debt obligations due within a year.",
    "Tax Payables": "Taxes owed to tax authorities that are due within a year.",
    "Deferred Revenue": "Revenue received but not yet earned, recognized as a liability.",
    "Other Current Liabilities": "Short-term liabilities not classified elsewhere, such as accrued expenses.",
    "Total Current Liabilities": "The sum of all liabilities due within a year.",
    "Long Term Debt": "Debt obligations not due within the next year.",
    "Deferred Revenue Non Current": "Revenue received for services or goods to be provided beyond one year.",
    "Deferred Tax Liabilities Non Current": "Taxes owed in the future due to temporary differences in accounting.",
    "Other Non Current Liabilities": "Long-term liabilities not categorized elsewhere.",
    "Total Non Current Liabilities": "The sum of all liabilities not due within the next year.",
    "Other Liabilities": "Miscellaneous liabilities that don't fit into the current or non-current categories.",
    "Capital Lease Obligations": "Liabilities arising from leases treated as asset purchases.",
    "Total Liabilities": "The sum of all current and non-current liabilities.",
    "Preferred Stock": "Equity securities with preferential rights over common stock.",
    "Common Stock": "Equity securities representing ownership in the company.",
    "Retained Earnings": "Cumulative profits or losses retained in the company after dividends.",
    "Accumulated Other Comprehensive Income Loss": "Cumulative unrealized gains or losses from certain transactions, such as foreign currency adjustments.",
    "Other Equity": "Additional equity items not categorized as common or preferred stock.",
    "Total Equity": "The total value of shareholders' ownership in the company.",
    "Total Stockholder's Equity": "The total value of shareholders' ownership in the company.",
    "Total Liabilities and Stockholder's Equity": "The combined value of liabilities and equity, equal to total assets.",

    // Cashflow Statement
    "Depreciation And Amortization": "Non-cash expenses related to the reduction in value of tangible and intangible assets over time.",
    "Deferred Income Tax": "Taxes that are due in future periods due to temporary differences in accounting methods.",
    "Stock Based Compensation": "Expenses related to issuing stock options or equity to employees as part of their compensation.",
    "Change In Working Capital": "The net change in current assets and liabilities, reflecting operational liquidity.",
    "Accounts Receivables": "Cash impact of changes in money owed by customers for goods or services sold on credit.",
    "Inventory": "Cash impact of changes in the value of goods available for sale or in production.",
    "Accounts Payables": "Cash impact of changes in money owed to suppliers for goods or services received.",
    "Other Working Capital": "Changes in other current assets and liabilities not included in specific categories.",
    "Other Non Cash Items": "Adjustments for non-cash gains or losses not captured elsewhere.",
    "Net Cash Provided By Operating Activities": "Cash generated or used by core business operations.",
    "Investments In Property Plant And Equipment": "Cash used to acquire or improve long-term assets like buildings and machinery.",
    "Acquisitions Net": "Cash impact of acquiring other businesses, net of cash acquired.",
    "Purchases Of Investments": "Cash used to buy securities or other long-term financial instruments.",
    "Sales Maturities Of Investments": "Cash received from selling or maturing investments.",
    "Other Investing Activities": "Cash impact of other investment-related activities not categorized elsewhere.",
    "Net Cash Used For Investing Activities": "Net cash spent or received from investment activities.",
    "Debt Repayment": "Cash used to pay off existing debt obligations.",
    "Common Stock Issued": "Cash raised by issuing new shares of common stock.",
    "Common Stock Repurchased": "Cash used to buy back shares of common stock from shareholders.",
    "Dividends Paid": "Cash distributed to shareholders as a return on their investment.",
    "Other Financing Activities": "Cash impact of financing activities not categorized elsewhere.",
    "Net Cash Used Provided By Financing Activities": "Net cash generated or used from financing activities.",
    "Effect Of Forex Changes On Cash": "Impact of currency exchange rate changes on cash balances held in foreign currencies.",
    "Cash At Beginning Of Period": "The cash balance at the start of the reporting period.",
    "Cash At End Of Period": "The cash balance at the end of the reporting period.",
    "Net Change In Cash": "The overall change in cash balances during the reporting period.",
    "Operating Cash Flow": "Cash generated or used by regular business operations.",
    "Capital Expenditure": "Cash spent on acquiring or maintaining fixed assets.",
    "Free Cash Flow": "The cash available after accounting for capital expenditures, representing funds available for growth or return to shareholders.",

    "Current Ratio": "Measures a company's ability to pay short-term obligations with its current assets. Formula: Current Assets / Current Liabilities.",
    "Quick Ratio": "Measures a company's ability to meet short-term obligations with its most liquid assets. Formula: (Current Assets - Inventory) / Current Liabilities.",
    "Cash Ratio": "Measures a company's ability to pay short-term obligations using only cash and cash equivalents. Formula: Cash and Cash Equivalents / Current Liabilities.",
    "Days Of Sales Outstanding": "Represents the average number of days it takes a company to collect payment after a sale. Formula: (Accounts Receivable / Revenue) * 365.",
    "Days Of Inventory Outstanding": "Represents the average number of days it takes to sell the entire inventory. Formula: (Inventory / Cost of Goods Sold) * 365.",
    "Days Of Payables Outstanding": "Represents the average number of days it takes a company to pay its suppliers. Formula: (Accounts Payable / Cost of Goods Sold) * 365.",
    "Operating Cycle": "Measures the time it takes to purchase inventory, sell it, and collect cash. Formula: Days of Inventory Outstanding + Days of Sales Outstanding.",
    "Cash Conversion Cycle": "Represents the time it takes to convert investments in inventory and other resources into cash flow from sales. Formula: Operating Cycle - Days of Payables Outstanding.",
  
    // PROFITABILITY RATIOS
    "Gross Profit Margin": "Measures the percentage of revenue that exceeds the cost of goods sold. Formula: Gross Profit / Revenue.",
    "Operating Profit Margin": "Measures how efficiently a company generates profit from its operations. Formula: Operating Income / Revenue.",
    "Pretax Profit Margin": "Measures profitability before tax expenses. Formula: Pretax Income / Revenue.",
    "Net Profit Margin": "Represents the percentage of revenue that remains as profit after all expenses. Formula: Net Income / Revenue.",
    "Effective Tax Rate": "Represents the average rate at which a company's pre-tax profits are taxed. Formula: Tax Expense / Pretax Income.",
  
    // EFFICIENCY RATIOS
    "Return On Assets": "Measures a company's ability to generate profit from its assets. Formula: Net Income / Total Assets.",
    "Return On Equity": "Measures a company's ability to generate profit from shareholders' equity. Formula: Net Income / Shareholders' Equity.",
    "Return On Capital Employed": "Measures the efficiency with which a company uses its capital to generate profit. Formula: EBIT / Capital Employed.",
  
    // SOLVENCY RATIOS
    "Debt Ratio": "Measures the proportion of a company's assets financed by debt. Formula: Total Debt / Total Assets.",
    "Debt Equity Ratio": "Measures a company's financial leverage. Formula: Total Debt / Shareholders' Equity.",
    "Long Term Debt To Capitalization": "Represents the proportion of long-term debt in a company's capital structure. Formula: Long-Term Debt / (Long-Term Debt + Shareholders' Equity).",
    "Total Debt To Capitalization": "Measures the proportion of total debt in the capital structure. Formula: Total Debt / (Total Debt + Shareholders' Equity).",
    "Interest Coverage": "Measures a company's ability to pay interest on its debt. Formula: EBIT / Interest Expense.",
    "Cash Flow To Debt Ratio": "Measures the ability to pay debt using cash flow. Formula: Operating Cash Flow / Total Debt.",
  
    // ACTIVITY RATIOS
    "Receivables Turnover": "Measures how efficiently a company collects receivables. Formula: Revenue / Average Accounts Receivable.",
    "Payables Turnover": "Measures how quickly a company pays its suppliers. Formula: Cost of Goods Sold / Average Accounts Payable.",
    "Inventory Turnover": "Measures how efficiently inventory is managed. Formula: Cost of Goods Sold / Average Inventory.",
    "Fixed Asset Turnover": "Measures how effectively a company uses fixed assets to generate sales. Formula: Revenue / Net Fixed Assets.",
    "Asset Turnover": "Measures how efficiently a company uses its assets to generate revenue. Formula: Revenue / Total Assets.",
  
    // CASH FLOW RATIOS
    "Operating Cash Flow Per Share": "Represents the operating cash flow on a per-share basis. Formula: Operating Cash Flow / Shares Outstanding.",
    "Free Cash Flow Per Share": "Represents free cash flow on a per-share basis. Formula: Free Cash Flow / Shares Outstanding.",
    "Cash Per Share": "Measures the cash available per share. Formula: Cash and Cash Equivalents / Shares Outstanding.",
    "Payout Ratio": "Represents the proportion of earnings paid out as dividends. Formula: Dividends / Net Income.",
    "Operating Cash Flow Sales Ratio": "Measures the relationship between cash flow from operations and revenue. Formula: Operating Cash Flow / Revenue.",
    "Free Cash Flow Operating Cash Flow Ratio": "Measures the proportion of free cash flow to operating cash flow. Formula: Free Cash Flow / Operating Cash Flow.",
    "Dividend Payout Ratio": "Represents the proportion of earnings paid out as dividends. Formula: Dividends / Earnings.",
  
    // MULTIPLES
    "Price Book Value Ratio": "Measures the market value of a stock relative to its book value. Formula: Market Price per Share / Book Value per Share.",
    "Price To Book Ratio": "Represents the market value of equity compared to book value. Formula: Market Capitalization / Shareholders' Equity.",
    "Price To Sales Ratio": "Measures the stock price relative to revenue. Formula: Market Price per Share / Revenue per Share.",
    "Price Earnings Ratio": "Represents the price of a stock relative to its earnings. Formula: Market Price per Share / Earnings per Share.",
    "Price To Free Cash Flows Ratio": "Represents the stock price relative to free cash flow. Formula: Market Price per Share / Free Cash Flow per Share.",
    "Price To Operating Cash Flows Ratio": "Represents the stock price relative to operating cash flow. Formula: Market Price per Share / Operating Cash Flow per Share.",
    "Price Cash Flow Ratio": "Measures the price of a stock relative to cash flow. Formula: Market Price per Share / Cash Flow per Share.",
    "Price Earnings To Growth Ratio": "Measures the relationship between P/E ratio and earnings growth. Formula: Price Earnings Ratio / Earnings Growth Rate.",
    "Price Sales Ratio": "Represents the market value of equity relative to sales. Formula: Market Capitalization / Revenue.",
    "Dividend Yield": "Represents the annual dividend payout relative to stock price. Formula: Dividends per Share / Market Price per Share.",
    "Enterprise Value Multiple": "Measures the value of a company relative to a financial metric (e.g., EBITDA). Formula: Enterprise Value / EBITDA.",
    "Price Fair Value": "Represents the relationship between the stock's market price and its fair value. Formula: Market Price / Fair Value."


  };

  export default tooltipFinancialDefinitions
  