import React, { useState, useEffect } from "react";
import CustomModal2 from "../CustomModal2";
import features from "../../../catalogs/features";
import FigrIconWrapper2 from "../../ui/small/FigrIconWrapper2";
import deferExecution from "../../../functions/deferExecution";

const SecurityFunctionModal = ({ functionMenuSymbol, show, setShow }) => {
    const [focusedIndex, setFocusedIndex] = useState(0);
    const filteredFeatures = features.filter((feature) => feature.securityFunction);

    useEffect(() => {
        if (show) {
            const handleKeyDown = (event) => {
                if (event.key === "ArrowUp") {
                    event.preventDefault();
                    setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredFeatures.length - 1));
                } else if (event.key === "ArrowDown") {
                    event.preventDefault();
                    setFocusedIndex((prevIndex) => (prevIndex < filteredFeatures.length - 1 ? prevIndex + 1 : 0));
                } else if (event.key === "Enter") {
                    event.preventDefault();
                    if (filteredFeatures[focusedIndex]) {
                        deferExecution(() => filteredFeatures[focusedIndex].handler(functionMenuSymbol.symbol))
                        setShow(false);
                    }
                } else if (event.key === "Escape") {
                    event.preventDefault();
                    setShow(false);
                }
            };
            window.addEventListener("keydown", handleKeyDown);
            return () => window.removeEventListener("keydown", handleKeyDown);
        }
    }, [show, focusedIndex, functionMenuSymbol.symbol, setShow, filteredFeatures]);
    
    return (
        <CustomModal2
            show={show}
            title={`${functionMenuSymbol.symbol} - ${functionMenuSymbol.name}`}
            body={
                <>
                    <div style={{ width: "800px" }}>
                        <div className="text-primary fst-italic">Select a Security Function...</div>
                        <div
                            className="w-100 my-2 p-1 d-flex justify-content-between"
                            style={{ border: "0.5px solid var(--secondary)" }}
                        >
                            <span className="d-flex align-items-center">
                                <div className="m-1">
                                    <FigrIconWrapper2 title={"Up"}>
                                        <svg
                                            style={{ height: "12px", width: "12px" }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 -960 960 960"
                                        >
                                            <path d="M480-528 296-344l-56-56 240-240 240 240-56 56z" />
                                        </svg>
                                    </FigrIconWrapper2>
                                </div>
                                <div className="m-1">
                                    <FigrIconWrapper2 title={"Down"}>
                                        <svg
                                            style={{ height: "12px", width: "12px" }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 -960 960 960"
                                        >
                                            <path d="M480-344 240-584l56-56 184 184 184-184 56 56z" />
                                        </svg>
                                    </FigrIconWrapper2>
                                </div>
                                <span className="m-2">to navigate</span>
                            </span>
                            <span className="d-flex align-items-center">
                                <FigrIconWrapper2 title={"Enter/Return"}>
                                    <svg
                                        style={{ height: "12px", width: "12px" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 -960 960 960"
                                    >
                                        <path d="M360-240 120-480l240-240 56 56-144 144h488v-160h80v240H272l144 144z" />
                                    </svg>
                                </FigrIconWrapper2>
                                <span className="m-2">to select</span>
                            </span>
                            <span className="d-flex align-items-center">
                                <FigrIconWrapper2 title={"Enter/Return"}>
                                    ESC
                                </FigrIconWrapper2>
                                <span className="m-2">to close</span>
                            </span>
                        </div>
                        <div className="mt-3 container-fluid p-0" style={{ minWidth: "500px" }}>
                            {filteredFeatures.map((feature, index) => (
                                <div
                                    key={index}
                                    className={`row p-1 hover-item ${
                                        index === focusedIndex ? "suggestion-active" : "suggestion-inactive"
                                    }`}
                                    onClick={() => {
                                        deferExecution(() => feature.handler(functionMenuSymbol.symbol));
                                        setShow(false);
                                    }}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className="pe-0" style={{ flex: "0 0 30px" }}>{`[${index}]`}</div>
                                    <div className="col-1 text-primary fw-bold">{feature.code} </div>
                                    <div className="col-3">{feature.featureName}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
            onClose={() => setShow(false)}
        />
    );
};

export default SecurityFunctionModal;