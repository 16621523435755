import React, { useState, useEffect, useRef, forwardRef} from "react";
import WindowPieces from "../../ui/small/WindowPieces";
import debounce from "../../../functions/helpers/debounce";
import SuggestionListForSecurities from "../suggestionscontainers/SuggestionListForSecurities";
import constants from "../../../constants";
import axios from "axios";

const AutosuggestSecurities = forwardRef(({
    id,
    value,
    handleSetValue,
    handleSubmitCustom,
    defaultValue,

}, ref) => {
    
    const DEBOUNCE_DELAY = 300;
    const MAX_RESULTS = 5;

    let timeoutId = null;
    const [activeInputElement, setActiveInputElement] = useState(null)
    const [activeSuggestionType, setActiveSuggestionType] = useState(null)

    const [securitySuggestions, setSecuritySuggestions] = useState([])
    const [showSuggestionsMenu, setShowSuggestionsMenu] = useState(false)
    const [highlightedIndex, setHighlightedIndex] = useState(0)
    const [inputValue, setInputValue] = useState(defaultValue); // Control the input value

    const lastRequestId = useRef(0);

    function highlightSuggestion(items, index) {
        const INACTIVE_CLASS = 'suggestion-inactive'
        const ACTIVE_CLASS = 'suggestion-active'

        items.forEach(item => {
            item.classList.remove(ACTIVE_CLASS)
            item.classList.add(INACTIVE_CLASS)
        })
        
        // Highlight target item
        const targetItem = items[index]
        if (targetItem) {
            targetItem.classList.remove(INACTIVE_CLASS)
            targetItem.classList.add(ACTIVE_CLASS)
            
            const suggestionType = targetItem.getAttribute('data-suggestion-type')
            setActiveSuggestionType(suggestionType)
        }
    }

    function updateInput(items, index) {
        const targetItem = items[index];
        const suggestionValue = targetItem.getAttribute('data-suggestion-value');
        
        setInputValue(suggestionValue);
        handleSetValue(suggestionValue)
    }
    
    async function displaySuggestions({query, inputElement}){
        const currentRequestId = ++lastRequestId.current;
        const securityQuery = query.trim().split(' ')[0]
        const response = await axios.get(constants.BACKEND_URL + '/query-symbols/', {
            params: {
                symbol: securityQuery, 
                maxResults: MAX_RESULTS  
            }
        });

        if (currentRequestId === lastRequestId.current) {
            let securities = await response.data.data;
            securities=securities.map(obj=>{
                return {
                    'symbol': obj.symbol,
                    'name': obj.name,
                    'exchangeShortName': obj.exchangeShortName,
                    'suggestionType': 'security'
                }
            })
            setSecuritySuggestions([])
            setSecuritySuggestions((prevSuggestionList)=>{
                const newSuggestionsList=securities.slice(0,MAX_RESULTS)
                setHighlightedIndex(0)
                const suggestionElements = Array.from(document.querySelectorAll('.suggestion-item'));
                if (newSuggestionsList.length > 0) {
                    highlightSuggestion(suggestionElements, 0)
                }
                return newSuggestionsList
            })
        }
        
    }

    const handleInput = (e) => {
        e.stopPropagation()
        const query = e.target.value;
        if (query.length > 0) {
            debounce(()=> displaySuggestions({ query, inputElement: e.target }), DEBOUNCE_DELAY)();
           
        } else {
            setSecuritySuggestions([])
            setHighlightedIndex(0)

        }

    }

    const handleKeyDown = (e) => {
        if (e.key == "Enter") {
            e.stopPropagation()
            handleSubmitCustom(inputValue)
            handleBlur(e)
        }

        else {
            e.stopPropagation()
            if (e.keyCode==40 && securitySuggestions.length > 0) {
                e.preventDefault()
                const suggestionElements = Array.from(document.querySelectorAll('.suggestion-item'))
                setHighlightedIndex((prevIndex)=>{
                    const newIndex = (prevIndex+1) % suggestionElements.length
                    highlightSuggestion(suggestionElements, newIndex)
                    updateInput(suggestionElements, newIndex)
                    return newIndex
                })
              
            }

            else if (e.keyCode === 38 && securitySuggestions.length > 0) {
                e.preventDefault(); 
                const suggestionElements = Array.from(document.querySelectorAll('.suggestion-item'));
                setHighlightedIndex((prevIndex)=>{
                    const newIndex = (prevIndex-1+suggestionElements.length) % suggestionElements.length
                    highlightSuggestion(suggestionElements, newIndex)
                    updateInput(suggestionElements, newIndex)
                    return newIndex
                })
               
            }

        }
    }

    const handleFocus = (e) => {
        setSecuritySuggestions([])
        setActiveInputElement(e.target)
        setHighlightedIndex(0)
    }

    const handleBlur = (e)=> {
        setSecuritySuggestions([])
        setHighlightedIndex(0)
        setActiveInputElement(null)    
    }


    return(
        <div className="flex-grow-4 m-0 w-100">
            <div style={{borderColor: "var(--secondary)"}}  className="h-100 border d-flex flex-row text-primary">
                <div style={{paddingLeft: '4px', lineHeight: '0em', fontSize: '1.5em', marginRight: "4px", display: 'flex', alignItems: "center"}}>&#36;</div>
                <input 
                    ref={ref}
                    id={id || undefined}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value)
                        handleSetValue(e.target.value)
                    }}
                    onInput={(e) => handleInput(e)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    // defaultValue={defaultValue || undefined}
                    style={{
                        border: 'none',
                        textTransform: 'uppercase',
                        borderRadius: WindowPieces.borderRadius, 
                        padding: '0px',
                        width: '100%',
                        height: '24px',
                        color: "var(--text)",
                        backgroundColor: "var(--theme)"
                        // minWidth: '500px'
                    }}
                    text="text"
                    className="
                        h-100                       
                    "
                    autoComplete="off"
                />
            </div>
            {
                
                (inputValue.length > 0 && activeInputElement) && (
                    <SuggestionListForSecurities securitySuggestions={securitySuggestions}/>
                )
            }
        </div>
    )


})

export default AutosuggestSecurities