import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import getRootColorString from "../../../functions/helpers/getRootColorString";

const AnalystPriceTargets = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'page': 0,
    };
    const ENDPOINT = '/analyst-price-targets/';
 

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({ priceTargets: [], summary: [], consensus: [] });
    const [initialRender, setInitialRender] = useState(true);

    const [currentPage, setCurrentPage] = useState(1); // Current page state

    const autoSuggestRef = useRef(null);

    

    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data
                    const status = responseData.status
                    const data = responseData.data

                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                        setCurrentPage(1)
                    }

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);

    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }
   

    const currentResults = (data.priceTargets || []).slice(
        (currentPage - 1) * constants.TABLE_MAX_ROWS,
        currentPage * constants.TABLE_MAX_ROWS
    );

    // Calculate total pages
    const totalPages = Math.ceil(data.priceTargets.length / constants.TABLE_MAX_ROWS);

    // Change page
    const changePage = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePageInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            changePage(value);
        }
    };

    function formatDateToISO(dateString) {
        const date = new Date(dateString);
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it's zero-based
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }
    
    const targetData = [
        { label: "Target High", value: data.consensus.targetHigh },
        { label: "Target Median", value: data.consensus.targetMedian },
        { label: "Target Consensus", value: data.consensus.targetConsensus },
        { label: "Target Low", value: data.consensus.targetLow },
    ];
    const sortedTargetData = targetData.sort((a, b) => b.value - a.value);

    const PriceTargetContainer = ({label, price}) => {
        let color;
        let bgColor;

        if (price === data.currentPrice) {
            color = getRootColorString("--text")
            bgColor = `${color}25`
        } else {
            color = price > data.currentPrice? getRootColorString("--positive"): getRootColorString("--negative")
            bgColor = `${color}25` 
        }
        return (
            <div style={{color: color, backgroundColor: bgColor}} className="w-100 text-center p-2 m-2">{label}: <span className="fw-bold">{`$${price.toFixed(2)}`} ({price !== data.currrentPrice? `${((price/data.currentPrice-1)*100).toFixed(2)}%`: ''})</span></div>
        )
    }

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                        id={'symbol'}
                        ref={autoSuggestRef}
                        defaultValue={params.symbol}
                        handleSubmitCustom={requestData}
                    />
                <WindowPieces.ConfigContainer>
                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                </WindowPieces.ConfigContainer>
            </WindowPieces.OptionsContainer>

            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>
                    

                    <div className="d-flex w-100 flex-row justify-content-between">
                        <div className="d-flex flex-column w-100 m-2 align-items-center justify-content-center">
                            <PriceTargetContainer label={"Current Price"} price={data.currentPrice} />
                        </div>
                        <div className="d-flex flex-column w-100 m-2 align-items-center">
                            {sortedTargetData.map((target, index) => (
                                <PriceTargetContainer key={index} label={target.label} price={target.value} />
                            ))}
                        </div>
                    </div>


                    {data.priceTargets && data.priceTargets.length > 0 ? (
                        <>
                            <WindowPieces.Content>
                                <p style={{borderTop: "1px solid var(--secondary)"}} className='text-primary fw-light px-2 py-1'>Viewing page {currentPage} of {totalPages}</p>
                                <ZenithTable1.Table 
                                    tablehead={
                                        <tr>
                                            <th>Price Change</th>
                                            <th>Published Time</th>
                                            <th>Analyst Name</th>
                                            <th>Analyst Company</th>
                                            <th>Article URL</th>
                                        </tr>
                                    }
                                    tablebody={
                                        <>
                                            {currentResults.map((priceTarget, index) => (
                                                <tr key={index}>
                                                    <td 
                                                        style={{
                                                            color: `${priceTarget.priceTarget > priceTarget.priceWhenPosted ? "var(--positive)" : 
                                                                    priceTarget.priceTarget < priceTarget.priceWhenPosted ? "var(--negative)" : 
                                                                    "var(--neutral)"}`
                                                        }}
                                                    >
                                                        ${priceTarget.priceWhenPosted.toFixed(2)} &rarr; ${priceTarget.priceTarget.toFixed(2)}
                                                    </td>
                                                    <td>{formatDateToISO(priceTarget.publishedDate)}</td>
                                                    <td>{priceTarget.analystName}</td>
                                                    <td>{priceTarget.analystCompany}</td>
                                                    <td><a style={{ color: "var(--primary)" }} target="_blank" rel="noopener noreferrer" href={priceTarget.newsURL}>URL</a></td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                />
                            </WindowPieces.Content>
                            <WindowPieces.PageViewer
                                currentPage={currentPage}
                                totalPages={totalPages}
                                changePageFunc={changePage}
                                handlePageInput={handlePageInputChange}
                            />
                        </>
                    ) : (
                        <div>No data available</div>
                    )}     
                </>
            )}
        </>
    );
};

export default AnalystPriceTargets;