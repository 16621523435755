import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';
import ChartSettingsComponent from '../../ui/small/ChartSettingsComponent';
import CustomModal1 from '../../modals/CustomModal1';
import getRootColorString from '../../../functions/helpers/getRootColorString';
import SettingsConfigButton from '../../ui/small/buttons/SettingsConfigButton';

const RegressionPlot = ({windowID, scatter_data, regressionline_data}) => {
    Chart.defaults.font.family =  'Inter'

    const [showSettings, setShowSettings] = useState(false)

    const chartContainerRef = useRef(null)
    const colors = constants.getColorPallete()

    const color1=getRootColorString("--primary")
    const color2=getRootColorString("--positive")
    const textColor = getRootColorString("--text")

    useEffect(() => {
        // if (!data) return;

        const chart = new Chart(chartContainerRef.current, {
            type: 'scatter',
            data: {
                datasets: [
                    {
                        label: "Observations",
                        data: scatter_data, // Scatter points data
                        borderColor: color2,
                        backgroundColor: color2,
                        showLine: false, // Ensure scatter does not show as a line,
                    },
                    {
                        label: "Regression Line",
                        data: regressionline_data,
                        borderColor: color1,
                        backgroundColor: "transparent",
                        type: 'line',
                        showLine: true,
                        pointRadius: 0,     // Add this to hide points
                        borderWidth: 2,     // Add this to control line thickness
                        tension: 0         // Optional: ensures a straight line with no curve
                    }
                ]
            },
            options: {
                animation:false,
                scales: {
                    x: {
                        ticks: {
                            color: textColor,
                        },
                        grid: {
                            display: false,
                            color: '#444',
                        }
                    },
                    y: {
                        ticks: {
                            color: textColor
                        }
                    }
                },
                plugins: {
                    htmlLegend: {
                        containerID: windowID+' '+'legend-container',
                      },
                    legend: {
                        display: false,
                        },
                    tooltip: {
                        ...constants.CHARTJS_TOOLTIP_STYLE
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            plugins: [constants.CHARTJS_HTML_LEGEND_PLUGIN],
        });

        return () => {
            chart.destroy();
        };
    }, []);

    return (
        <>
            <div id={windowID+' '+'legend-container'}></div>
            <div className='position-relative'>
                <canvas ref={chartContainerRef} style={{ width: '200px', height: '300px'}}></canvas>
                {/* <div
                style={{
                    position: 'absolute',
                    right: '5px',
                    top: '15px',
                    zIndex: '3',
                    fontSize: '14px',
                    lineHeight: "18px",
                    fontWeight: '300'
                }}
                >
                    <SettingsConfigButton label={"SETTINGS"} onClick={()=>setShowSettings(true)}/>
                </div> */}
            </div>
            <CustomModal1 
                show={showSettings}
                body={
                    <h3>Chart Settings</h3>
                }
                onClose={()=>setShowSettings(false)}
                onConfirm={()=>setShowSettings(false)}
            
            />
        </>    
    );
};

export default RegressionPlot;