import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constants from '../../../constants';
import { showAlert } from '../../../functions/showAlert';
import watchlistData from '../../../functions/memoryManagement/watchlistData';
import CustomModal1 from '../../modals/CustomModal1';
import AutosuggestSecurities from '../../autosuggest/searchbars/AutosuggestSecurities_v4';
import ZenithTable1 from "../../ui/small/tables/ZenithTable1"
import ToggleButton1 from '../../ui/small/buttons/ToggleButton1';
import AddButton from '../../ui/small/buttons/AddButton';
import getRootColorString from '../../../functions/helpers/getRootColorString';
import WindowPieces from '../../ui/small/WindowPieces';
import handleTearSheet from "../../../functions/requestFuncs/handling/handleTearSheet"
import deferExecution from '../../../functions/deferExecution';
import FigrTooltipMenu from '../../ui/small/FigrTooltipMenu';
import DropdownWrapper from '../../ui/small/DropdownWrapper';

// TUESDAY 9:30AM 
// TO FIX IN THIS COMPONENT
// Might need to the forEach in ws subscribe block - we might need to remove the forEach and just subscribe to the tickers as a list
// else if (messageData.event === 'data') <= change this block to whatever the websocket data comes as 
// get data from the fmp api every 1m if the event is heartbeat.

const Watchlist = ({ initWatchlistObject }) => {
    const [watchlistObject, setWatchlistObject] = useState(initWatchlistObject);
    const [selectedWatchlist, setSelectedWatchlist] = useState(Object.keys(initWatchlistObject)[0]);
    const [stockData, setStockData] = useState([]);
    const [previousClosePrices, setPreviousClosePrices] = useState({})
    const [hoveredTicker, setHoveredTicker] = useState(null);

    const [previousPrices, setPreviousPrices] = useState({});
    const [updateKey, setUpdateKey] = useState(0);



    // Modal related states
    const [loading, setLoading] = useState(false);
    const [showAddTickerModal, setShowAddTickerModal] = useState(false);
    const [newTicker, setNewTicker] = useState("");
    const [showCreateWatchlistModal, setShowCreateWatchlistModal] = useState(false);
    const [newWatchlistName, setNewWatchlistName] = useState("");
    const [showDeleteWatchlistModal, setShowDeleteWatchlistModal] = useState(false);
    const [renameWatchlistName, setRenameWatchlistName] = useState("");
    const [showRenameWatchlistModal, setShowRenameWatchlistModal] = useState(false);

    const pollingInterval = useRef(null)
    const autoSuggestRef = useRef(null);

    const positiveColor = getRootColorString("--positive")
    const negativeColor = getRootColorString("--negative")
    

    useEffect(() => {

        const fetchData =() => {
            setLoading(true)
            const tickers = watchlistObject[selectedWatchlist];
            constants.api.post(constants.BACKEND_URL+'/watchlist-update/', { 'symbols': tickers })
                .then(response => {
                    const responseData = response.data
                    const data = responseData.data
                    
                    const currentPrices = {};
                    stockData.forEach(stock => {
                        currentPrices[stock.ticker] = stock.lastPrice;
                    });
                    setPreviousPrices(currentPrices);
                    
                    setStockData(data);
                    setUpdateKey(prev => prev + 1); 

                    const newPreviousClosePrices = {};
                    data.forEach(item => {
                        newPreviousClosePrices[item.ticker] = item.previousClose;
                    });
                    setPreviousClosePrices(newPreviousClosePrices);

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                
                })
                .finally(() => {
                    setLoading(false)
                });
            
        }

        fetchData();

        // Set up polling interval
        pollingInterval.current = setInterval(fetchData, 60000); // Poll every 60 seconds

        // Clean up on unmount
        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
            }
        };
    }, [selectedWatchlist, watchlistObject]);

    // Handle adding a ticker to the selected watchlist
    const handleAddTicker = () => {
        setShowAddTickerModal(true);
    };

    const handleConfirmAddTicker = () => {
        if (watchlistObject[selectedWatchlist]) {
            if (newTicker && !watchlistObject[selectedWatchlist].includes(newTicker.toUpperCase())) {
                setWatchlistObject(prev => {
                    const updatedWatchlist = {
                        ...prev,
                        [selectedWatchlist]: [...prev[selectedWatchlist], newTicker.toUpperCase()]
                    };
                    watchlistData.saveWatchlist(updatedWatchlist);
                
                    return updatedWatchlist;
                });
                setNewTicker(""); // Reset input field after adding
            } else if (watchlistObject[selectedWatchlist].includes(newTicker)) {
                showAlert('error', `${newTicker.toUpperCase()} is already in the '${selectedWatchlist}' watchlist.`);
            }
            setShowAddTickerModal(false);
        } else {
            showAlert('error', 'Please create a watchlist to add a ticker!');
        }
    };

    // Handle creating a new watchlist using modal
    const handleCreateWatchlist = () => {
        setShowCreateWatchlistModal(true);
    };

    const handleConfirmCreateWatchlist = () => {
        if (newWatchlistName && !watchlistObject[newWatchlistName]) {
            setWatchlistObject(prev => {
                const updatedWatchlist = {
                    ...prev,
                    [newWatchlistName]: []
                };
                watchlistData.saveWatchlist(updatedWatchlist);
                return updatedWatchlist;
            });
            setSelectedWatchlist(newWatchlistName);
            setNewWatchlistName(""); // Reset input field after creating
        } else if (watchlistObject[newWatchlistName]) {
            showAlert('error', "Watchlist with this name already exists.");
        }
        setShowCreateWatchlistModal(false);
    };

    const handleDeleteWatchlist = () => {
        setShowDeleteWatchlistModal(true);
    };

    const handleConfirmDeleteWatchlist = () => {
        setWatchlistObject(prev => {
            if (Object.keys(prev).length <= 1) {
                showAlert("warning", "You cannot delete the last watchlist.");
                return prev; // Do nothing if there's only one watchlist
            }
    
            const updatedWatchlists = { ...prev };
            delete updatedWatchlists[selectedWatchlist];
            watchlistData.saveWatchlist(updatedWatchlists);
            return updatedWatchlists;
        });
    
        if (Object.keys(watchlistObject).length > 1) {
            const newSelection = Object.keys(watchlistObject).filter(w => w !== selectedWatchlist)[0] || "";
            setSelectedWatchlist(newSelection);
        }
    
        setShowDeleteWatchlistModal(false);
    };

    const handleDeleteTicker = (ticker) => {
        setWatchlistObject(prev => {
            const updatedWatchlist = {
                ...prev,
                [selectedWatchlist]: prev[selectedWatchlist].filter(t => t !== ticker)
            };
            watchlistData.saveWatchlist(updatedWatchlist);
            return updatedWatchlist;
        });
    };

    const handleRenameWatchlist = () => {
        setShowRenameWatchlistModal(true);
    };

    const handleConfirmRenameWatchlist = () => {
        if (renameWatchlistName && !watchlistObject[renameWatchlistName]) {
            setWatchlistObject(prev => {
                const updatedWatchlist = { ...prev };
                updatedWatchlist[renameWatchlistName] = updatedWatchlist[selectedWatchlist];
                delete updatedWatchlist[selectedWatchlist];

                watchlistData.saveWatchlist(updatedWatchlist);
                return updatedWatchlist;
            });
            setSelectedWatchlist(renameWatchlistName);
            setRenameWatchlistName(""); // Reset input field after renaming
        } else if (watchlistObject[renameWatchlistName]) {
            showAlert('error', "A watchlist with this name already exists.");
        }
        setShowRenameWatchlistModal(false);
    };

    function formatTimestampToTime(timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time (0 becomes 12)
        const minutesPadded = minutes.toString().padStart(2, '0'); // Ensure two-digit minutes
    
        return `${hours}:${minutesPadded}${ampm}`;
    }

    const getFlashClass = (ticker, currentPrice) => {
        if (!previousPrices[ticker] || !currentPrice) return '';
        
        if (currentPrice > previousPrices[ticker]) {
            return 'flash-row-green';
        } else if (currentPrice < previousPrices[ticker]) {
            return 'flash-row-red';
        } else {
            return 'flash-row-white';
        }
    };

    const invalidSymbol = (
        <td style={{color: "var(--negative)"}} title="Invalid Symbol">#ERROR</td>
    )

    return (
        <div className="p-2">
            <div className='d-flex justify-content-between'>
                <ToggleButton1 
                    showToggle={true}
                    label={selectedWatchlist ? selectedWatchlist : "Select Watchlist"}
                    dropDownMenu={
                        <ul style={{backgroundColor: "var(--theme)"}} className="dropdown-menu dropdown-menu-end rounded-0 p-2">
                        {Object.keys(watchlistObject).map((watchlist, index) => (
                            <DropdownWrapper 
                                key={index}
                                onClick={() => setSelectedWatchlist(watchlist)}
                                label={watchlist}
                            />

                        ))}
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                            <DropdownWrapper 
                                onClick={handleCreateWatchlist}
                                label={"Create New Watchlist"}
                                iconSVG={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                                    </svg>
                                }   
                            />
                        </li>
                        <li>
                            <DropdownWrapper 
                                onClick={handleRenameWatchlist}
                                label={"Rename Watchlist"}
                                iconSVG={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
                                    </svg>
                                }       
                            
                            />
                        </li>
                        <li>
                            <DropdownWrapper 
                                onClick={handleDeleteWatchlist}
                                label={"Delete Watchlist"}
                                iconSVG={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                    </svg>
                                }   
                            />
                        </li>
                    </ul>
                    }
                









                />
                <AddButton onClick={handleAddTicker}>
                    + Add Symbol
                </AddButton>
            </div>
            
            {/* Render Table */}
            <ZenithTable1.Table 
                tablehead={
                    <tr>
                        <th className='text-nowrap'>Ticker</th>
                        <th className='text-nowrap'>Last Price</th>
                        <th className='text-nowrap'>Change</th>
                        <th className='text-nowrap'>Change %</th>
                    </tr>
                }
                tablebody={
                    <>
                        {stockData.map((stock, index) => (
                            // Animate Class: flash-row-update animate
                            <tr key={index} className={getFlashClass(stock.ticker, stock.lastPrice)}> 
                                <td
                                
                                    onMouseEnter={() => setHoveredTicker(stock.ticker)}
                                    onMouseLeave={() => setHoveredTicker(null)}
                                    style={{ position: 'relative', minWidth: '80px'}}
                                    title={stock.name}
                                >
                                    {stock.ticker}

                                    {hoveredTicker === stock.ticker && (
                                        <span>
                                            <span
                                                title="Tear Sheet"
                                                style={{
                                                    position: 'absolute',
                                                    right: '30px',
                                                    color: 'var(--primary)',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => deferExecution(()=>handleTearSheet(stock.ticker))}
                                            >
                                                &#8594;
                                            </span>
                                                <span
                                                title="Delete Symbol"
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    color: 'red',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleDeleteTicker(stock.ticker)}
                                            >
                                                &#10005;
                                            </span>
                                        </span>
                                    )}
                                
                                </td>
                                {stock.lastPrice ? <td title={formatTimestampToTime(stock.timestamp*1000)}>{stock.lastPrice.toFixed(2)}</td>: invalidSymbol}
                                {stock.change? <td title={formatTimestampToTime(stock.timestamp*1000)} style={{ color: stock.change >= 0 ? positiveColor : negativeColor }}>
                                    {stock.change.toFixed(2)}
                                </td>: invalidSymbol}
                                {stock.changePercent? <td title={formatTimestampToTime(stock.timestamp*1000)} style={{ color: stock.changePercent >= 0 ? positiveColor : negativeColor }}>
                                    {stock.changePercent.toFixed(2)}%
                                </td>: invalidSymbol}
                                
                                
                                
                            </tr>
                        ))}
                    </>
                }
            />
        

            {/* Modals for Add Ticker, Create Watchlist, and Delete Watchlist */}
            <CustomModal1
                show={showRenameWatchlistModal}
                body={
                    <>
                        <h3>Rename Watchlist {selectedWatchlist}</h3>
                        <WindowPieces.SquareInput 
                            type={"text"}
                            value={renameWatchlistName}
                            onChange={(e)=>setRenameWatchlistName(e.target.value)}
                            placeholder={"Enter new watchlist name"}
                            fullWidth={true}
                        
                        />
                    </>
                }
                onClose={() => setShowRenameWatchlistModal(false)}
                onConfirm={handleConfirmRenameWatchlist}
            />

            

            <CustomModal1
                show={showAddTickerModal}
                body={
                    <>
                        <h3>Add New Symbol</h3>
                        <div className='position-relative'>
                            <AutosuggestSecurities
                                id={'symbol'}
                                ref={autoSuggestRef}
                                defaultValue={''}
                                handleSetValue={setNewTicker}
                                handleSubmitCustom={()=>{
                                    
                                }}
                            />

                        </div>
                    </>
                   
                }
                onClose={() => setShowAddTickerModal(false)}
                onConfirm={handleConfirmAddTicker}
            />

            <CustomModal1
                show={showCreateWatchlistModal}
                body={
                    <>
                        <h3>Create a new Watchlist</h3>
                        <WindowPieces.SquareInput 
                            text={"text"}
                            value={newWatchlistName}
                            onChange={(e)=>setNewWatchlistName(e.target.value)}
                            placeholder={"Enter watchlist name"}
                            fullWidth={true}
                        />
                    </>
            
                }
                onClose={() => setShowCreateWatchlistModal(false)}
                onConfirm={handleConfirmCreateWatchlist}
            />

            <CustomModal1
                show={showDeleteWatchlistModal}
                body={
                    <>
                        <h3>Confirm Delete Watchlist</h3>
                        <p>Are you sure you want to delete the '{selectedWatchlist}' watchlist?</p>
                    </>
                
                }
                onClose={() => setShowDeleteWatchlistModal(false)}
                onConfirm={handleConfirmDeleteWatchlist}
            />
        </div>
    );
};

export default Watchlist;
