import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import constants from '../../../constants';
import axios from 'axios';
import CustomModal1 from '../../modals/CustomModal1';
import ColorPicker from '../../ui/small/ColorPicker';
import SettingsSection from '../../ui/small/wrappers/settings/SettingsSection';
import SettingLineWrapper from '../../ui/small/wrappers/settings/SettingLineWrapper';
import CheckBox from '../../ui/small/CheckBox';
import SettingsConfigButton from '../../ui/small/buttons/SettingsConfigButton';
import getRootColorString from '../../../functions/helpers/getRootColorString';

function logWithTime(message) {
    const currentTime = new Date().toLocaleString();
    console.log(`[${currentTime}] ${message}`);
}

const CandlestickChart = ({ ohlcData, symbol, params, windowContainer, contentContainerRef }) => {
    // Modal Stuff
    const [showSettings, setShowSettings] = useState(false)

    const [currentSettings, setCurrentSettings] = useState({
        chartBackground: getRootColorString("--theme"),
        textColor: getRootColorString("--text"),
        upCandleColor: getRootColorString("--positive"),
        downCandleColor: getRootColorString("--negative"),
        upCandleBorder: getRootColorString("--positive"),
        downCandleBorder: getRootColorString("--negative"),
        syncUpCandleBorder: true,
        syncDownCandleBorder: true,
        showGridLines: false,
        gridColor: getRootColorString("--secondary"),
        showWatermark: false,
        watermarkColor: getRootColorString("--text")
    })
    const [adjustedSettings, setAdjustedSettings] = useState({
        })
    
    const setAdjustedSetting = (key, value) => {
        setAdjustedSettings((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const setChartBackground = (color) => setAdjustedSetting('chartBackground', color);
    const setTextColor = (color) => setAdjustedSetting('textColor', color);
    const setUpCandleColor = (color) => setAdjustedSetting('upCandleColor', color);
    const setDownCandleColor = (color) => setAdjustedSetting('downCandleColor', color);
    const setUpCandleBorder = (color) => setAdjustedSetting('upCandleBorder', color);
    const setDownCandleBorder = (color) => setAdjustedSetting('downCandleBorder', color);
    const setSyncUpCandleBorder = (bool) => setAdjustedSetting('syncUpCandleBorder', bool);
    const setSyncDownCandleBorder = (bool) => setAdjustedSetting('syncDownCandleBorder', bool);
    const setShowGridLines = (bool) => setAdjustedSetting('showGridLines', bool);
    const setGridColor = (color) => setAdjustedSetting('gridColor', color);
    const setShowWatermark = (bool) => setAdjustedSetting('showWatermark', bool);
    const setWatermarkColor = (color) => setAdjustedSetting('watermarkColor', color);


    const chartContainerRef = useRef();
    const chartRef = useRef(null); // Ref for chart instance
    const seriesRef = useRef(null); // Ref for chart instance
    const pollingInterval = useRef(null);
    let resizeAnimationFrame;

    const [candlestickSeries, setCandlestickSeries] = useState(ohlcData);
    const [currentOHLC, setCurrentOHLC] = useState({
        time: ohlcData[ohlcData.length-1].time,
        open: ohlcData[ohlcData.length-1].open,
        high: ohlcData[ohlcData.length-1].high,
        low: ohlcData[ohlcData.length-1].low,
        close: ohlcData[ohlcData.length-1].close

    })

    const [tooltipValues, setTooltipValues] = useState({time: null, open: null, high: null, low: null, close: null})
   

    const initializeChart = () => {
        const chart = createChart(chartContainerRef.current, {
            rightPriceScale: {
                scaleMargins: {
                    top: 0.4, // leave some space for the legend
                    bottom: 0.15,
                },
            },
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            timeScale: {
                timeVisible: true,
                secondsVisible: false
            },
            layout: {
                background: { color: currentSettings.chartBackground },
                textColor: currentSettings.textColor,
                fontFamily: 'Inter',
            },
            grid: {
                vertLines: { visible: currentSettings.showGridLines, color: currentSettings.gridColor },
                horzLines: { visible: currentSettings.showGridLines, color: currentSettings.gridColor },
            },
            watermark: {
                visible: currentSettings.showWatermark,
                fontSize: 24,
                horzAlign: 'center',
                vertAlign: 'center',
                color: currentSettings.watermarkColor,
                text: symbol.toUpperCase(),
            },
        });

        const series = chart.addCandlestickSeries({
            upColor: currentSettings.upCandleColor,
            downColor: currentSettings.downCandleColor,
            borderVisible: false,
            wickUpColor: currentSettings.syncUpCandleBorder? currentSettings.upCandleColor:currentSettings.upCandleBorder,
            wickDownColor: currentSettings.syncDownCandleBorder?currentSettings.downCandleColor: currentSettings.downCandleBorder,
        });
        seriesRef.current = series
        chartRef.current = chart; // Store chart in the ref

        const getLastBar = series => {
            const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
            return series.dataByIndex(lastIndex);
        };
        const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);
        const setTooltip = (time, open, high, low, close) => {
            setTooltipValues({
                time: time,
                open: formatPrice(open),
                high: formatPrice(high),
                low: formatPrice(low),
                close: formatPrice(close)
            })
        };

        const updateLegend = param => {
            const validCrosshairPoint = !(
                param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
            );
            const bar = validCrosshairPoint ? param.seriesData.get(series) : currentOHLC;

            const time = bar.time;
            const open = bar.open !== undefined ? bar.open : bar.close;
            const high = bar.high !== undefined ? bar.high : bar.close;
            const low = bar.low !== undefined ? bar.low : bar.close;
            const close = bar.close !== undefined ? bar.close : bar.close;
            setTooltip(time, open, high, low, close);
        };
        

        chart.subscribeCrosshairMove(updateLegend);

        // updateLegend(undefined);

        return series;
    };

    const handleResize = () => {
        if (!chartRef.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (entries.length === 0 || entries[0].target !== contentContainerRef.current) return;
            const { width, height } = entries[0].contentRect;

            if (height > 0 && width > 0) { // Only resize if dimensions are valid
                if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
                resizeAnimationFrame = requestAnimationFrame(() => {
                    chartRef.current.resize(width, height - 5);
                });
            }
        });

        resizeObserver.observe(contentContainerRef.current);

        return () => {
            resizeObserver.disconnect();
            if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
        };
    };


    
    useEffect(() => {
        const series = initializeChart();

        series.setData(candlestickSeries);
        chartRef.current.timeScale().fitContent();
        
        const fetchData =() => {
            constants.api.post(constants.BACKEND_URL+'/historical-price/', params)
                .then(response => {
                    const responseData = response.data
    
                    const status = responseData.status
                    const data = responseData.data
                
                    if (status === 'success') {
                        setCandlestickSeries(data); // Update state
                        // if (seriesRef.current) {
                        //     seriesRef.current.setData(data); // Replace the entire series
                        // }
                    }    

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
            });
        }

        pollingInterval.current = setInterval(fetchData, 60000); 

        const cleanupResize = handleResize();
        return () => {
            cleanupResize();
            chartRef.current.remove();
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current); 
            }
    
        };
    }, [currentSettings]);
    
    useEffect(()=>{ 
        if (candlestickSeries && chartRef.current) {
            seriesRef.current.setData(candlestickSeries)
        }
    }, [candlestickSeries])

    return (
        <>
            <div className='h-100 w-100' ref={chartContainerRef}>
                <div className='legend'
                    style={{
                        position: 'absolute',
                        left: '6px',
                        top: '70px',
                        zIndex: '3',
                        fontSize: '14px',
                        lineHeight: "18px",
                        fontWeight: '300'
                    }}
                >
                    <div style={{fontSize: '16px', color: currentSettings.textColor}}>{symbol.toUpperCase()}</div>
                    {/* <div>{formatTimestamp(tooltipValues.time)}</div> */}
                    <div>
                        <span className='text-primary'>O:<strong style={{color: "var(--text)"}}>{tooltipValues.open} </strong></span>
                        <span className='text-primary'>H:<strong style={{color: "var(--text)"}}>{tooltipValues.high} </strong></span>
                        <span className='text-primary'>L:<strong style={{color: "var(--text)"}}>{tooltipValues.low} </strong></span>
                        <span className='text-primary'>C:<strong style={{color: "var(--text)"}}>{tooltipValues.close} </strong></span>
                    
                    </div>
                </div>
                <div 
                style={{
                    position: 'absolute',
                    left: '3px',
                    top: '38px',
                    zIndex: '3',
                    fontSize: '14px',
                    lineHeight: "18px",
                    fontWeight: '300'
                }}>

                    <SettingsConfigButton label={"Chart Settings"} onClick={()=>setShowSettings(true)} />
                </div>
            </div>
            <CustomModal1 
                show={showSettings}
                body={
                    <>
                        <h3>Candlestick Chart Settings</h3>
                        {/* <SettingsSection sectionTitle={"Background & Foreground"}>
                            <SettingLineWrapper label={"Background"}>
                                <ColorPicker value={chartBackground} setColor={setChartBackground}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Foreground"}>
                                <ColorPicker value={textColor} setColor={setTextColor}/>
                            </SettingLineWrapper>
                        </SettingsSection> */}
                        <SettingsSection sectionTitle={"Candlesticks"}>
                            <SettingLineWrapper label={"Up Candle"}>
                                <ColorPicker value={adjustedSettings.upCandleColor || currentSettings.upCandleColor} setColor={setUpCandleColor}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Down Candle"}>
                                <ColorPicker value={adjustedSettings.downCandleColor || currentSettings.downCandleColor} setColor={setDownCandleColor}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Up Candle Border"}>
                                <div className='d-flex'>
                                    <ColorPicker value={adjustedSettings.upCandleBorder || currentSettings.upCandleBorder} setColor={setUpCandleBorder}/>
                                    <div className='ms-2'>
                                        <CheckBox title={"sync with candle"} setBool={setSyncUpCandleBorder} initialValue={adjustedSettings.syncUpCandleBorder || currentSettings.syncUpCandleBorder}/>
                                    </div>
                                </div>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Down Candle Border"}>
                                <div className='d-flex'>
                                    <ColorPicker value={adjustedSettings.downCandleBorder || currentSettings.downCandleBorder} setColor={setDownCandleBorder}/>
                                    <div className='ms-2'>
                                        <CheckBox title={"sync with candle"} setBool={setSyncDownCandleBorder} initialValue={adjustedSettings.syncDownCandleBorder || currentSettings.syncDownCandleBorder}/>
                                    </div>
                                </div>



                       
                            </SettingLineWrapper>
                        </SettingsSection>
                        <SettingsSection sectionTitle={"Grid"}>
                            <SettingLineWrapper label={"Show Grid"}>
                                <CheckBox setBool={setShowGridLines} initialValue={adjustedSettings.showGridLines || currentSettings.showGridLines}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Grid Color"}>
                            <ColorPicker value={adjustedSettings.gridColor || currentSettings.gridColor} setColor={setGridColor}/>
                            </SettingLineWrapper>
                        </SettingsSection>
                        <SettingsSection sectionTitle={"Other Configurations"}>
                            <SettingLineWrapper label={"Show Watermark"}>
                                <CheckBox setBool={setShowWatermark} initialValue={adjustedSettings.showWatermark || currentSettings.showWatermark}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Watermark Color"}>
                                <ColorPicker value={adjustedSettings.watermarkColor || currentSettings.watermarkColor} setColor={setWatermarkColor}/>
                            </SettingLineWrapper>
                        </SettingsSection>
                    </>
                    
                }
                onClose={()=>setShowSettings(false)}
                onConfirm={() => {
                    setCurrentSettings((prev) => ({
                        ...prev,
                        ...adjustedSettings,
                    }));
                    setAdjustedSettings({}); // Reset adjusted settings
                    setShowSettings(false);
                }}
            
            />
        
        </>
        
    )
};

export default CandlestickChart;
