import React, {useState} from "react";
import CustomModal2 from "../CustomModal2";
import SettingsSection from "../../ui/small/wrappers/settings/SettingsSection";
import SettingLineWrapper from "../../ui/small/wrappers/settings/SettingLineWrapper";
import Button2 from "../../ui/small/buttons/Button2";
import Button2Danger from "../../ui/small/buttons/Button2Danger";
import axios from "axios";
import getRootColorString from "../../../functions/helpers/getRootColorString";
import ajaxRequestFuncs from "../../../functions/requestFuncs/ajaxRequestFuncs";
import SitePieces from "../../ui/small/SitePieces";
import constants from "../../../constants";

const AccountModal = ({ user, show, setShow }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState(getRootColorString("--text"))

  const [deleteAccountMessage, setDeleteAccountMessage] = useState("");
  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] = useState(false);



  const handleSubmitPasswordChange = async (e) => {
    e.preventDefault();

    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');

    const formData = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    }

    try {
      const response = await axios.post(constants.BACKEND_URL+"/auth/change-password-logged-in/", formData, {
        headers: {
          'Content-Type': 'application/json',
          'x-csrftoken': csrfToken,
        },
        withCredentials: true
      });
      setMessage(response.data.success);
      setMessageColor(getRootColorString("--positive-c"))
    } catch (error) {
      setMessage(error.response.data.error || "Something went wrong");
      setMessageColor(getRootColorString("--negative-c"))
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');

      const response = await axios.post(constants.BACKEND_URL+"/auth/delete-account/", {}, {
        headers: {
          'Content-Type': 'application/json',
          'x-csrftoken': csrfToken,
        },
        withCredentials: true
      });
      setDeleteAccountMessage(response.data.success);
      // Optionally, redirect the user to the homepage or logout page
      setTimeout(() => {
        window.location.href = constants.REACT_APP_REDIRECT_ENDPOINT; // Redirect to homepage
      }, 2000);
    } catch (error) {
      setDeleteAccountMessage(error.response.data.error || "Something went wrong");
    }
  };

  return (
    <CustomModal2 
      show={show}
      title={"Account"}
      body={
        user ? ( // Check if user is not null
          <div style={{ width: "800px" }}>
            <SettingsSection sectionTitle={"Account Information"}>
              <SettingLineWrapper label={"Username: "}>
                <div>{user.username}</div>
              </SettingLineWrapper>
              <SettingLineWrapper label={"Email: "}>
                <div>{user.email}</div>
              </SettingLineWrapper>
            </SettingsSection>
            <SettingsSection sectionTitle={"Password Management"}>
              {message && <p style={{color: messageColor}}>{message}</p>}
              <form onSubmit={handleSubmitPasswordChange}>
                <SettingLineWrapper label={"Password: "}>
                  <SitePieces.ZenithInput 
                    type="text"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  
                  />
                </SettingLineWrapper>
                <SettingLineWrapper label={"New Password: "}>
                  <SitePieces.ZenithInput 
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </SettingLineWrapper>
                <SettingLineWrapper label={"Confirm New Password: "}>
                  <SitePieces.ZenithInput 
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </SettingLineWrapper>
                <Button2 onClick={handleSubmitPasswordChange}>Change Password</Button2>
              </form>
            </SettingsSection>
            {/* <SettingsSection sectionTitle={"Account Deletion"}>
            {!showDeleteAccountConfirmation ? (
              <Button2Danger onClick={()=>setShowDeleteAccountConfirmation(true)}>Delete My Account</Button2Danger>
            ) : (
              <div>
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                <div className="d-flex">
                  <Button2 onClick={() => setShowDeleteAccountConfirmation(false)}>Cancel</Button2>
                  <div className="ms-2">
                    <Button2Danger onClick={handleDeleteAccount}>Yes, Delete My Account</Button2Danger>
                  </div>
                </div>
              </div>
            )}
            </SettingsSection> */}
          </div>
        ) : (
          <div>You are not signed in!...</div> // Fallback content
        )
              }
      onClose={() => setShow(false)}
    />
  );
  };
  
  export default AccountModal;
  