import getRootColorString from "./functions/helpers/getRootColorString";
import axios from "axios";
import ajaxRequestFuncs from "./functions/requestFuncs/ajaxRequestFuncs";

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');
  
    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.margin = 0;
      listContainer.style.padding = 0;
  
      legendContainer.appendChild(listContainer);
    }
  
    return listContainer;
};
  
const CHARTJS_HTML_LEGEND_PLUGIN = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.padding = '5px';

            li.onclick = () => {
                const {type} = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                // Pie and doughnut charts only have a single dataset and visibility is per item
                chart.toggleDataVisibility(item.index);
                } else {
                chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.border = item.lineWidth > 0? "1px solid " + item.strokeStyle: 'none'
            boxSpan.style.display = 'inline-block';
            boxSpan.style.flexShrink = 0;
            boxSpan.style.height = '20px';
            boxSpan.style.marginRight = '10px';
            boxSpan.style.width = '20px';

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = 0;
            textContainer.style.padding = 0;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
    };

const TABLE_BS_CLASSES="table table-dark text-light table-borderless table-sm table-hover w-100 m-0"
const TABLE2_BS_CLASSES="table table-dark text-light table-borderless table-striped table-hover m-0"
const TABLE_MAX_ROWS = 30

// const COLORS = [
//     '#ffd700',  
//     '#0CB577',  // green
//     '#b50c3c',  // red
//     '#3357ff',  // blue
//     '#ff33a1',  // pink
//     '#33fff7',  // cyan
//     '#ff5733',  // red-orange
//     '#f7ff33',  // yellow
//     '#5733ff',  // purple
//     '#ff3380',  // magenta
//     '#33ffb0'   // teal
// ];
const COLORS = [
    '#3357ff',  // blue
    '#ff33a1',  // pink
    '#33fff7',  // cyan
    '#ff5733',  // red-orange
    '#f7ff33',  // yellow
    '#5733ff',  // purple
    '#ff3380',  // magenta
    '#33ffb0'   // teal
];

const getColorPallete = () => {
    const primaryColor = getRootColorString("--primary")
    const positiveColor = getRootColorString("--positive")
    const negativeColor = getRootColorString("--negative")

    const colorPalleteBeginning = [primaryColor, positiveColor, negativeColor]
    const colorPallete = [...colorPalleteBeginning, ...COLORS] 
    return colorPallete


}



const POSITIVE_COLOR = getRootColorString("--positive")
const NEGATIVE_COLOR = getRootColorString("--negative")

const tooltipColor = getRootColorString("--secondary") || "#212121"

const CHARTJS_TOOLTIP_STYLE = {
  cornerRadius: '0px',
  titleMarginBottom: 1,
  backgroundColor: tooltipColor,
  yAlign: 'bottom',
  displayColors: false,
}

const WINDOWPIECES_VERTICAL_PADDING = '1px'
const WINDOWPIECES_BORDER_RADIUS = '0px'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const REACT_APP_REDIRECT_ENDPOINT = process.env.REACT_APP_REDIRECT_ENDPOINT



const api = axios.create({
    baseURL: BACKEND_URL, 
    headers: {
      'Content-Type': 'application/json',
      'x-csrftoken': ajaxRequestFuncs.getCookie('csrftoken'),
    },
    withCredentials: true
});

const DEFAULT_LAYOUT = [
    {
      "tabID": "tab-1735594328510",
      "layout": [
        {
          "h": 230,
          "n": 0,
          "w": 297,
          "x": 10,
          "y": 6,
          "link": null,
          "windowID": "window-1736279626713",
          "windowType": "window-watchlist"
        },
        {
          "h": 204,
          "n": 1,
          "w": 301,
          "x": 11,
          "y": 241,
          "link": null,
          "windowID": "window-1736279638703",
          "windowType": "window-watchlist"
        },
        {
          "h": 367,
          "n": 2,
          "w": 299,
          "x": 11,
          "y": 454,
          "link": null,
          "windowID": "window-1736279655856",
          "windowType": "window-top-gainers-losers"
        },
        {
          "h": 200,
          "n": 3,
          "w": 300,
          "x": 313,
          "y": 9,
          "link": null,
          "symbol": "xom",
          "windowID": "window-1736279699474",
          "windowType": "window-widget",
          "windowParameters": {
            "symbol": "xom"
          }
        },
        {
          "h": 200,
          "n": 4,
          "w": 300,
          "x": 621,
          "y": 9,
          "link": null,
          "symbol": "nvda",
          "windowID": "window-1736279708968",
          "windowType": "window-widget",
          "windowParameters": {
            "symbol": "nvda"
          }
        },
        {
          "h": 200,
          "n": 5,
          "w": 300,
          "x": 928,
          "y": 10,
          "link": null,
          "symbol": "ba",
          "windowID": "window-1736279717571",
          "windowType": "window-widget",
          "windowParameters": {
            "symbol": "ba"
          }
        },
        {
          "h": 600,
          "n": 6,
          "w": 410,
          "x": 318,
          "y": 212,
          "link": null,
          "symbol": "N/A",
          "windowID": "window-1736279727768",
          "windowType": "window-market-scanner",
          "windowParameters": {
            "period": "1Y",
            "symbols": [
              "XLP",
              "XLE",
              "XLV"
            ]
          }
        },
        {
          "h": 595,
          "n": 7,
          "w": 491,
          "x": 736,
          "y": 213,
          "link": null,
          "symbol": "N/A",
          "windowID": "window-1736279767380",
          "windowType": "window-market-scanner",
          "windowParameters": {
            "period": "1Y",
            "symbols": [
              "UCO",
              "BOIL",
              "GLD"
            ]
          }
        },
        {
          "h": 475,
          "n": 8,
          "w": 673,
          "x": 1237,
          "y": 11,
          "link": null,
          "symbol": "aapl",
          "windowID": "window-1736279844033",
          "windowType": "window-financial-statements",
          "windowParameters": {
            "symbol": "aapl",
            "divider": "millions",
            "periods": 3,
            "periodType": "annual"
          }
        },
        {
          "h": 311,
          "n": 9,
          "w": 400,
          "x": 1234,
          "y": 495,
          "link": null,
          "symbol": "aapl",
          "windowID": "window-1736279856507",
          "windowType": "window-eps",
          "windowParameters": {
            "symbol": "aapl",
            "periods": 5
          }
        },
        {
          "h": 309,
          "n": 10,
          "w": 280,
          "x": 1635,
          "y": 496,
          "link": null,
          "windowID": "window-1736279869628",
          "windowType": "window-top-gainers-losers"
        }
      ],
      "tabName": "Tab 1",
      "tabColor": "#FFD700"
    }
]



export default {
    BACKEND_URL,
    REACT_APP_REDIRECT_ENDPOINT,
    TABLE_BS_CLASSES,
    TABLE2_BS_CLASSES,
    TABLE_MAX_ROWS,
    COLORS,
    getColorPallete,
    POSITIVE_COLOR,
    NEGATIVE_COLOR,
    CHARTJS_TOOLTIP_STYLE,
    CHARTJS_HTML_LEGEND_PLUGIN,
    WINDOWPIECES_VERTICAL_PADDING,
    WINDOWPIECES_BORDER_RADIUS,
    DEFAULT_LAYOUT,
    api
}
