import React, {useRef} from "react";
import TearSheetSectionHeader from "./TearSheetSectionHeader";
import LineWrappers from "./LineWrappers";
import OpenWindowAlternativeButton from "../../ui/small/OpenWindowAlternativeButton";
import OpenWindowAlternativeButtonWide from "../../ui/small/OpenWindowAlternativeButtonWide";
// import TVLWLineChartArea from "../charting/tvlw/TVLW_LineChartArea";
import LineAreaChart from "../../charting/tvlw/LineAreaChartEmbedded";
import handleHistoricalPrice from "../../../functions/requestFuncs/handling/handleHistoricalPrice";
import handleFinancialStatements from "../../../functions/requestFuncs/handling/handleFinancialStatements";
import PerformanceBlock from "./PerformanceBlock";
import Button2 from "../../ui/small/buttons/Button2";
import deferExecution from "../../../functions/deferExecution";

const TearSheetStructure = ({ sheet, priceData }) => {
    const chartContainerRef = useRef()


    const formatLargeDollarFigure = (number) => {
        if (number >= 1000000000) {
            return `${(number / 1000000000).toFixed(2)}B`;
        } else if (number >= 1000000) {
            return `${(number / 1000000).toFixed(2)}M`;
        }
        return number.toFixed(0);
    };

    return (

        <div className="container-fluid m-0 w-100 h-100 p-3">
            <div className="row">
                <h1>{sheet.symbol} - {sheet.companyName}</h1>
                <div><p className="m-0">Sector: {sheet.sector} | Industry: {sheet.industry}</p></div>
            </div>
            <hr></hr>

            <div className="row mt-2">
                <div className="col-md-3">
                    <div className="mb-3">
                        <TearSheetSectionHeader text="SUMMARY" />
                        <LineWrappers.LineWrapperNoFormatting name={"Mkt. Cap. (MM)"}  value={formatLargeDollarFigure(sheet.mktCap)}/>
                        <LineWrappers.LineWrapper name={"Beta"}  value={sheet.beta}/>
                        <LineWrappers.LineWrapper name={"Daily Volume (MM)"}  value={(sheet.volAvg/1000000).toFixed(2)}/>
                    </div>
                    
                    <div className="mb-3">
                        <TearSheetSectionHeader text="KEY RATIOS" />
                        <LineWrappers.LineWrapper name={"P/E"}  value={sheet.ttmPE}/>
                        <LineWrappers.LineWrapper name={"EPS"}  value={sheet.ttmEPS}/>
                        <LineWrappers.LineWrapperCustom name={"Dividend Yield"}  value={`${sheet.ttmDividendYieldPercentage.toFixed(2)}%`}/>
                        <LineWrappers.LineWrapper name={"LTM Debt/Equity"}  value={sheet.ttmDE}/>
                    </div>
                    <div className="mb-3">
                        <TearSheetSectionHeader text="RECENT PERFORMANCE" />
                        <div className="container-fluid m-0">
                            <PerformanceBlock label={"1D"} performancevalue={sheet['1D']} />
                            <PerformanceBlock label={"5D"} performancevalue={sheet['5D']} />
                            <PerformanceBlock label={"1M"} performancevalue={sheet['1M']} />
                            <PerformanceBlock label={"3M"} performancevalue={sheet['3M']} />
                            <PerformanceBlock label={"6M"} performancevalue={sheet['6M']} />
                            <PerformanceBlock label={"YTD"} performancevalue={sheet['ytd']} />
                            <PerformanceBlock label={"1Y"} performancevalue={sheet['1Y']} />
                        </div>
                    </div>
                </div>


                <div className="col-md-9">
                    <div>
                        <div className="d-flex justify-content-between mb-1">
                            <TearSheetSectionHeader text="HISTORICAL PRICE" />
                            <Button2 onClick={()=>deferExecution(()=>handleHistoricalPrice(sheet.symbol))}>Open Chart</Button2>
                            {/* <OpenWindowAlternativeButton label={"Open Historical Chart Window"} onClick={() => handleHistoricalPrice(sheet.symbol)} /> */}
                        </div>
                    </div>
                    <div ref={chartContainerRef} style={{height: '400px'}}>
                        <LineAreaChart lineData={priceData} symbol={sheet.symbol} contentContainerRef={chartContainerRef} params={{
                            'symbol': sheet.symbol,
                            'format': "arealine",
                            'timeframe': '1Y',
                            'interval': '1D',
                        }}
                        />
                    </div>
                </div>
            </div>

            

            <div className="row mt-2">
                    <div className="col mb-3">
                        <TearSheetSectionHeader text="EFFICIENCY" />
                        <LineWrappers.LineWrapperPercent name={"Gross Margin"}  value={sheet.ttmGrossMargin}/>
                        <LineWrappers.LineWrapperPercent name={"EBIT Margin"}  value={sheet.ttmEbitMargin}/>
                        <LineWrappers.LineWrapperPercent name={"Net Margin"}  value={sheet.ttmNetMargin}/>
                        <LineWrappers.LineWrapperPercent name={"ROA"}  value={sheet.ttmROA}/>
                        <LineWrappers.LineWrapperPercent name={"ROE"}  value={sheet.ttmROE}/>
                        <LineWrappers.LineWrapperPercent name={"ROIC"}  value={sheet.ttmROIC}/>
                    </div>
                    <div className="col mb-3">
                        <TearSheetSectionHeader text="MULTIPLES" />
                        <LineWrappers.LineWrapper name={"P/B"}  value={sheet.ttmPriceBook}/>
                        <LineWrappers.LineWrapper name={"P/Sales"}  value={sheet.ttmPriceSales}/>
                        <LineWrappers.LineWrapper name={"P/FCF"}  value={sheet.ttmPriceFCF}/>
                        <LineWrappers.LineWrapper name={"EV/EBITDA"}  value={sheet.ttmEVEbitda}/>
                        <LineWrappers.LineWrapper name={"EV/Sales"}  value={sheet.ttmEVSales}/>
                        <LineWrappers.LineWrapper name={"EV/FCF"}  value={sheet.ttmEVFCF}/>
                    </div>
            </div>
            <Button2  onClick={()=>deferExecution(()=>handleFinancialStatements(sheet.symbol))} fullWidth={true}>View {sheet.symbol} Financials</Button2>

            <div className="row mt-2">
                <div>
                    <TearSheetSectionHeader text="COMPANY DESCRIPTION" />
                    </div>
                <div>
                    <p>{sheet.description}</p>
                </div>

            </div>
        </div>



      
      );
};

export default TearSheetStructure