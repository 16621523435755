import React, {useState, useEffect, useRef} from "react";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";
import LineChart from "../../charting/chartjs/LineChart";
import FigrTooltip from "../../ui/small/FigrTooltip";
import economicIndicators from "./economicTooltipDescriptions";

const EconomicSeries = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'name': 'GDP'
    };
    const ENDPOINT = '/economic-series/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        updateLayoutItem(windowID, {
            windowParameters: params,
        });
        const layout = getGlobalLayout();
        dashboardLayout.saveLayout(layout);

        // Call the API with the updated params
        setLoading(true); // Start loading
        setError(null); 
        constants.api.post(constants.BACKEND_URL+ENDPOINT, params) // Use newParams here
            .then(response => {
                const responseData = response.data
                const status = responseData.status
                const data = responseData.data

                if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                    setError(responseData.message)
                    showPremiumPrompt(responseData.message)
                } else if (status === "success") {
                    setData(data)
                }

            })
            .catch(error => {
                console.error('Error during POST request:', error);
                setError("Failed to load data.");
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, [params.name]);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }


    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <WindowPieces.ConfigContainer>
                    <div className="m-1 d-flex flex-row align-items-center">
                        <WindowPieces.SquareSelect
                            id={'name'}
                            valueList={
                                [
                                    "GDP",
                                    "realGDP",
                                    "nominalPotentialGDP",
                                    "realGDPPerCapita",
                                    "federalFunds",
                                    "CPI",
                                    "inflationRate",
                                    "inflation",
                                    "retailSales",
                                    "consumerSentiment",
                                    "durableGoods",
                                    "unemploymentRate",
                                    "totalNonfarmPayroll",
                                    "initialClaims",
                                    "industrialProductionTotalIndex",
                                    "newPrivatelyOwnedHousingUnitsStartedTotalUnits",
                                    "totalVehicleSales",
                                    "retailMoneyFunds",
                                    "smoothedUSRecessionProbabilities",
                                    "3MonthOr90DayRatesAndYieldsCertificatesOfDeposit",
                                    "commercialBankInterestRateOnCreditCardPlansAllAccounts",
                                    "30YearFixedRateMortgageAverage",
                                    "15YearFixedRateMortgageAverage"
                                ]
                            } 
                            innerHTMLList={
                                [
                                    "GDP",
                                    "Real GDP",
                                    "Nominal Potential GDP",
                                    "Real GDP Per Capita",
                                    "Federal Funds",
                                    "CPI",
                                    "Inflation Rate",
                                    "Inflation",
                                    "Retail Sales",
                                    "Consumer Sentiment",
                                    "Durable Goods",
                                    "Unemployment Rate",
                                    "Total Nonfarm Payroll",
                                    "Initial Claims",
                                    "Industrial Production Total Index",
                                    "New Privately Owned Housing Units Started (Total Units)",
                                    "Total Vehicle Sales",
                                    "Retail Money Funds",
                                    "Smoothed US Recession Probabilities",
                                    "3-Month or 90-Day Rates and Yields Certificates of Deposit",
                                    "Commercial Bank Interest Rate on Credit Card Plans (All Accounts)",
                                    "30-Year Fixed Rate Mortgage Average",
                                    "15-Year Fixed Rate Mortgage Average"
                                ]
                            }
                            value={params.name}
                            onChange={updateParams}

                        />
                        <FigrTooltip direction="bottom">
                            {economicIndicators[params.name]}
                        </FigrTooltip>
                    </div>
                </WindowPieces.ConfigContainer>
            </WindowPieces.OptionsContainer>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    <WindowPieces.ChartJSContainer>
                        <LineChart lineData={data} windowID={windowID}/>
                    </WindowPieces.ChartJSContainer>                    
                </>
            )}
        </>
    );
};

export default EconomicSeries;
