import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import CustomModal2 from "../CustomModal2";
import ajaxRequestFuncs from "../../../functions/requestFuncs/ajaxRequestFuncs";
import { showAlert } from "../../../functions/showAlert";
import axios from "axios";
import SitePieces from "../../ui/small/SitePieces";
import Button2 from "../../ui/small/buttons/Button2";
import constants from "../../../constants";
import LoadingSpinnerMini from "../../misc/LoadingSpinnerMini";

const ForgotPasswordModal=({show, setShow}) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
      setLoading(true);
  
      try {
        const response = await axios.post(constants.BACKEND_URL+'/auth/send-password-reset-email/', {email}, {
          headers: {
            'Content-Type': 'application/json',
            'x-csrftoken': csrfToken,
          },
          withCredentials: true,
        });
  
        // Assuming the login was successful
        if (response.status === 200) {
          setMessage(response.data.message);
          
        }
      } catch (error) {
        if (error.response.data.redirect) {
          window.location.href = error.response.data.redirect;
          return;
        }
        if (error.response && error.response.data.message) {
          setMessage('Error sending reset email.');
        } else {
          console.error('An unexpected error occurred:', error);
        }
      } finally {
        setLoading(false);
      }
    };
  
    return (
        <CustomModal2 
            show={show}
            title={
                "Forgot Password"
            }
            body={
                <div style={{width: '400px'}}>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="id_email">Email associated with account:</label>
                            <SitePieces.ZenithInput 
                                type={"text"}
                                id={'id_email'}
                                name={"email"}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={"Email"}
                            />
                            
                        </div>
                        
                        {message && (
                          <div className="text-center my-3 border py-3" style={{color: "var(--negative-c)", borderColor: "var(--secondary)"}}>
                            <p className="m-0">{message}</p>
                          </div>
                          )}
          
                        <Button2 onClick={handleSubmit} fullWidth={true} isDisabled={loading}>
                          {loading ? <LoadingSpinnerMini /> : "Send Reset Link"}
                        </Button2>
                     
                    </form>
                </div>
            }
            onClose={()=>setShow(false)}
          />
    )
}

export default ForgotPasswordModal
