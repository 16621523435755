import React, { useState } from "react";
import CustomModal2 from "../CustomModal2";
import PageUpButton from "../../ui/small/buttons/PageUpButton";
import PageDownButton from "../../ui/small/buttons/PageDownButton";
import CloseWindowButton from "../../ui/small/buttons/CloseWIndowButton";
import FigrIconWrapper2 from "../../ui/small/FigrIconWrapper2";


const IntroductionModal = ({show, setShow}) => {

    const [currentSlide, setCurrentSlide] = useState(0); // Start with the first slide

    if (!show) return null;

    const SlideImage = ({imgSource, alt}) => {
        return (
            <div 
                style={{
                    height: "100%", 
                    border: "1px solid var(--secondary)",
                    overflow: "hidden", // Ensures content stays within bounds
                    margin: "0 auto", // Centers the container
                }}
            >
                <img 
                    src={imgSource} 
                    alt={alt}
                    style={{
                        width: "100%", // Fill the container's width
                        height: "100%", // Ensure the image respects the container's height
                        objectFit: "contain", // Preserve aspect ratio
                    }} 
                />
            </div>
        )
    }

    const SlideListItemContainer = ({children}) => {
        return (
            <div >
                {children}
            </div>
        )
    }

    const SlideListItemHeader = ({number, header}) => {
        return (
            <div className="d-flex flex-row p-2 align-items-center">
                <div className="d-flex justify-content-center align-items-center" 
                     style={{
                         height: "20px", 
                         width: "20px", 
                         backgroundColor: "var(--primary-c)",
                         color: "#000000",
                         display: "flex",
                         lineHeight: "20px",
                         textAlign: "center"
                     }}>
                    <span>{number}</span>
                </div>
                <h4 className="ms-2 m-0">{header}</h4>
            </div>
        )
    }

    const SlideListItemWrapper = ({children}) => {
        return (
            <div className="text-left">{children}</div>
        )
    }

    // Define your slides as an array of JSX elements
    const slides = [
            <>
                <h1>Welcome to FIGR Terminal!</h1>
                <div className="mt-5">
                    <p className="px-5 my-5">FIGR Terminal is a cutting-edge financial research and analytics platform, designed to deliver powerful insights with unparalleled speed and dynamic visualizations. Whether you're managing portfolios, analyzing market trends, or exploring financial statements, FIGR empowers you to make data-driven decisions effortlessly.</p>
                    <SlideImage imgSource={"/landing-page/img/figr-layouts/layout-1.png"} alt={"Introduction"} />
                    <p className="px-5 my-5">This introductory guide can be closed and revisited at any time by clicking on the 'GUIDE' button located in the bottom right corner of the terminal.</p>
                </div>
            </>
        ,
            <>
                <h1>Using the Terminal</h1>
                <div className="mt-5">
                    <p className="px-5 my-5">Using the Terminal is incredible easy. Just follow these steps.</p>
                    <div className="p-2" style={{border: "1px solid var(--secondary"}}>
                        <SlideListItemContainer>
                            <SlideListItemHeader number={1} header={"Focusing the Terminal"} />
                            <SlideListItemWrapper>
                                The Terminal can be focused by pressing the 
                                <span className="mx-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                    <FigrIconWrapper2 title={"Enter/Return"}>
                                        <div className="px-2">/</div>
                                    </FigrIconWrapper2>
                                </span>
                                key.
                                <div className="px-4 py-3">
                                    <SlideImage imgSource={"/landing-page/img/figr-intro/terminal.png"} alt={"FIGR Terminal Search"} />
                                    <SlideImage imgSource={"/landing-page/img/figr-intro/terminal-focused.png"} alt={"FIGR Terminal Focused"} />
                                </div>
                   

                            </SlideListItemWrapper>
                        </SlideListItemContainer>

                        <SlideListItemContainer>
                            <SlideListItemHeader number={2} header={"Executing Commands - Method 1"} />
                            <SlideListItemWrapper>
                                <p>To execute any command, all you have to do is type in a symbol (e.g. AAPL) and then a FIGR function (e.g. FA - for financial analysis).</p>
                                <p>
                                    <strong>Example: </strong>
                                    <span className="mx-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                        <FigrIconWrapper2 title={"Symbol"}>
                                            <div className="px-2">AAPL</div>
                                        </FigrIconWrapper2>
                                    </span>
                                    <span className="mx-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                        <FigrIconWrapper2 title={"Function"}>
                                            <div className="px-2">FA</div>
                                        </FigrIconWrapper2>
                                    </span>
                                </p>
                                <p>Then press 
                                    <span className="mx-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                        <FigrIconWrapper2 title={"Enter/Return"}>
                                        <svg
                                            style={{ height: "12px", width: "12px" }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 -960 960 960"
                                        >
                                            <path d="M360-240 120-480l240-240 56 56-144 144h488v-160h80v240H272l144 144z" />
                                        </svg>
                                        </FigrIconWrapper2>
                                    </span>
                                    to return AAPL Financials.
                                </p>
                                
                                
                   

                            </SlideListItemWrapper>
                        </SlideListItemContainer>
                    </div>
                </div>
            </>,
        <>
            <h1>Using the Terminal Cont'd</h1>
                <div className="mt-5">
                    <div className="p-2" style={{border: "1px solid var(--secondary"}}>
                        <SlideListItemContainer>
                            <SlideListItemHeader number={3} header={"Executing Commands - Method 2"} />
                            <SlideListItemWrapper>
                                <p>You can also just type in the symbol, and use the security functions menu to choose a function.</p>
                                <p>
                                    <strong>Example: </strong>
                                    <span className="mx-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                        <FigrIconWrapper2 title={"Symbol"}>
                                            <div className="px-2">AAPL</div>
                                        </FigrIconWrapper2>
                                    </span>
                                </p>
                                <p>Then press 
                                    <span className="mx-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                        <FigrIconWrapper2 title={"Enter/Return"}>
                                            <svg
                                                style={{ height: "12px", width: "12px" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 -960 960 960"
                                            >
                                                <path d="M360-240 120-480l240-240 56 56-144 144h488v-160h80v240H272l144 144z" />
                                            </svg>
                                        </FigrIconWrapper2>
                                    </span>
                                    to open the security functions menu for AAPL.
                                </p>
                                <SlideImage imgSource={"/landing-page/img/figr-intro/functions-menu.png"} />
                                <p className="mt-2">You can use the arrow keys 
                                    <span className="m-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                        <FigrIconWrapper2 title={"Up"}>
                                            <svg
                                                style={{ height: "12px", width: "12px" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 -960 960 960"
                                            >
                                                <path d="M480-528 296-344l-56-56 240-240 240 240-56 56z" />
                                            </svg>
                                        </FigrIconWrapper2>
                                    </span>
                                    <span className="m-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                        <FigrIconWrapper2 title={"Down"}>
                                            <svg
                                                style={{ height: "12px", width: "12px" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 -960 960 960"
                                            >
                                                <path d="M480-344 240-584l56-56 184 184 184-184 56 56z" />
                                            </svg>
                                        </FigrIconWrapper2>
                                    </span>
                                    to navigate the menu or your mouse.
                                </p>
                                <p>
                                    Use your mouse to make a selection or use 
                                    <span className="mx-1" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                        <FigrIconWrapper2 title={"Enter/Return"}>
                                            <svg
                                                style={{ height: "12px", width: "12px" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 -960 960 960"
                                            >
                                                <path d="M360-240 120-480l240-240 56 56-144 144h488v-160h80v240H272l144 144z" />
                                            </svg>
                                        </FigrIconWrapper2>
                                    </span>.
                                </p>
                                <p>This method may take a bit more time, so we encourage you to explore the commands for quicker navigation. You can find a complete list by clicking the 'HELP' button located in the bottom-right corner of the screen.</p>
                                
                                
                   

                            </SlideListItemWrapper>
                        </SlideListItemContainer>
                    </div>
                </div>
        </>,
        <>
            <h1>Dashboards</h1>
            <div className="mt-5">
                <p className="px-5 my-5">
                    The FIGR Terminal allows you to create and manage multiple custom dashboards. You can navigate between them, add new ones, or remove existing ones using the tabs at the top of the screen.
                </p>
                <SlideImage imgSource={"/landing-page/img/figr-intro/tabs.png"} />
                <p className="px-5 my-5">
                    Tabs can be renamed, customized with different colours, or deleted through the dropdown menu.
                </p>
                <SlideImage imgSource={"/landing-page/img/figr-intro/tabs-open.png"} />
            </div>

        </>,
    ];

    // Handle navigation
    const handleNextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length); // Cycle to the next slide
    };

    const handlePreviousSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length); // Cycle to the previous slide
    };



    return (
        <>
            {/* Backdrop */}
            <div 
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
                    zIndex: 999, // Ensure it's behind the modal but above other content
                }}
                onClick={()=>setShow(false)} // Close modal when clicking on the backdrop
            ></div>

            <div 
                style={{
                    position: 'fixed',
                    backgroundColor: 'var(--theme)',
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '1rem',
                    color: 'var(--text)',
                    borderColor: "var(--secondary)",
                    zIndex: 1000, // Ensure it's above the backdrop
                    width: "30%",
                    minWidth: "400px"
                }}
                className={`border modal-content-animate modal-slide-up`}
            >
                <div className='w-100 d-flex flex-row-reverse justify-content-between align-items-center'>
                    <CloseWindowButton onClick={()=>setShow(false)} title='Close'/>
                </div>


                <div className='px-5 d-flex flex-column justify-content-between' style={{minWidth: "80%", minHeight: "600px", textAlign: "center"}}>

                    <div>{slides[currentSlide]}</div>
                 
                    <div>
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                {slides.map((_, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: "10px",
                                            height: "10px",
                                            margin: "0 5px",
                                            backgroundColor: index === currentSlide ? "var(--primary-c)" : "gray",
                                            borderRadius: "0px",
                                            transition: "background-color 0.3s ease",
                                        }}
                                    />
                                ))}
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <PageDownButton onClick={handlePreviousSlide} />
                                <PageUpButton onClick={handleNextSlide} />
                            </div>
                        </div>



                </div>
                
            </div>
        </>
    );
};

export default IntroductionModal;