import React, { useState } from "react";
import CloseWindowButton from "./buttons/CloseWIndowButton";
import getRootColorString from "../../../functions/helpers/getRootColorString";

const TipSection = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
        {isVisible && (
            <div
            className={`p-2 d-flex flex-row justify-content-between transition-height`}
            style={{
                height: isVisible ? "auto" : "0",
                overflow: "hidden",
                transition: "height 2s ease",
                backgroundColor: "var(--secondary)",
            }}
            >
            <p className="m-0">
                <strong>TIP: </strong>
                {message}
            </p>
            <CloseWindowButton onClick={() => setIsVisible(false)} />
            </div>
        )}
    </>
  );
};

export default TipSection;