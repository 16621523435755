import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import CustomModal2 from "../CustomModal2";
import ajaxRequestFuncs from "../../../functions/requestFuncs/ajaxRequestFuncs";
import { showAlert } from "../../../functions/showAlert";
import axios from "axios";
import SitePieces from "../../ui/small/SitePieces";
import Button2 from "../../ui/small/buttons/Button2";
import constants from "../../../constants";
import LoadingSpinnerMini from "../../misc/LoadingSpinnerMini";
import GoogleSignInButton from "../../ui/small/buttons/GoogleSignInButton";
import RedditSignInButton from "../../ui/small/buttons/RedditSignInButton";

const LoginModal=({show, setShow, setShowRegister, setShowForgotPassword}) => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [capsLockOn, setCapsLockOn] = useState(false);

    const navigate = useNavigate();

    const handleCapsLockCheck = (e) => {
      setCapsLockOn(e.getModifierState && e.getModifierState('CapsLock'));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
      setLoading(true);
  
      try {
        const response = await axios.post(constants.BACKEND_URL+'/auth/login/', formData, {
          headers: {
            'Content-Type': 'application/json',
            'x-csrftoken': csrfToken,
          },
          withCredentials: true,
        });
  
        // Assuming the login was successful
        if (response.status === 200) {
          navigate(constants.REACT_APP_REDIRECT_ENDPOINT);
          window.location.reload();
          showAlert('success', "Successfully Logged In!")
        }
      } catch (error) {
          if (error.response.data.redirect) {
            window.location.href = error.response.data.redirect;
            return;
          }
          if (error.response && error.response.data.message) {
            setErrorMessage(error.response.data.message);
          } else {
            console.error('An unexpected error occurred:', error);
          }
      } finally {
        setLoading(false);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    return (
        <CustomModal2 
            show={show}
            title={
                "Login"
            }
            body={
                <div style={{width: '400px'}}>
                    <form onSubmit={handleSubmit}>
                        <div className="mt-2">
                          <div>
                            <GoogleSignInButton />
                          </div>
                          <div className="mt-2">
                            <RedditSignInButton />
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-center">
                          OR
                        </div>
                        <div className="mb-4">
                            <label htmlFor="id_username">Username</label>
                            <SitePieces.ZenithInput 
                                type={"text"}
                                id={'id_username'}
                                name={"username"}
                                value={formData.username}
                                onChange={handleChange}
                                placeholder={"Username"}
                            />
                            {/* {errors.username && <div className="text-danger">{errors.username}</div>} */}
                          </div>
                          <div className="mb-4">
                            <label htmlFor="id_password">Password</label>
                            <SitePieces.ZenithInput 
                                type={"password"}
                                id={'id_password'}
                                name={"password"}
                                value={formData.password}
                                onChange={handleChange}
                                placeholder={"Password"}
                            />

                            {/* {errors.password && <div className="text-danger">{errors.password}</div>} */}
                          </div>

                        
                        {/* {errors.non_field_errors && <div className="text-danger">{errors.non_field_errors}</div>} */}
                        {errorMessage && (
                          <div className="text-center my-3 border py-3" style={{color: "var(--negative-c)", borderColor: "var(--secondary)"}}>
                            <p className="m-0">{errorMessage}</p>
                          </div>
                        )}
          
                        <Button2 onClick={handleSubmit} fullWidth={true} disabled={loading} isDisabled={loading}>
                          {loading ? <LoadingSpinnerMini /> : "Log in"}
                        </Button2>
                     
                    </form>
                    <div className="text-center mt-4 border py-3" style={{borderColor: "var(--secondary)"}}>
                        <p className="m-0">Don't have an account? <a style={{color: "var(--primary-c)"}} href="#" onClick={() => { setShow(false); setShowRegister(true) }} >Register</a></p>
                    </div>
                    <div className="text-center mt-4">
                        <p className="m-0">Forgot Password? <a href="#" style={{color: "var(--negative)"}} onClick={() => { setShow(false); setShowForgotPassword(true) }} >Reset</a></p>
                    </div>
                    
                </div>
            }
            onClose={()=>setShow(false)}
          />
    )
}

export default LoginModal
