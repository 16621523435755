const fundamentals = [
    ///////////INCOME STATEMENT
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'revenue', name: 'Revenue', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'costOfRevenue', name: 'Cost of Revenue / Cost of Goods Sold', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'grossProfit', name: 'Gross Profit', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'researchAndDevelopmentExpenses',  name: 'Research & Development Expenses', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'sellingGeneralAndAdministrativeExpenses', name: 'Selling, General, and Administrative Expenses', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'operatingExpenses', name: 'Operating Expenses', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'interestIncome', name: 'Interest Income', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'interestExpense', name: 'Interest Expense', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'depreciationAndAmortization', name: 'Depreciation & Amortization Expense', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'ebit', name: 'Earnings Before Interest and Taxes (EBIT)', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'ebitda', name: 'Earnings Before Interest, Taxes, Depreciation, and Amortization (EBITDA)', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'operatingIncome', name: 'Operating Income', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'incomeBeforeTax', name: 'Pre-tax Income', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'incomeTaxExpense', name: 'Income Tax Expense', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Income Statement', fmpName: 'netIncome', name: 'Net Income', suggestionType: 'fundamental' },

    { isNumber: true, isDollar: false, sourceName: 'Income Statement', fmpName: 'grossProfitRatio', name: 'Gross Profit Margin', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: false, sourceName: 'Income Statement', fmpName: 'ebitdaratio', name: 'Earnings Before Interest, Taxes, Depreciation, and Amortization (EBITDA) Margin', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: false, sourceName: 'Income Statement', fmpName: 'operatingIncomeRatio', name: 'Operating Income Margin', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: false, sourceName: 'Income Statement', fmpName: 'incomeBeforeTaxRatio', name: 'Pre-tax Margin', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: false, sourceName: 'Income Statement', fmpName: 'netIncomeRatio', name: 'Net Profit Margin', suggestionType: 'fundamental' },

    { isNumber: true, isDollar: false, sourceName: 'Income Statement', fmpName: 'eps', name: 'Earnings per Share', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: false, sourceName: 'Income Statement', fmpName: 'epsdiluted', name: 'Diluted Earnings per Share', suggestionType: 'fundamental' },
    
    ///////////BALANCE SHEET
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'cashAndCashEquivalents', name: 'Cash & Equivalents', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'shortTermInvestments', name: 'Short-term Investments', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'netReceivables', name: 'Accounts Receivable', suggestionType: 'fundamental' },
    
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'inventory', name: 'Inventory', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalCurrentAssets', name: 'Total Current Assets', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'propertyPlantEquipmentNet', name: 'Plant, Property, and Equipment (PP&E)', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'goodwill', name: 'Goodwill', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'intangibleAssets', name: 'Intangible Assets', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'longTermInvestments', name: 'Long-term Investments', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'taxAssets', name: 'Deferred Tax Assets (DTA)', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalNonCurrentAssets', name: 'Total Non-current Assets', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalAssets', name: 'Total Assets', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'accountsPayables', name: 'Accounts Payable', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'shortTermDebt', name: 'Short-term Debt', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'taxPayables', name: 'Taxes Payable', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'deferredRevenue', name: 'Deferred Revenue', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalCurrentLiabilities', name: 'Total Current Liabilities', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'longTermDebt', name: 'Long-term Debt', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'deferredRevenueNonCurrent', name: 'Deferred Revenue Non-current', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'deferredTaxLiabilitiesNonCurrent', name: 'Deferred Tax Liabilities (DTA) Non-current', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalNonCurrentLiabilities', name: 'Total Non-current Liabilities', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'capitalLeaseObligations', name: 'Capital Lease Obligations', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalLiabilities', name: 'Total Liabilities', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'preferredStock', name: 'Preferred Stock', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'commonStock', name: 'Common Stock', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'retainedEarnings', name: 'Retained Earnings', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalStockholdersEquity', name: "Total Stockholder's Equity", suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalLiabilitiesAndStockholdersEquity', name: "Total Liabilities & Shareholder's Equity", suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'totalDebt', name: 'Total Debt', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName: 'Balance Sheet', fmpName: 'netDebt', name: 'Net Debt', suggestionType: 'fundamental' },

    ///////////CASH FLOW STATEMENT
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'deferredIncomeTax', name: 'Deferred Income Taxes', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'stockBasedCompensation', name: 'Stock-based Compensation Expense', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'changeInWorkingCapital', name: 'Change in Working Capital', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'netCashProvidedByOperatingActivities', name: 'Cash Flow from Operating Activities (CFO)', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'netCashUsedForInvestingActivites', name: 'Cash Flow from Investing Activities (CFI)', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'debtRepayment', name: 'Debt Repayment', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'commonStockIssued', name: 'Issuance of Common Stock', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'commonStockRepurchased', name: 'Repurchase of Common Stock', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'dividendsPaid', name: 'Dividends Paid', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'netCashUsedProvidedByFinancingActivities', name: 'Cash Flow from Financing Activities (CFF)', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'netChangeInCash', name: 'Net Change in Cash', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'cashAtEndOfPeriod', name: 'Cash Beginning of Period', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'cashAtBeginningOfPeriod', name: 'Cash End of Peroid', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'capitalExpenditure', name: 'Capital Expenditures', suggestionType: 'fundamental' },
    { isNumber: true, isDollar: true, sourceName:'Cash Flow Statement', fmpName: 'freeCashFlow', name: 'Free Cash Flow', suggestionType: 'fundamental' },

    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'dividendYield', name: 'Dividend Yield', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'dividendYielPercentageTTM', name: 'Dividend Yield Percentage', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'peRatioTTM', name: 'PE Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'pegRatioTTM', name: 'PEG Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'payoutRatioTTM', name: 'Dividend Payout Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'currentRatioTTM', name: 'Current Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'quickRatioTTM', name: 'Quick Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'cashRatioTTM', name: 'Cash Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'daysOfSalesOutstandingTTM', name: 'Days Of Sales Outstanding', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'daysOfInventoryOutstandingTTM', name: 'Days Of Inventory Outstanding', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'operatingCycleTTM', name: 'Operating Cycle', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'daysOfPayablesOutstandingTTM', name: 'Days Of Payables Outstanding', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'cashConversionCycleTTM', name: 'Cash Conversion Cycle', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'grossProfitMarginTTM', name: 'Gross Profit Margin', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'operatingProfitMarginTTM', name: 'Operating Profit Margin', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'pretaxProfitMarginTTM', name: 'Pretax Profit Margin', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'netProfitMarginTTM', name: 'Net Profit Margin', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'effectiveTaxRateTTM', name: 'Effective Tax Rate', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'returnOnAssetsTTM', name: 'Return On Assets', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'returnOnEquityTTM', name: 'Return On Equity', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'returnOnCapitalEmployedTTM', name: 'Return On Capital Employed', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'netIncomePerEBTTTM', name: 'Net Income Per EBT', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'ebtPerEbitTTM', name: 'EBT Per EBIT', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'ebitPerRevenueTTM', name: 'EBIT Per Revenue', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'debtRatioTTM', name: 'Debt Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'debtEquityRatioTTM', name: 'Debt Equity Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'longTermDebtToCapitalizationTTM', name: 'Long Term Debt To Capitalization', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'totalDebtToCapitalizationTTM', name: 'Total Debt To Capitalization', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'interestCoverageTTM', name: 'Interest Coverage', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'cashFlowToDebtRatioTTM', name: 'Cash Flow To Debt Ratio', suggestionType: 'fundamental'},

    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'receivablesTurnoverTTM', name: 'Receivables Turnover Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'payablesTurnoverTTM', name: 'Payables Turnover Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'inventoryTurnoverTTM', name: 'Inventory Turnover Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'fixedAssetTurnoverTTM', name: 'Fixed Asset Turnover Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'assetTurnoverTTM', name: 'Asset Turnover Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'operatingCashFlowPerShareTTM', name: 'Operating Cash Flow Per Share', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'cashPerShareTTM', name: 'Cash Per Share', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'operatingCashFlowSalesRatioTTM', name: 'Operating Cash Flow to Sales Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'freeCashFlowOperatingCashFlowRatioTTM', name: 'Free Cash Flow to Operating Cash Flow Ratio', suggestionType: 'fundamental'},

    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'capitalExpenditureCoverageRatioTTM', name: 'Capital Expenditure Coverage Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'dividendPaidAndCapexCoverageRatioTTM', name: 'Dividend Coverage Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'priceBookValueRatioTTM', name: 'Price To Book Value Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'priceToSalesRatioTTM', name: 'Price To Sales Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'priceEarningsRatioTTM', name: 'Price Earnings Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'priceToFreeCashFlowsRatioTTM', name: 'Price To Free Cash Flow Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'priceToOperatingCashFlowsRatioTTM', name: 'Price To Operating Cash Flow Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'priceCashFlowRatioTTM', name: 'Price To Cash Flow Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'enterpriseValueMultipleTTM', name: 'Enterprise Value Multipler Ratio', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'dividendPerShareTTM', name: 'Dividend Per Share', suggestionType: 'fundamental'},
    // {isNumber: true, isDollar: true, sourceName: 'Ratios & Multiples', fmpName: 'priceEarningsRatio', name: 'Price Earnings Ratio', suggestionType: 'fundamental' },
    
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'currentRatio', name: 'Current Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'quickRatio', name: 'Quick Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'cashRatio', name: 'Cash Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'daysOfSalesOutstanding', name: 'Days Of Sales Outstanding', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'daysOfInventoryOutstanding', name: 'Days Of Inventory Outstanding', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'operatingCycle', name: 'Operating Cycle', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'daysOfPayablesOutstanding', name: 'Days Of Payables Outstanding', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'cashConversionCycle', name: 'Cash Conversion Cycle', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'grossProfitMargin', name: 'Gross Profit Margin', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'operatingProfitMargin', name: 'Operating Profit Margin', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'pretaxProfitMargin', name: 'Pretax Profit Margin', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'netProfitMargin', name: 'Net Profit Margin', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'effectiveTaxRate', name: 'Effective Tax Rate', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'returnOnAssets', name: 'Return On Assets', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'returnOnEquity', name: 'Return On Equity', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'returnOnCapitalEmployed', name: 'Return On Capital Employed', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'netIncomePerEBT', name: 'Net Income Per Ebt', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'ebtPerEbit', name: 'Ebt Per Ebit', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'ebitPerRevenue', name: 'Ebit Per Revenue', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'debtRatio', name: 'Debt Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'debtEquityRatio', name: 'Debt Equity Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'longTermDebtToCapitalization', name: 'Long Term Debt To Capitalization', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'totalDebtToCapitalization', name: 'Total Debt To Capitalization', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'interestCoverage', name: 'Interest Coverage', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'cashFlowToDebtRatio', name: 'Cash Flow To Debt Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'companyEquityMultiplier', name: 'Company Equity Multiplier', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'receivablesTurnover', name: 'Receivables Turnover', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'payablesTurnover', name: 'Payables Turnover', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'inventoryTurnover', name: 'Inventory Turnover', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'fixedAssetTurnover', name: 'Fixed Asset Turnover', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'assetTurnover', name: 'Asset Turnover', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'operatingCashFlowPerShare', name: 'Operating Cash Flow Per Share', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'freeCashFlowPerShare', name: 'Free Cash Flow Per Share', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'cashPerShare', name: 'Cash Per Share', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'payoutRatio', name: 'Payout Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'operatingCashFlowSalesRatio', name: 'Operating Cash Flow Sales Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'freeCashFlowOperatingCashFlowRatio', name: 'Free Cash Flow Operating Cash Flow Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'cashFlowCoverageRatios', name: 'Cash Flow Coverage Ratios', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'shortTermCoverageRatios', name: 'Short Term Coverage Ratios', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'capitalExpenditureCoverageRatio', name: 'Capital Expenditure Coverage Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'dividendPaidAndCapexCoverageRatio', name: 'Dividend Paid And Capex Coverage Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'dividendPayoutRatio', name: 'Dividend Payout Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceBookValueRatio', name: 'Price Book Value Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceToBookRatio', name: 'Price To Book Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceToSalesRatio', name: 'Price To Sales Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceEarningsRatio', name: 'Price Earnings Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceToFreeCashFlowsRatio', name: 'Price To Free Cash Flows Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceToOperatingCashFlowsRatio', name: 'Price To Operating Cash Flows Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceCashFlowRatio', name: 'Price Cash Flow Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceEarningsToGrowthRatio', name: 'Price Earnings To Growth Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceSalesRatio', name: 'Price Sales Ratio', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'dividendYield', name: 'Dividend Yield', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'enterpriseValueMultiple', name: 'Enterprise Value Multiple', suggestionType: 'fundamental'},
    {isNumber: true, isDollar: false, sourceName: 'Ratios & Multiples', fmpName: 'priceFairValue', name: 'Price Fair Value', suggestionType: 'fundamental'},


    {isNumber: false, isDollar: false, sourceName: 'Information', fmpName: 'country', name: 'Country of Headquarters', suggestionType: 'fundamental' },
    {isNumber: false, isDollar: false, sourceName: 'Information', fmpName: 'sector', name: 'Sector', suggestionType: 'fundamental' },
    {isNumber: false, isDollar: false, sourceName: 'Information', fmpName: 'industry', name: 'Industry', suggestionType: 'fundamental' },
    {isNumber: false, isDollar: false, sourceName: 'Information', fmpName: 'fullTimeEmployees', name: 'Number of Employees', suggestionType: 'fundamental' },
    {isNumber: false, isDollar: false, sourceName: 'Information', fmpName: 'exchange', name: 'Exchange', suggestionType: 'fundamental' },
    {isNumber: true, isDollar: false, sourceName: 'Information', fmpName: 'beta', name: 'Beta', suggestionType: 'fundamental' },

]
export default fundamentals