import React from "react";

const LogoutSideNavWrapper = ({iconSVG, label, href="#", color=""}) => {
    return (
        <a href="/logout" className="hover-item d-flex flex-row p-3 align-items-center">
            <div style={{fill: "var(--negative-c)"}}>
                {iconSVG}
            </div>
            <div className="mx-3" style={{fontSize: "12px", color: "var(--negative-c)"}}>
                {label}
            </div>
        </a>
    )   
}

export default LogoutSideNavWrapper