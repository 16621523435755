import React, { useEffect, useState } from 'react';
import SitePieces from '../ui/small/SitePieces';
import CloseWindowButton from '../ui/small/buttons/CloseWIndowButton';
import getRootColorString from '../../functions/helpers/getRootColorString';
import Button2 from '../ui/small/buttons/Button2';
import Button1 from '../ui/small/buttons/Button1';
import handleCheckout from '../../functions/handleCheckout';
import Button1Large from '../ui/small/buttons/Button1Large';
import axios from 'axios';
import constants from '../../constants';

const PremiumPromptModal = ({body, closeModal}) => {
    const [user, setUser] = useState(null)

    useEffect(()=>{
        axios.get(constants.BACKEND_URL+'/user-info/', {
            withCredentials: true
        })
        .then((response) => {
            setUser(response.data);
        })
        .catch((error) => {
            setUser(null);  
        });
    }, [])

   
    return (
        <>
            {/* Backdrop */}
            <div 
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
                    zIndex: 999, // Ensure it's behind the modal but above other content
                }}
                onClick={closeModal} // Close modal when clicking on the backdrop
            ></div>

            <div 
                style={{
                    position: 'fixed',
                    backgroundColor: 'var(--theme)',
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '1rem',
                    color: 'var(--text)',
                    borderColor: "var(--secondary)",
                    zIndex: 1000, // Ensure it's above the backdrop
                }}
                className={`border modal-content-animate modal-slide-up`}
            >
                <div className='w-100 d-flex flex-row-reverse justify-content-between align-items-center'>
                    <CloseWindowButton onClick={closeModal} title='Close'/>
                </div>


                <div className='px-5' style={{width: "400px", textAlign: "center"}}>
                    <h1 style={{fontWeight: "600", marginTop: "1em"}}>Upgrade to Premium!</h1>
                    <div 
                        style={{
                            backgroundColor: `#ffd70050`, // Circle background color
                            width: "120px",
                            height: "120px",
                            borderRadius: "50%", // Makes it a circle
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "3rem auto",
                            position: "relative", // To position flames
                            animation: "floatRocket 4s infinite ease-in-out",
                        }}
                    >
                        <div style={{
                            position: "absolute",
                            bottom: "-20px",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "50px", // Total width for booster flames
                        }}>
                            {/* Flame 1 */}
                            <div 
                                style={{
                                    width: "4px",
                                    height: "30px",
                                    backgroundColor: "orange",
                                    opacity: "0.7",
                                    animation: "boosterFlame 0.8s infinite alternate",
                                }}
                            ></div>
                            {/* Flame 2 */}
                            <div 
                                style={{
                                    width: "4px",
                                    height: "40px",
                                    backgroundColor: "yellow",
                                    opacity: "0.8",
                                    animation: "boosterFlame 0.6s infinite alternate",
                                }}
                            ></div>
                            {/* Flame 3 */}
                            <div 
                                style={{
                                    width: "4px",
                                    height: "30px",
                                    backgroundColor: "orange",
                                    opacity: "0.7",
                                    animation: "boosterFlame 0.85s infinite alternate",
                                }}
                            ></div>
                        </div>

                        {/* Rocket Ship SVG */}
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="64" 
                            height="64" 
                            fill="#ffd700" 
                            viewBox="0 0 16 16"
                        >
                            <path d="M10.175 1.991c.81 1.312 1.583 3.43 1.778 6.819l1.5 1.83A2.5 2.5 0 0 1 14 12.202V15.5a.5.5 0 0 1-.9.3l-1.125-1.5c-.166-.222-.42-.4-.752-.57-.214-.108-.414-.192-.627-.282l-.196-.083C9.7 13.793 8.85 14 8 14s-1.7-.207-2.4-.635q-.101.044-.198.084c-.211.089-.411.173-.625.281-.332.17-.586.348-.752.57L2.9 15.8a.5.5 0 0 1-.9-.3v-3.298a2.5 2.5 0 0 1 .548-1.562l.004-.005L4.049 8.81c.197-3.323.969-5.434 1.774-6.756.466-.767.94-1.262 1.31-1.57a3.7 3.7 0 0 1 .601-.41A.55.55 0 0 1 8 0c.101 0 .17.027.25.064q.056.025.145.075c.118.066.277.167.463.315.373.297.85.779 1.317 1.537M9.5 6c0-1.105-.672-2-1.5-2s-1.5.895-1.5 2S7.172 8 8 8s1.5-.895 1.5-2"/>
                            <path d="M8 14.5c.5 0 .999-.046 1.479-.139L8.4 15.8a.5.5 0 0 1-.8 0l-1.079-1.439c.48.093.98.139 1.479.139"/>
                        </svg>
                    </div>

                    <div style={{marginTop: "3em", fontSize: "1.2rem"}}>
                        {body}
                    </div>
                    {!user && (
                        <div style={{marginTop: "3em", fontSize: "1.2rem", color: "var(--negative-c)"}}>
                            You are not signed in. Login and upgrade to premium today!
                        </div>
                    )}

                    {user ? (
                        <div className='d-flex flex-row justify-content-center gap-3' style={{marginBlock: "2em"}}>
                            <button 
                                style={{backgroundColor: "var(--secondary)", color: "var(--text)"}} 
                                type="button" 
                                className={`text-nowrap rounded-0 btn btn-lg`}
                                onClick={closeModal}>
                                No Thanks
                            </button>
                            <Button1Large onClick={()=>handleCheckout()}>Upgrade Now</Button1Large>
                        </div>

                    ) : (
                        <div className='d-flex flex-row justify-content-center gap-3' style={{marginBlock: "2em"}}>
                            <button 
                                style={{backgroundColor: "var(--secondary)", color: "var(--text)"}} 
                                type="button" 
                                className={`text-nowrap rounded-0 btn btn-lg`}
                                onClick={closeModal}>
                                Close
                            </button>
                        </div>
                    )}         




                </div>
                
            </div>

            <style>
            {`
                @keyframes floatRocket {
                    0% { transform: translateY(0); }
                    50% { transform: translateY(-10px); }
                    100% { transform: translateY(0); }
                }

                @keyframes boosterFlame {
                    0% { height: 30px; opacity: 0.7; }
                    100% { height: 50px; opacity: 0.3; }
                }
            `}
            </style>

        </>
    );
};

export default PremiumPromptModal;
