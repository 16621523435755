import constants from "../../constants";
import ajaxRequestFuncs from "../requestFuncs/ajaxRequestFuncs";
import axios from "axios";

function saveWatchlist(watchlistObject) {
    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
    
    axios.post(constants.BACKEND_URL+'/save-watchlist/', watchlistObject, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,  // Include CSRF token in the header
        },
        withCredentials: true,  // Ensure that the cookie is sent with the request
    })
    .then(response => {
    })
    .catch(error => {
        console.error("An error occurred:", error);  // Log any errors
    });
}

function getWatchlist() {
    return axios.get(constants.BACKEND_URL+'/get-watchlist/', {
        withCredentials: true,
        headers: {
            'X-CSRFToken': ajaxRequestFuncs.getCookie('csrftoken') // Adding CSRF token in case it's required
        }
    })
    .then(response => {
        return response.data; // Return the layout data
    })
    .catch(error => {
        console.error("An error occurred:", error); // Log any errors
        throw error; // Re-throw the error to be handled by the caller
    });
}

const DEFAULT_WATCHLIST = {
    'Indicies': ['^SPX', '^VIX', '^NDX'],
    'Tech': ['META', 'MSFT', 'NVDA', 'GOOGL', 'AMZN', 'TSLA'],
    "Finance": ["JPM", "GS", "BAC"]
};

async function loadWatchlist(){
    try {
        const { watchlist_data } = await getWatchlist();
        return watchlist_data || DEFAULT_WATCHLIST;
    } catch (error) {
        console.error("Failed to load watchlist:", error);
        return DEFAULT_WATCHLIST;
    }
};


export default { saveWatchlist, getWatchlist, loadWatchlist }