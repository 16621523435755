import constants from "../../constants";
import ajaxRequestFuncs from "../requestFuncs/ajaxRequestFuncs";
import axios from "axios";

function saveUserPreferences(preferencesObject) {
    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken'); // Assuming getCookie function exists
  
    return axios.post(constants.BACKEND_URL+'/save-user-preferences/', preferencesObject, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      withCredentials: true,
    })
    .then((response) => {
      return response;  // Return the response if needed for further processing
    })
    .catch((error) => {
      console.error("Error saving user preferences:", error);
      throw error;  // Ensure errors are propagated for .catch() in the component
    });
  }

function getUserPreferences() {
    return axios.get(constants.BACKEND_URL+'/get-user-preferences/', {
        withCredentials: true,
        headers: {
            'X-CSRFToken': ajaxRequestFuncs.getCookie('csrftoken') 
        }
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.error("An error occurred:", error); 
        throw error; 
    });
}
export default { saveUserPreferences, getUserPreferences }