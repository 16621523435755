import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { showAlert } from '../functions/showAlert';
import LoadingSpinnerMini from '../components/misc/LoadingSpinnerMini';
import ajaxRequestFuncs from '../functions/requestFuncs/ajaxRequestFuncs';
import constants from '../constants';
import SitePieces from '../components/ui/small/SitePieces';
import Button2 from '../components/ui/small/buttons/Button2';

function ResetPassword() {
    const { userId, token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
        setLoading(true);

        try {
            const response = await axios.post(`${constants.BACKEND_URL}/auth/reset-password/${userId}/${token}/`, { 
                'password': password,
                'confirm_password': confirmPassword
             }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-csrftoken': csrfToken,
                  },
                withCredentials: true,
            });
            setMessage(response.data.message);
            setTimeout(() => navigate(constants.REACT_APP_REDIRECT_ENDPOINT), 3000);
            showAlert("success", "Password successfully reset! Redirecting now...")
        } catch (error) {
            if (error.response && error.response.data.message) {
            setMessage(error.response.data.message);
            } else {
            console.error('An unexpected error occurred:', error);
        }
        } finally {
            setLoading(false)
        }
    };

    // return (
    //     <div style={{position: "relative", height: "100vh", width:"100vw", backgroundColor: "#000000"}}>
    //         <div 
    //             style={{
    //                 position: 'fixed',
    //                 backgroundColor: '#000000',
    //                 top: '30%', // Center vertically
    //                 left: '50%', // Center horizontally
    //                 transform: 'translate(-50%, -50%)',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 padding: '2rem',
    //                 color: 'white',
    //                 borderColor: "#212121",
    //                 zIndex: 1000, // Ensure it's above the backdrop
    //             }}
    //             className='border'

    //         >
    //             <h2>Reset Password</h2>
    //             <div style={{minWidth: '300px', width: "50%"}}>
    //                 <form onSubmit={handleSubmit}>

    //                     <div className="mb-4">
    //                         <label htmlFor="id_username">New Password</label>
    //                         <SitePieces.ZenithInput 
    //                             type={"password"}
    //                             id={'id_password'}
    //                             name={"password"}
    //                             value={password}
    //                             onChange={(e) => setPassword(e.target.value)}
    //                             placeholder={"Enter new password"}
    //                         />
    //                     </div>
    //                     <div className="mb-4">
    //                         <label htmlFor="id_email">Confirm New Password:</label>
    //                         <SitePieces.ZenithInput 
    //                             type={"password"}
    //                             id={'id_password2'}
    //                             name={"password2"}
    //                             value={confirmPassword}
    //                             onChange={(e) => setConfirmPassword(e.target.value)}
    //                             placeholder={"Confirm New Password"}
    //                         />
                            
    //                     </div>
    //                     {message && (
    //                       <div className="text-center my-3 border py-3" style={{color: "var(--negative-c)", borderColor: "var(--secondary)"}}>
    //                         <p className="m-0">{message}</p>
    //                       </div>
    //                     )}
    //                     <Button2 onClick={handleSubmit} fullWidth={true} disabled={loading}>
    //                         {loading ? <LoadingSpinnerMini /> : "Reset Password"}
    //                     </Button2>
    //                 </form>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <div className="min-vh-100 w-100 bg-black p-3">
            <div 
                className="position-absolute top-50 start-50 translate-middle 
                          bg-black border p-4 p-md-5"
                style={{
                    width: '90%',
                    maxWidth: '450px',
                    borderColor: "#212121",
                    zIndex: 1000
                }}
            >
                <h2 className="text-white mb-4 fs-3">Reset Password</h2>
                <div className="w-100">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label 
                                htmlFor="id_username" 
                                className="form-label text-white mb-2"
                            >
                                New Password
                            </label>
                            <SitePieces.ZenithInput 
                                type="password"
                                id="id_password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter new password"
                                className="w-100"
                            />
                        </div>

                        <div className="mb-4">
                            <label 
                                htmlFor="id_email" 
                                className="form-label text-white mb-2"
                            >
                                Confirm New Password:
                            </label>
                            <SitePieces.ZenithInput 
                                type="password"
                                id="id_password2"
                                name="password2"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm New Password"
                                className="w-100"
                            />
                        </div>

                        {message && (
                            <div 
                                className="text-center my-3 border py-3 px-3 rounded"
                                style={{
                                    color: "var(--negative-c)",
                                    borderColor: "var(--secondary)"
                                }}
                            >
                                <p className="m-0 small">{message}</p>
                            </div>
                        )}

                        <Button2 
                            onClick={handleSubmit} 
                            fullWidth={true} 
                            disabled={loading}
                            className="mt-4"
                        >
                            {loading ? <LoadingSpinnerMini /> : "Reset Password"}
                        </Button2>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
