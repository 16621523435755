import React from "react";

const FigrIconWrapper2 = ({children, title}) => {
    return (
        <div className="figr-icon2-wrapper">
            <div className="figr-icon2" title={title}>
                {children}
            </div>
        </div>
    )
}

export default FigrIconWrapper2