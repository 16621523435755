import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import constants from '../constants';
import ajaxRequestFuncs from './requestFuncs/ajaxRequestFuncs';

// not sensitive
const stripePromise = loadStripe(process.env.REACT_APP_STRIKE_PUBLISHABLE_KEY);

const handleCheckout = async () => {
    const stripe = await stripePromise;

    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken'); // Assuming getCookie function exists

    try {
        const response = await axios.post(constants.BACKEND_URL+'/create-checkout-session/', {}, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
            withCredentials: true,
          });

        const { sessionId } = response.data;
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) console.error('Stripe Checkout Error:', error);

    } catch (err) {
        console.error('API Request Error:', err);
    }
};

export default handleCheckout