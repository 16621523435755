import ReactDOM from "react-dom";
import React from 'react';
import prepareWindow from "../../windowCreating/prepareWindow";
import GroupAnalysisByTime from "../../../components/FeatureComponents/8_GroupAnalysisByTime/8_GroupAnalysisByTime";

async function handleGroupTime(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-group-time';
    const TITLE_PREFIX = 'Group Analysis By Time ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<GroupAnalysisByTime symbol={symbol} windowID={window.id}/>, destinationElement)
}

export default handleGroupTime 


