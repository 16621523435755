import prepareWindow from "../../windowCreating/prepareWindow";
import React from "react";
import ReactDOM from "react-dom";
import EconomicSeries from "../../../components/FeatureComponents/27_EconomicSeries/27_EconomicSeries";

async function handleEconomicSeries(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-economic-series';
    const TITLE_PREFIX = 'Economic Series ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<EconomicSeries symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleEconomicSeries 