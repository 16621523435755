

import React, { useState, useEffect } from "react";
import WindowPieces from "../../ui/small/WindowPieces";

const MarketScannerAccordion = ({data, checkedArray = [], handleCheckboxChange }) => {
  const [openSections, setOpenSections] = useState({});
  const [checkedSecurities, setCheckedSecurities] = useState({});


  // Initialize checked securities from the checkedArray prop
  useEffect(() => {
    const initialCheckedSecurities = {};
    checkedArray.forEach((ticker) => {
      initialCheckedSecurities[ticker] = true;
    });
    setCheckedSecurities(initialCheckedSecurities);
  }, [checkedArray]);

  // Group data by category and group
  const groupedData = data.reduce((acc, item) => {
    const { category, group } = item;
    acc[category] = acc[category] || {};
    acc[category][group] = acc[category][group] || [];
    acc[category][group].push(item);
    return acc;
  }, {});

  // Toggle accordion sections
  const toggleSection = (header) => {
    setOpenSections((prev) => ({
      ...prev,
      [header]: !prev[header],
    }));
  };

  // Handle checking a group and all its securities
  const handleGroupCheck = (group, securities) => {
    const allChecked = securities.every((sec) => checkedSecurities[sec.symbol]);
    const updatedCheckedSecurities = { ...checkedSecurities };

    // Toggle check for the group and its securities
    securities.forEach((security) => {
      updatedCheckedSecurities[security.symbol] = !allChecked;
      handleCheckboxChange(!allChecked, security.symbol);
    });

    setCheckedSecurities(updatedCheckedSecurities);
  };

  // Handle checking individual securities
  const handleSecurityCheck = (security) => {
    const isChecked = checkedSecurities[security] || false;
    setCheckedSecurities((prev) => ({
      ...prev,
      [security]: !isChecked,
    }));

    // Update parent state via handleCheckboxChange
    handleCheckboxChange(!isChecked, security);
  };

  return (
    <>
        <div className="accordion p-0 h-100 overflow-y-auto">
          {Object.entries(groupedData).map(([category, groups]) => (
            <div key={category} className="accordion-section">
              {/* Accordion Header */}
              <div
                className="accordion-header"
                onClick={() => toggleSection(category)}
                style={{
                  backgroundColor: "var(--secondary)",
                  padding: "1rem",
                  cursor: "pointer",
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "var(--text)"
                }}
              >
                {category}
                <span style={{ fontSize: "1.2rem" }}>
                  {openSections[category] ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                  )}
                </span>
              </div>
              {/* Accordion Body */}
              {openSections[category] && (
                <div
                  className="accordion-body"
                  style={{
                    padding: 0,
                    backgroundColor: "var(--theme)",
                    borderRadius: "0px",
                    marginBottom: "0.5rem",
                  }}
                >
                  {Object.entries(groups).map(([group, securities]) => (
                    <div
                      key={group}
                      className="accordion-item"
                      style={{
                        border: "0.5px solid var(--secondary)",
                        backgroundColor: "var(--theme)",
                        borderRadius: "0px",
                        padding: "0.5rem",
                      }}
                    >
                      {/* Group Header with Checkbox */}
                      <div
                        style={{
                          color: "var(--text)",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <input
                                style={{
                                    marginRight: "0.5rem",
                                    height: "10px",
                                    width: "10px",
                                    outline: "none",
                                    borderColor: "var(--secondary)",
                                    backgroundColor: "var(--theme)"
                                }}
                                className="form-check-input rounded-0 border"
                                type="checkbox"
                                checked={securities.every((sec) => checkedSecurities[sec.symbol])}
                                onChange={() => handleGroupCheck(group, securities)}
                            />
                        {group}
                      </div>
                      {/* Securities List */}
                      <div style={{ paddingLeft: "1.5rem" }}>
                        {securities.map((security) => (
                          <div title={security.securityName} style={{color: "var(--text)", fontSize: "0.9rem"}} key={security.symbol}>

                            <input
                              style={{
                                  marginRight: "0.5rem",
                                  height: "10px",
                                  width: "10px",
                                  outline: "none",
                                  borderColor: "var(--secondary)",
                                  backgroundColor: "var(--theme)"
                              }}
                              className="form-check-input rounded-0 border"
                              type="checkbox"
                              checked={checkedSecurities[security.symbol] || false}
                              onChange={() => handleSecurityCheck(security.symbol)}
                            />
                            {security.name} ({security.symbol})

                            {/* <input
                              type="checkbox"
                              checked={checkedSecurities[security.symbol] || false}
                              onChange={() => handleSecurityCheck(security.symbol)}
                              style={{ marginRight: "0.5rem" }}
                            /> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
    
    </>
  );
};

export default MarketScannerAccordion;
