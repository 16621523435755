import React from "react";
import FormattingComponents from "../../formatting/FormattingComponents";
import constants from "../../../constants";
import getRootColorString from "../../../functions/helpers/getRootColorString";

const PerformanceBlock = ({label, performancevalue}) => {
    const color = performancevalue > 0? getRootColorString("--positive"): (performancevalue < 0? getRootColorString("--negative"): '#ffffff')
    return (
        <div className="row p-1 my-1 p-2" style={{backgroundColor: `${color}50`}}>
            <div className="col-6">{label}</div>
            <div className="col-6 fw-bold" style={{color: color}}>{`${performancevalue.toFixed(2)}%`}</div>
        </div>
    )
}

export default PerformanceBlock