let currentLayout = [];

export function setGlobalLayout(newLayout) {
  currentLayout = newLayout;
}

export function getGlobalLayout() {
  
  return currentLayout;
}


export function updateLayoutItem(windowID, updates) {
  const targetTabIndex = currentLayout.findIndex(tab => 
    tab.layout.some(window => window.windowID === windowID)
  );
  if (targetTabIndex === -1) {
    console.warn(`Window ID: <${windowID}> Not Found Due to Parent Tab not being Found - No Updates to Layout Made`);
    return;
  }
  const targetWindowIndex = currentLayout[targetTabIndex].layout.findIndex(window=>
    window.windowID === windowID
  )
  if (targetWindowIndex === -1) {
    console.warn(`Window ID: <${windowID}> Not Found - No Updates to Layout Made`)
  }

  const newWindowLayout = { ...currentLayout[targetTabIndex].layout[targetWindowIndex], ...updates };
  currentLayout[targetTabIndex].layout[targetWindowIndex] = newWindowLayout
 
}

