
import React, { useState, useMemo } from 'react';
import constants from '../../../constants';
import RemoveButton from '../../ui/small/buttons/RemoveButton';
import ZenithTable1 from '../../ui/small/tables/ZenithTable1';
import fundamentals from "../../../catalogs/fundamentals"; // Assuming this contains your isDollar logic
import getRootColorString from '../../../functions/helpers/getRootColorString';


const GroupAnalysisTimeTable = ({ data, mappedSymbolNames, onRowClick, plottedSymbols, params }) => {
    const [hoveredSymbol, setHoveredSymbol] = useState(null);
    const positiveColor = getRootColorString("--positive")
    const negativeColor = getRootColorString("--negative")

    const companyNames = Object.keys(data);
    const numberOfColumns = data[companyNames[0]]?.length || 0;

    const calculateMean = (arr) => arr.reduce((acc, val) => acc + val, 0) / arr.length;

    const calculateMedian = (arr) => {
        const sorted = [...arr].sort((a, b) => a - b);
        const mid = Math.floor(sorted.length / 2);
        return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
    };

    const calculateMax = (arr) => Math.max(...arr);
    const calculateMin = (arr) => Math.min(...arr);

    const columns = useMemo(() => (
        Array.from({ length: numberOfColumns }, (_, colIndex) =>
            companyNames.map((company) => data[company][colIndex])
        )
    ), [data, numberOfColumns, companyNames]);

    // Calculate statistics for each column
    const means = columns.map(calculateMean);
    const medians = columns.map(calculateMedian);
    const maxValues = columns.map(calculateMax);
    const minValues = columns.map(calculateMin);

    const backendToFrontendName = (backendName) => {
        return fundamentals.find((item) => backendName === item.fmpName)?.name || backendName;
    };

    // Function to check if a value is in dollars
    const isDollarValue = (metric) => {
        return fundamentals.find(fundamental => fundamental.fmpName === metric)?.isDollar;
    };

    const getCellStyle = (value, columnIndex) => {
        if (value === maxValues[columnIndex]) {
            return { color: `${positiveColor}` }; // Green for max
        } else if (value === minValues[columnIndex]) {
            return { color: `${negativeColor}` }; // Red for min
        }
        return {};
    };

    // Function to format values with consideration for dollar metrics
    const formatValue = (value, isDollar) => {
        if (isDollar) {
            return (value / 1000000).toFixed(2).toLocaleString(); // Round to millions
        }
        return value.toFixed(2).toLocaleString(); // Regular formatting
    };

    const colorPallete = constants.getColorPallete()

    return (
        <div className='overflow-y-auto overwflow-x-auto'>
            <ZenithTable1.Table
                tablehead={
                    <tr>
                        <th>Company</th>
                        {Array.from({ length: numberOfColumns }, (_, i) => (
                            <th key={i}>{params.periodType == 'annual'? 'Year': 'Quarter'} n-{numberOfColumns - i}</th>
                        ))}
                    </tr>
                }
                tablebody={
                    <>
                        {companyNames.map((company) => (
                            <tr key={company} onClick={() => onRowClick(company, data[company])} 
                            onMouseEnter={() => setHoveredSymbol(company)}
                            onMouseLeave={() => setHoveredSymbol(null)}>
                                <td
                                    
                                    style={{ position: 'relative' }}
                                    className='ps-0'
                                >
                                    {plottedSymbols.includes(company.toUpperCase()) && (
                                        <div style={{position: 'absolute', top: 0, height: '100%', width: '5px', backgroundColor: colorPallete[plottedSymbols.indexOf(company.toUpperCase()) % colorPallete.length]}}></div>
                                    )}
                                    {hoveredSymbol===company && (
                                        <div 
                                        className="d-flex flex-row" 
                                        style={{
                                            position: 'absolute',
                                            top: "50%",
                                            right: "8px",
                                            transform: "translateY(-50%)"  // Center vertically
                                        }}
                                        >
                                            <div className="mx-2" title="Plot">
                                                <svg style={{height: '12px', width: '12px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                                                    <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"/>
                                                </svg>
                                            </div>
                                        </div>

                                    )}
                                    <div className='ms-3'>{company.toUpperCase()} - {mappedSymbolNames[company.toUpperCase()]? mappedSymbolNames[company.toUpperCase()]: ""}</div>
    
    
                                </td>
                                {data[company].map((value, i) => {
                                    const metric = `metric_${i}`; // Assuming the column corresponds to a specific metric
                                    const isDollar = isDollarValue(params.metric);
                                    return (
                                        <td
                                            className='p-0 text-end'
                                            key={i}
                                            style={getCellStyle(value, i)} // Apply dynamic styling
                                        >
                                            {formatValue(value, isDollar)} {/* Apply formatting */}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                        <tr style={{ borderTop: "1px solid var(--secondary)" }} onClick={() => onRowClick("Mean", means)}>
                            <td className='ps-0 position-relative'>
                                {plottedSymbols.includes("MEAN") && (
                                        <div style={{position: 'absolute', top: 0, height: '100%', width: '5px', backgroundColor: colorPallete[plottedSymbols.indexOf("MEAN") % colorPallete.length]}}></div>
                                )}
                                <strong className='ms-3'>Mean</strong>
                            </td>
                            {means.map((mean, i) => {
                                const isDollar = isDollarValue(params.metric); // Assuming the column corresponds to a specific metric
                                return (
                                    <td key={i} className="text-end">
                                        {formatValue(mean, isDollar)} {/* Apply formatting */}
                                    </td>
                                );
                            })}
                        </tr>
    
    
                        <tr onClick={() => onRowClick("Median", medians)}>
                            <td className='ps-0 position-relative'>
                                {plottedSymbols.includes("MEDIAN") && (
                                        <div style={{position: 'absolute', top: 0, height: '100%', width: '5px', backgroundColor: colorPallete[plottedSymbols.indexOf("MEDIAN") % colorPallete.length]}}></div>
                                )}
                                <strong className='ms-3'>Median</strong>
                            </td>
                            {medians.map((median, i) => {
                                const isDollar = isDollarValue(params.metric);
                                return (
                                    <td key={i} className="text-end">
                                        {formatValue(median, isDollar)} {/* Apply formatting */}
                                    </td>
                                );
                            })}
                        </tr>
    
    
                        <tr onClick={() => onRowClick("Max", maxValues)}>
                            <td className='ps-0 position-relative'>
                                {plottedSymbols.includes("MAX") && (
                                        <div style={{position: 'absolute', top: 0, height: '100%', width: '5px', backgroundColor: colorPallete[plottedSymbols.indexOf("MAX") % colorPallete.length]}}></div>
                                )}
                                <strong className='ms-3'>Max</strong>
                            </td>
                            {maxValues.map((max, i) => {
                                const isDollar = isDollarValue(params.metric);
                                return (
                                    <td key={i} className="text-end">
                                        {formatValue(max, isDollar)} {/* Apply formatting */}
                                    </td>
                                );
                            })}
                        </tr>
    
    
                        <tr style={{ borderBottom: "1px solid var(--secondary)" }} onClick={() => onRowClick("Min", minValues)}>
                            <td className='ps-0 position-relative'>
                                {plottedSymbols.includes("MIN") && (
                                        <div style={{position: 'absolute', top: 0, height: '100%', width: '5px', backgroundColor: colorPallete[plottedSymbols.indexOf("MIN") % colorPallete.length]}}></div>
                                )}
                                <strong className='ms-3'>Min</strong>
                            </td>
                            {minValues.map((min, i) => {
                                const isDollar = isDollarValue(params.metric);
                                return (
                                    <td key={i} className="text-end">
                                        {formatValue(min, isDollar)} {/* Apply formatting */}
                                    </td>
                                );
                            })}
                        </tr>
                    </>
                }
            />
        </div>
    );
};

export default GroupAnalysisTimeTable;
