// import React from "react";
// import CustomModal2 from "../CustomModal2";

// const ChooseColorModal = ({show, setShow, onColorChoice}) => {
//     const baseColors = {
//         red: '#FF0000',
//         orange: '#FF7A00',
//         yellow: '#FFD700',
//         green: '#00B050',
//         blue: '#0070C0',
//         purple: '#7030A0',
//         pink: '#FF1493',
//         gray: '#808080'
//     };

//     const generateShades = (hexColor) => {
//         const rgb = parseInt(hexColor.slice(1), 16);
//         const r = (rgb >> 16) & 0xff;
//         const g = (rgb >> 8) & 0xff;
//         const b = rgb & 0xff;

//         return [
//             0.2, 0.4, 0.6, 0.8, 1
//         ].map(opacity => {
//             const newR = Math.round(r * opacity);
//             const newG = Math.round(g * opacity);
//             const newB = Math.round(b * opacity);
//             return `#${(newR << 16 | newG << 8 | newB).toString(16).padStart(6, '0')}`;
//         });
//     };

//     const colorGroups = Object.values(baseColors).map(color => {
//         return [color, ...generateShades(color)].reverse();
//     });

//     return (
//         <CustomModal2 
//             show={show}
//             title="Set Color"
//             body={
//                 <div className="p-2">
//                     {colorGroups.map((shades, groupIndex) => (
//                         <div key={groupIndex} className="d-flex mb-2">
//                             {shades.map((color, index) => (
//                                 <div
//                                     key={`${groupIndex}-${index}`}
//                                     onClick={() => {
//                                         onColorChoice(color);
//                                         setShow(false);
//                                     }}
//                                     className="m-1 cursor-pointer"
//                                     style={{
//                                         height: '25px',
//                                         width: '25px',
//                                         backgroundColor: color,
//                                         border: '1px solid var(--secondary)',
//                                         borderRadius: '0px'
//                                     }}
//                                     title={color}
//                                 />
//                             ))}
//                         </div>
//                     ))}
//                 </div>
//             }
//             onClose={() => setShow(false)}
//         />
//     );
// };

// export default ChooseColorModal;

import React from "react";
import CustomModal2 from "../CustomModal2";

const ChooseColorModal = ({show, setShow, onColorChoice}) => {
    const baseColors = [
        '#FF0000', // Red
        '#FF7A00', // Orange
        '#FFD700', // Yellow
        '#00B050', // Green
        '#0070C0', // Blue
        '#7030A0', // Purple
        '#FF1493', // Pink
        '#808080', // Gray
    ];

    const generateShades = (hexColor) => {
        const rgb = parseInt(hexColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = rgb & 0xff;

        const darkerShades = [0.3, 0.5, 0.7].map(opacity => {
            const newR = Math.round(r * opacity);
            const newG = Math.round(g * opacity);
            const newB = Math.round(b * opacity);
            return `#${(newR << 16 | newG << 8 | newB).toString(16).padStart(6, '0')}`;
        });

        const lighterShades = [0.3, 0.5, 0.7].map(opacity => {
            const newR = Math.round(r + (255 - r) * opacity);
            const newG = Math.round(g + (255 - g) * opacity);
            const newB = Math.round(b + (255 - b) * opacity);
            return `#${(newR << 16 | newG << 8 | newB).toString(16).padStart(6, '0')}`;
        });

        return [...darkerShades.reverse(), hexColor, ...lighterShades];
    };

    const shadeRows = Array(7).fill(null).map((_, rowIndex) => (
        <div key={rowIndex} className="d-flex">
            {baseColors.map((baseColor, colIndex) => {
                const shades = generateShades(baseColor);
                return (
                    <div
                        key={`${rowIndex}-${colIndex}`}
                        onClick={() => {
                            onColorChoice(shades[rowIndex]);
                            setShow(false);
                        }}
                        className="m-1 "
                        style={{
                            cursor: "pointer",
                            height: '25px',
                            width: '25px',
                            backgroundColor: shades[rowIndex],
                            border: '1px solid var(--secondary)'
                        }}
                        title={shades[rowIndex]}
                    />
                );
            })}
        </div>
    ));

    return (
        <CustomModal2 
            show={show}
            title="Set Color"
            body={
                <div className="p-2">
                    {shadeRows}
                </div>
            }
            onClose={() => setShow(false)}
        />
    );
};

export default ChooseColorModal;