import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import initEventListeners from '../functions/initEventListeners';
import { setGridLayoutRef } from '../functions/gridLayoutManager';
import ResizableDraggableContainer from '../components/ZenithGridLayout';
import AutosuggestCommandLine from '../components/autosuggest/searchbars/AutosuggestCommandLine_v3';
import handleHelp from '../functions/requestFuncs/handling/handleHelp';
import LoginModal from '../components/modals/CustomModals2/LoginModal';
import RegisterModal from '../components/modals/CustomModals2/RegisterModal';
import SettingsModal from '../components/modals/CustomModals2/SettingsModal';
import AccountModal from '../components/modals/CustomModals2/AccountModal';
import IntroductionModal from '../components/modals/CustomModals2/IntroductionModal';
import ChooseColorModal from '../components/modals/CustomModals2/ChooseColorModal';
import SecurityFunctionModal from '../components/modals/CustomModals2/SecurityFunctionsModal';
import Button1 from '../components/ui/small/buttons/Button1';
import ToggleButton1 from '../components/ui/small/buttons/ToggleButton1';
import dashboardLayout from '../functions/memoryManagement/dashboardLayout';
import { getGlobalLayout, setGlobalLayout } from '../functions/memoryManagement/layoutManager';
import TabToggleButton from '../components/ui/small/buttons/TabToggleButton';
import RenameTabModal from '../components/modals/CustomModals2/RenameTabModal';
import AddButton from '../components/ui/small/buttons/AddButton';
import handleCheckout from '../functions/handleCheckout';
import UserPlanBadge from '../components/misc/UserPlanBadge';
import Button2 from '../components/ui/small/buttons/Button2';
import constants from '../constants';
import UserSummary from '../components/misc/UserSummary';
import getRootColorString from '../functions/helpers/getRootColorString';
import updateRootColor from '../functions/helpers/updateRootColor';
import requestFullScreenMode from '../functions/helpers/requestFullScreenMode';

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../css/app/main.min.css';
import '../css/app/index.css';
import '../css/app/window.css';
import '../css/app/table.css';
import '../css/app/theme.css';
import '../css/app/grid.css';
import '../css/app/zenithGridLayout.css'
import "../css/app/colors.css"
import "../css/app/highlighting.css"
import "../css/app/scrollbar.css"
import "../css/app/alert-dropdown.css"
import "../css/app/input-select.css"
import "../css/app/side-nav.css"
import "../css/app/tooltip.css"
import "../css/app/flashing.css"
import "../css/app/buttons.css"
import userPreferences from '../functions/memoryManagement/userPreferences';
import IconToggleButton from '../components/ui/small/buttons/IconToggleButton';
import { showAlert } from '../functions/showAlert';
import CloseWindowButton from '../components/ui/small/buttons/CloseWIndowButton';
import BillingModal from '../components/modals/CustomModals2/BillingModal';
import SideNavWrapper from '../components/ui/small/wrappers/settings/SideNavWrapper';
import LogoutSideNavWrapper from '../components/ui/small/wrappers/settings/LogoutSideNavWrapper';
import ForgotPasswordModal from '../components/modals/CustomModals2/ForgotPassordModal';
import FigrIconWrapper from '../components/ui/small/FigrIconWrapper';
import exitFullScreenMode from '../functions/helpers/exitFullScreenMode';
import UpgradeToPremiumButton from '../components/ui/small/buttons/UpgradeToPremiumButton';
import showPremiumPrompt from '../functions/showPremiumPrompt';
import checkPermissions from '../functions/helpers/checkPermissions';
import ScreenSizeWarning from '../components/modals/ScreenSizeWarningModal';
import axios from 'axios';

async function loadLayout() {
  try {
    const loadedLayout = await dashboardLayout.getAndLoadLayout();
    const layoutData = loadedLayout.layout_data
    if (layoutData) {
      return layoutData;
    } else {
      return constants.DEFAULT_LAYOUT
    }
  } catch (error) {
    console.error("Failed to load layout:", error);
    return constants.DEFAULT_LAYOUT;
  }
}

const loadedLayout = await loadLayout();
setGlobalLayout(loadedLayout);

async function loadUserPreferences() {
  try {
    const loadedUserPreferences = await userPreferences.getUserPreferences();
    const preferences = loadedUserPreferences.preferences
    if (preferences) {
      Object.entries(preferences).forEach(([key, value]) => {
        updateRootColor(key, value);
      });
    } else {
    }
  } catch (error) {
    console.error("Failed to load user preferences: ", error)
  }
}
loadUserPreferences()



function Terminal() {


  ///////////////////////////////////////////////////////////////////////// STATE MANAGEMENT
  const [showSideNav, setShowSideNav] = useState(false);
  const [isMaximizedScreen, setIsMaximizedScreen] = useState(false);

  const [showFunctionMenu, setShowFunctionMenu] = useState(false);
  const [functionMenuSymbol, setFunctionMenuSymbol] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showScreensizeWarning, setShowScreensizeWarning] = useState(true);
  const [showColorModal, setShowColorModal] = useState(false);
  const [showRenameTabModal, setShowRenameTabModal] = useState(false);
  const [showIntroductionModal, setShowIntroductionModal] = useState(false); 
  const [isGridLayoutRefSet, setIsGridLayoutRefSet] = useState(false);

  // Tabs & Layout
  const [tabs, setTabs] = useState(
    loadedLayout.map((item) => ({ id: item.tabID, name: item.tabName, color: item.tabColor })) || []
  );
  const [activeTab, setActiveTab] = useState(
    loadedLayout && loadedLayout.length > 0 ? loadedLayout[0].tabID : null
  );
  const [layout, setLayout] = useState(loadedLayout || [])

  // User State
  const [user, setUser] = useState(null);
  const [isUserCheckComplete, setIsUserCheckComplete] = useState(false);

  ///////////////////////////////////////////////////////////////////////// REFS
  // Refs
  const zenithGridLayout = useRef(null);
  const tabRefs = useRef({}); // Manage tab refs here

  ///////////////////////////////////////////////////////////////////////// FUNCTIONS

  initEventListeners();

  async function addTab() {
    const n = tabs.length > 0 ? tabs.length + 1 : 1;
    if (n > 1) {
      const hasAccessMultiDashboard = await checkPermissions("multiDashboard")
      if (!hasAccessMultiDashboard) {
        showPremiumPrompt("Your current plan includes access to a single dashboard. Upgrade now to unlock multiple dashboards and elevate your financial analysis and insights!")
        return;
      }
    }

    // const newId = tabs.length > 0 ? tabs[tabs.length - 1].id + 1 : 1;
    const time = Date.now()
    const newId = `tab-${time}`
    const newTabName = `Tab ${n}`
    const newTab = { id: newId, name:  newTabName, color: 'var(--primary)'};
    setTabs([...tabs, newTab]);
    setActiveTab(newId);
    
    tabRefs.current[newId] = React.createRef(); 

    setLayout((prevLayout)=>{
      const newTabStructure = {
        tabID: newId,
        tabName: newTabName,
        tabColor: getRootColorString('--primary'),
        layout: []
      }
      const updatedLayout = [...prevLayout, newTabStructure]
      setGlobalLayout(updatedLayout)
      dashboardLayout.saveLayout(updatedLayout)
      return updatedLayout
    })
  };

  const removeTab = (id) => {
    // Prevent deletion if there's only one tab left
    if (tabs.length === 1) {
      showAlert("error", "Cannot delete the last tab.")
      return;
    }
  
    const updatedTabs = tabs.filter((tab) => tab.id !== id);
    setTabs(updatedTabs);

    setLayout((prevLayout) => {
      const updatedLayout = prevLayout.filter((layout) => layout.tabID !== id);

      setGlobalLayout(updatedLayout);
      dashboardLayout.saveLayout(updatedLayout); 
  
      return updatedLayout; 
    });

    delete tabRefs.current[id]; 
  
    if (id === activeTab && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[0].id); 
    } else if (updatedTabs.length === 0) {
      // This won't happen since user cannot delete the last tab.
      setActiveTab(null); 
    }
  };

  const changeColor = (newColor)=> {
    setLayout((prevLayout)=>{
      const targetTabLayout = prevLayout.find(layout=>layout.tabID===activeTab)
      targetTabLayout.tabColor = newColor

      setGlobalLayout(prevLayout)
      dashboardLayout.saveLayout(prevLayout)

      const updatedTabs = prevLayout.map((item) => ({ id: item.tabID, name: item.tabName, color: item.tabColor }))
      setTabs(updatedTabs)

      return prevLayout
    })
  }

  const renameTab = (newName)=>{
    setLayout((prevLayout)=>{
      const targetTabLayout = prevLayout.find(layout=>layout.tabID===activeTab)
      targetTabLayout.tabName = newName

      setGlobalLayout(prevLayout)
      dashboardLayout.saveLayout(prevLayout)

      const updatedTabs = prevLayout.map((item) => ({ id: item.tabID, name: item.tabName, color: item.tabColor }))
      setTabs(updatedTabs)

      return prevLayout
    })

  }

  // const saveLayout = (tabID, newTabLayout) => {
  //   setLayout((prevLayout)=>{
  //     const globalLayout = getGlobalLayout()
  //     const targetTabLayout = prevLayout.find(layout=>layout.tabID===tabID)
  //     targetTabLayout.layout = newTabLayout

  //     setGlobalLayout(prevLayout)
  //     dashboardLayout.saveLayout(prevLayout)

  //     return prevLayout
  //   })

  // }
  const saveLayout = (tabID, newTabLayout) => {
    setLayout((prevLayout) => {
        const updatedLayout = prevLayout.map(layout => 
            layout.tabID === tabID 
                ? { ...layout, layout: newTabLayout }
                : layout
        );
        
        setGlobalLayout(updatedLayout);
        dashboardLayout.saveLayout(updatedLayout);
        
        return updatedLayout;
    });
  }

  const activeTabObject = tabs.find((tab) => tab.id === activeTab);

  const toggleFullScreenMode = () => {
    if (isMaximizedScreen) {
      exitFullScreenMode();
    } else {
      requestFullScreenMode();
    }
  };
  const handleFullscreenChange = () => {
    setIsMaximizedScreen(!!document.fullscreenElement); // `fullscreenElement` is null if not in full-screen mode
  };



  useEffect(() => {
    // Add an event listener for full-screen changes
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    axios.get(constants.BACKEND_URL+'/user-info/', {
      withCredentials: true
    })
      .then((response) => {
        setUser(response.data);
        setIsUserCheckComplete(true);
        setShowIntroductionModal(!response.data); // Show modal only if no user data
        showAlert('success', `Hello ${response.data.username}`)
      })
      .catch((error) => {
        setUser(null);
        setIsUserCheckComplete(true);
        setShowIntroductionModal(true);
      });
  }, []);
  


  const ensureGridLayoutRef = (tabId, maxAttempts = 5) => {
    let attempts = 0;
    
    const trySetRef = () => {
      if (attempts >= maxAttempts) {
        console.error(`Failed to set grid layout ref for tab ${tabId} after ${maxAttempts} attempts`);
        showAlert('error', 'Error initializing dashboard. Please refresh the page.');
        return;
      }
  
      const currentRef = tabRefs.current[tabId]?.current;
      if (currentRef) {
        setGridLayoutRef(currentRef);
        setIsGridLayoutRefSet(true);
      } else {
        attempts++;
        setTimeout(trySetRef, 1000); // Retry after 200ms
      }
    };
  
    trySetRef();
  };



  useEffect(() => {
    // Initialize tabRefs from loadedLayout
    loadedLayout.forEach((item) => {
      if (!tabRefs.current[item.tabID]) {
        tabRefs.current[item.tabID] = React.createRef();
      }
    });
  
    // Handle initial tab setup after refs are initialized
    if (tabs.length > 0) {
      if (!tabRefs.current[tabs[0].id]) {
        tabRefs.current[tabs[0].id] = React.createRef();
      }
      ensureGridLayoutRef(tabs[0].id);
      // setTimeout(() => {
      //   setGridLayoutRef(tabRefs.current[tabs[0].id]?.current);
      // }, 1000);
    } else {
      addTab();
    }
  }, []); 
  
  useEffect(() => {
    if (activeTabObject && activeTabObject.id) {
      ensureGridLayoutRef(activeTabObject.id);
    }
  }, [activeTab, activeTabObject]);


  const HEADER_HEIGHT = '45px';
  const FOOTER_HEIGHT = '45px';

  return (
    <>
      {user && (
        <div id="side-nav" style={{top: HEADER_HEIGHT, borderLeft: "1px solid var(--secondary)"}} className={showSideNav ? "open" : ""}>
          <div id="side-nav-content"  className='h-100'>
            <UserSummary userObject={user} />
            <hr></hr>
            <div className='d-flex flex-column justify-content-between'>
              <div>
                <SideNavWrapper 
                  label={"Account"}
                  iconSVG={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-vcard-fill" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8m1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96q.04-.245.04-.5M7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0"/>
                    </svg>
                  }
                  onClick={() => {setShowSideNav(false); setShowAccountModal(true)}}
                />
                <SideNavWrapper 
                  label={"Preferences"}
                  iconSVG={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sliders" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1z"/>
                    </svg>
                  }
                  onClick={() => {setShowSideNav(false); setShowSettingModal(true)}}
                />
                <SideNavWrapper 
                  label={"Billing"}
                  iconSVG={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                      <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                    </svg>
                  }
                  onClick={() => {setShowSideNav(false); setShowBillingModal(true)}}
                />
              </div>
              <hr></hr>
              <LogoutSideNavWrapper 
                  label={"Logout"}
                  iconSVG={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                      <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                    </svg>
                  }
                />
            </div>
          </div>
        </div>
      )}

      <div style={{ paddingBottom: '0px', backgroundColor: "var(--negative)" }} className="position-fixed h-100 w-100">
        {/* Header */}
        <div style={{ height: HEADER_HEIGHT, backgroundColor: "var(--theme)", borderBottom: "1px solid var(--secondary)" }} className="position-absolute top-0 w-100">
          <nav
            style={{backgroundColor: "var(--theme)", height: "100%"}}
            className="position-absolute top-0 w-100 navbar navbar-expand-xxl py-0"
          >
            <div className="terminal-header container-fluid p-1 px-2">
              <div className='d-flex justify-content-between w-100'>
                <div className='d-flex'>
                  <AutosuggestCommandLine
                    id="command-line-main"
                    forTerminal={true}
                    setShowFunctionMenu={setShowFunctionMenu}
                    setFunctionMenuSymbol={setFunctionMenuSymbol}
                  />
                  
                  <div className='d-flex align-items-center'>
                    <div className='mx-1'>
                      <AddButton onClick={addTab}>+ Add Tab</AddButton>
                    </div>
                    {tabs.map((tab, index) => (
                      <TabToggleButton 
                        // key={tab.id}
                        key={index}
                        isActive={tab.id===activeTab}
                        color={tab.color}
                        onTabChange={
                          ()=>{
                            setActiveTab(tab.id);
                            setGridLayoutRef(tabRefs.current[tab.id].current)                    
                        }
                        }
                        onTabRemove={
                          ()=>removeTab(tab.id)
                        }
                        onChooseColor={
                          ()=>setShowColorModal(true)
                        }
                        onTabRename={
                          ()=>setShowRenameTabModal(true)
                        }
                        label={tab.name}
                        
                      />


                    ))}
                  </div>
                </div>
                {user? (
              
                    <div className='d-flex align-items-center'>
                      {(user && user.group === 'level1_user') && (
                        <div className='m-2'> 
                          <UpgradeToPremiumButton label={"Upgrade to Premium!"} onClick={()=>handleCheckout()} />
                        </div>
                      )}
                      {showSideNav ? (
                        <div onClick={()=>setShowSideNav(false)} className='d-flex align-items-center justify-content-center figr-icon'>
                          <FigrIconWrapper>
                            <svg style={{height: "18px", width: "18px"}}   xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                            </svg>
                          </FigrIconWrapper>
                      </div>
                      ) : (
                        <div onClick={()=>setShowSideNav(true)} className='d-flex align-items-center justify-content-center figr-icon'>
                          <FigrIconWrapper>
                            <svg style={{height: "18px", width: "18px"}}  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                              <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                            </svg>
                          </FigrIconWrapper>
                        </div>
                      )}
                    </div>
                
                ) : (
                  <div className='d-flex align-items-center'>

                    <div>
                      <button
                          style={{
                              backgroundColor: "var(--primary)",
                              color: "black",
                          }}
                          type="button"
                          className={`text-nowrap rounded-0 btn`}
                          onClick={()=>setShowLoginModal(true)}
                      >
                          Login
                      </button>
                    </div>
                    <div className='ms-2'>
                      <Button2 onClick={()=>setShowRegisterModal(true)}>Register</Button2>
                    </div>
                  </div>

                )
                }
              </div>
            </div>
          </nav>
        </div>
        
        {/* Main Body - FIGR Grid */}
        <div
          style={{
            marginTop: HEADER_HEIGHT,
            paddingBottom: FOOTER_HEIGHT,
            height: `calc(100% - ${HEADER_HEIGHT})`,
            overflowY: 'auto',
            backgroundColor: "var(--grid)"
          }}
          className="position-absolute w-100 d-flex flex-column"
        >
          {tabs.map((tab, index) => (
       
              <ResizableDraggableContainer key={index} show={tab.id === activeTab} onSaveParentLayout={saveLayout} tabID={tab.id} tabLayout={layout.find(item=>item.tabID===tab.id)?.layout || []} ref={tabRefs.current[tab.id]} />
             
          ))}
        </div>
        
        {/* Footer */}
        <div style={{ height: FOOTER_HEIGHT}} className="position-fixed bottom-0 w-100">
          <footer
            style={{ height: FOOTER_HEIGHT, backgroundColor: "var(--theme)", color: "var(--text)", borderTop: "1px solid var(--secondary)" }}
            className="position-fixed bottom-0 w-100 footer d-flex flex-row flex-wrap justify-content-between align-items-center p-1"
          >
            <p className="m-0 mx-2">© 2025 FIGR Terminal. All Rights Reserved</p>
            <div className="d-flex flex-row align-items-center">
              <div className="mx-2">
                <Button2 onClick={()=> setShowIntroductionModal(true)}>GUIDE</Button2>
              </div>
              <div className="mx-2">
                <Button2 onClick={()=> handleHelp()}>HELP</Button2>
              </div>


              <div className='mx-2' onClick={()=>toggleFullScreenMode()}>
                  {isMaximizedScreen? (
                    <FigrIconWrapper title={"Exit Full Screen"}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen-exit" viewBox="0 0 16 16">
                        <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5m5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5M0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5m10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0z"/>
                      </svg>
                    </FigrIconWrapper>
                  ) : (
                    <FigrIconWrapper title={"Full Screen"}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16">
                        <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5"/>
                      </svg>
                    </FigrIconWrapper>
                  )}
              </div>


            </div>
          </footer>
        </div>


      </div>


      <ScreenSizeWarning show={showScreensizeWarning} closeModal={()=>setShowScreensizeWarning(false)}/>

      {/* Modals */}
      <IntroductionModal show={showIntroductionModal} setShow={setShowIntroductionModal}/>
      <RenameTabModal show={showRenameTabModal} setShow={setShowRenameTabModal} onRenameTab={renameTab}/>
      <ChooseColorModal show={showColorModal} setShow={setShowColorModal} onColorChoice={changeColor}/>
      <SecurityFunctionModal show={showFunctionMenu} setShow={setShowFunctionMenu} functionMenuSymbol={functionMenuSymbol}/>
      <LoginModal show={showLoginModal} setShow={setShowLoginModal} setShowRegister={setShowRegisterModal}  setShowForgotPassword={setShowForgotPasswordModal}/>
      <RegisterModal show={showRegisterModal} setShow={setShowRegisterModal} setShowLogin={setShowLoginModal} />
      <ForgotPasswordModal show={showForgotPasswordModal} setShow={setShowForgotPasswordModal}/>
      <AccountModal user={user} show={showAccountModal} setShow={setShowAccountModal} />
      <SettingsModal show={showSettingModal} setShow={setShowSettingModal} />
      <BillingModal user={user} show={showBillingModal} setShow={setShowBillingModal} />
    </>
  );
}

export default Terminal;
