import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../functions/showAlert';
import constants from "../constants"
import ReactGA from 'react-ga4';

function Success() {
  const navigate = useNavigate();

  function generateTransactionId() {
    // Get the current time in seconds since the Unix epoch
    const epochSeconds = Math.floor(Date.now() / 1000);
  
    // Add a random component to ensure uniqueness across simultaneous calls
    const randomComponent = Math.floor(Math.random() * 100000);
  
    // Combine epochSeconds and randomComponent to create the transaction ID
    return `${epochSeconds}-${randomComponent}`;
  }



  useEffect(() => {
    ReactGA.event({
      category: 'Purchase',
      action: 'Conversion',
      label: 'Thank You Page',
      value: 19.99, // Revenue from the transaction
      nonInteraction: true,
    });

    window.gtag('event', 'conversion', {
      send_to: 'AW-11561015138/Qep7CJeWzIYaEOKu3Igr', // Replace with your Ads conversion ID
      value: 19.99, // Transaction value
      currency: "USD", // Transaction currency
      transaction_id: generateTransactionId(), // Transaction ID
    });

    // if (window.gtag) {
    //   window.gtag('event', 'conversion', {
    //     send_to: 'AW-11561015138/Qep7CJeWzIYaEOKu3Igr', // Replace with your Ads conversion ID
    //     value: 29.99, // Transaction value
    //     currency: "USD", // Transaction currency
    //     transaction_id: generateTransactionId(), // Transaction ID
    //   });
    // }
    
    navigate(constants.REACT_APP_REDIRECT_ENDPOINT);
    showAlert('success', 'Payment Successful. Thank You!')

  }, [navigate]);

  return <div>Payment Successful! Thank you...</div>;
}

export default Success;
