import React, { useEffect} from 'react';  
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Terminal from './pages/Terminal';
import Logout from './pages/Logout';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import ResetPassword from './pages/ResetPassword';
import Home from './pages/landing-page/Home';
import Docs from "./pages/landing-page/Docs"
import DocsFunctions from "./pages/landing-page/DocsFunctions"
import Pricing from "./pages/landing-page/Pricing"
import Blog from "./pages/landing-page/Blog"
import Changelog from "./pages/landing-page/Changelog"

const getRoutes = () => {
  const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT';

  // Common routes used in both environments
  const commonRoutes = [
    <Route key="reset-password" path="/reset-password/:userId/:token/" element={<ResetPassword />} />,
    <Route key="logout" path="/logout/" element={<Logout />} />,
    <Route key="success" path="/success/" element={<Success />} />,
    <Route key="cancel" path="/cancel/" element={<Cancel />} />
  ];

  if (isDevelopment) {
    return [
      // All this will be served statically in the backend on figrterminal.com in production
      <Route key="home" path="/" element={<Home />} />,
      <Route key="terminal" path="/terminal/" element={<Terminal />} />,
      <Route key="docs" path="/docs/" element={<Docs />} />,
      // <Route key="docs-getting-started" path="/docs/getting-started/" element={<Docs />} />,
      // <Route key="docs-functions" path="/docs/functions/" element={<DocsFunctions />} />,

      <Route key="pricing" path="/pricing/" element={<Pricing />} />,
      <Route key="blog" path="/blog/" element={<Blog />} />,
      <Route key="changelog" path="/changelog/" element={<Changelog />} />,
      ...commonRoutes,
      // <Route key="catch-all" path="*" element={<Navigate to="/terminal/" replace />} />
    ];
  } else {
    return [
      // This will be served on app.figrterminal.com aswell as the common routes.
      <Route key="terminal" path="/" element={<Terminal />} />,
      ...commonRoutes,
      <Route key="catch-all" path="*" element={<Navigate to="/" replace />} />
    ];
  }
};


function App() {

  return (
    <Router>
      <Routes>
        {getRoutes()}
      </Routes>
    </Router>
  );
}

export default App;
