import React, {useState} from "react";


const FigrTooltip = ({ children, direction = "right" ,maxWidth="400px" }) => {
    const [visible, setVisible] = useState(false);

    const showTooltip = () => setVisible(true);
    const hideTooltip = () => setVisible(false);

    return (
        <div className="figr-tooltip-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            <div className="figr-icon">
                <svg style={{height: "12px", width: "12px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                </svg>
            </div>
            {visible && (
                <div style={{minWidth: "200px"}} className={`figr-tooltip figr-tooltip-${direction}`}>
                    {children}
                </div>
            )}
        </div>
    );
};


export default FigrTooltip