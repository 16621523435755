import ajaxRequestFuncs from "../requestFuncs/ajaxRequestFuncs";
import axios from "axios";
import constants from "../../constants";

function saveLayout(layout) {
    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
    axios.post(constants.BACKEND_URL+'/save-dashboard-layout/', layout, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,  
        },
        withCredentials: true,  
    })
    .then(response => {
    })
    .catch(error => {
        console.error("An error occurred:", error);  
    });
}

function getAndLoadLayout() {
    return axios.get(constants.BACKEND_URL+'/get-dashboard-layout/', {
        withCredentials: true,
        headers: {
            'X-CSRFToken': ajaxRequestFuncs.getCookie('csrftoken')
        }
    })
    .then(response => {
        return response.data; 
    })
    .catch(error => {
        throw error;
    });
}

export default { saveLayout, getAndLoadLayout }