import React from "react";

const Button2 = ({children, onClick, fullWidth=false, isDisabled=false}) => {
    return (
        <button 
            style={{backgroundColor: "var(--secondary)", color: "var(--text)"}} 
            type="button" 
            className={`text-nowrap rounded-0 btn ${fullWidth? 'w-100': ''}`}
            onClick={onClick}
            disabled={isDisabled}
            >
            {children}
        </button>
    )
}  

export default Button2