import React, {useState, useEffect, useRef} from "react"
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import constants from "../../../constants";
import axios from 'axios';
import FIGRSparkLine from "../../charting/sparklines/FIGRSparkLines";
import getRootColorString from "../../../functions/helpers/getRootColorString";


const WidgetComponent = ({initialData, params}) => {
    const getCloseData = () => {
        return initialData.priceData.map(dataObject => dataObject.close)
    }
    const getLastPrice = () => {
        return initialData.lastPrice
    }
    const getVolume = ()=>{
        return initialData.volume
    }
    const getChange = () => {
        return initialData.change
    }
    const getPercentageChange = ()=>{
        return initialData.percentChange
    }
    const getTimestamp = ()=>{
        return initialData.timestamp
    }



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Data States
    const [priceData, setPriceData] = useState(getCloseData)
    const [lastPrice, setLastPrice] = useState(getLastPrice)
    const [volume, setVolume] = useState(getVolume)
    const [percentageChange, setPercentageChange] = useState(getPercentageChange)
    const [change, setChange] = useState(getChange)
    const [timestamp, setTimestamp] = useState(getTimestamp)
  

    // Streaming 
    const pollingInterval = useRef(null);




    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
 
     useEffect(() => {
        const fetchData =() => {
            constants.api.post(constants.BACKEND_URL+'/widget/', params)
                .then(response => {
                    const responseData = response.data
    
                    const status = responseData.status
                    const data = responseData.data
                
                    if (status === 'success') {
                        setPriceData(data.priceData.map(dataObject => dataObject.close))
                        setLastPrice(data.lastPrice)
                        setVolume(data.volume)
                        setChange(data.change)
                        setPercentageChange(data.percentChange)
                        setTimestamp(data.timestamp)
                    }    

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
            });
        }

        pollingInterval.current = setInterval(fetchData, 60000); 
        
        return () => {
            if (pollingInterval.current) clearInterval(pollingInterval.current);
        };
    }, []);


    const color = change > 0? getRootColorString("--positive"): (change < 0?getRootColorString("--negative"): getRootColorString("--neutral"))
    const invalidItem = (
        <span className="ms-3" title="Error">...</span>
    )
    const formatVolume = (volume) => {
        if (volume >= 1000000000) {
            return `${(volume / 1000000000).toFixed(2)}B`;
        } else if (volume >= 1000000) {
            return `${(volume / 1000000).toFixed(2)}M`;
        }
        return volume.toFixed(0);
    };

    return (
        <div className="d-flex flex-column h-100">
            <div>
                <div className="p-2" style={{color: "var(--text)"}}>
                    <div className="fw-bold"  style={{fontSize: "1.4em"}} title="Last Price">{`$${lastPrice.toFixed(2)}`}</div>
                    <div className="mt-1">
                        <span title="Exchange:Symbol">{initialData.exchange}:{params.symbol.toUpperCase()}</span>
                        {percentageChange? <span className="ms-3" style={{color: color}} title="Price Change (Relative)">{`${percentageChange.toFixed(2)}%`}</span>: invalidItem}
                        {change? <span className="ms-3" style={{color: color}} title="Price Change (Absolute)">{`${change.toFixed(2)}`}</span>: invalidItem}
                        {volume? <span className="ms-3" title="Volume">{formatVolume(volume)}</span>: invalidItem}

                    </div>
                </div>
                
                <div className="d-flex">
                </div>
            </div>
            <div>
                <FIGRSparkLine data={priceData} height={70} width={295} strokeColor={color}/>
            </div>
        </div>
    )
}

export default WidgetComponent