import React from "react"
import FormattingComponents from "../../formatting/FormattingComponents"

const LineWrapperNoFormatting = ({ name, value }) => {
    return (
        <div className="d-flex justify-content-between mt-1">
            <span className="text-nowrap" style={{color: "var(--text)"}}>{name}</span>
            <span style={{color: "var(--text)"}}><strong style={{color: "var(--text)"}}>{value}</strong></span>
        </div>
    )
}

const LineWrapper = ({ name, value }) => {
    return (
        <div className="d-flex justify-content-between mt-1">
            <span className="text-nowrap" style={{color: "var(--text)"}}>{name}</span>
            <span style={{color: "var(--text)"}}><strong style={{color: "var(--text)"}}><FormattingComponents.NumberRounded value={value} numDecimals={2} /></strong></span>
        </div>
    )
}
const LineWrapperCustom = ({ name, value }) => {
    return (
        <div className="d-flex justify-content-between mt-1">
            <span className="text-nowrap" style={{color: "var(--text)"}}>{name}</span>
            <span style={{color: "var(--text)"}}><strong style={{color: "var(--text)"}}>{value}</strong></span>
        </div>
    )
}

const LineWrapperPercent = ({ name, value }) => {
    return (
        <div className="d-flex justify-content-between mt-1">
            <span className="text-nowrap" style={{color: "var(--text)"}}>{name}</span>
            <span style={{color: "var(--text)"}}><strong style={{color: "var(--text)"}}><FormattingComponents.Percentage value={value} numDecimals={2} /></strong></span>
        </div>
    )
}

export default { LineWrapperNoFormatting, LineWrapper, LineWrapperPercent, LineWrapperCustom }