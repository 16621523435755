

import React from "react";
import DropdownWrapper from "../DropdownWrapper";



const TabToggleButton = ({
  label,
  color,
  isActive,
  onTabChange,
  onTabRemove,
  onChooseColor,
  onTabRename
}) => {
  return (
    <div
      className={`btn-group rounded-0 mx-1 text-nowrap`} title={label} style={{background: `${isActive? color: "var(--secondary)"}`}}
    >

      <button style={{color: `${isActive? 'black': color}`}} type="button" className="rounded-0 btn" onClick={onTabChange}>
        {label}
      </button>
      {isActive && (
        <button style={{color: `${isActive? 'black': color}`}} type="button" className="rounded-0 btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
          <span className="visually-hidden">Toggle Dropdown</span>
        </button>
      )}
      <ul
        className="dropdown-menu dropdown-menu-end rounded-0 p-2" style={{backgroundColor: "var(--theme)"}} data-bs-theme="dark">
        <li>
          <DropdownWrapper 
            iconSVG={
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
              </svg>
            } 
            label={"Rename"}
            onClick={onTabRename}
          />
        </li>
        <li>
          <DropdownWrapper 
            iconSVG={
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-palette-fill" viewBox="0 0 16 16">
                <path d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07M8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
              </svg>
            } 
            label={"Set Color"}
            onClick={onChooseColor}
          />
        </li>
        <li>
          <hr className="dropdown-divider"></hr>
        </li>
        <li>
          <DropdownWrapper 
            iconSVG={
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
              </svg>
            } 
            label={"Delete Dashboard"}
            onClick={onTabRemove}
          />
        </li>
      </ul>
    </div>
  )
};

export default TabToggleButton;
