import React, { useEffect } from "react";

const Changelog = ({}) => {
    return (
        // <div className="bg-danger">Hey</div>
        <iframe
            src="/landing-page/changelog.html"
            style={{
                width: "100%",
                height: "100vh",
                border: "none",
            }}
            title="Landing Page"
        />
    );
}

export default Changelog


