import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import showPremiumPrompt from "../../../functions/showPremiumPrompt";

const InsiderTrading = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'page': 1,
    };
    const ENDPOINT = '/insider-trading/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);

  
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            constants.api.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const responseData = response.data
                    const status = responseData.status
                    const data = responseData.data

                    if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                        setError(responseData.message)
                        showPremiumPrompt(responseData.message)
                    } else if (status === "success") {
                        setData(data)
                    }

                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load data.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };


    useEffect(() => {
        requestData();  // Initial request
    }, [params.page]);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const changePage = (pageVal) => {
        if (pageVal > 0 && pageVal <= 100) {
            setParams((prevParams) => ({
                ...prevParams,
                page: pageVal
            }));
        }
    };

    const handlePageInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            changePage(value);
        }
    };

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    
    const unavailable = <td style={{color: "var(--negative)"}}></td>

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                    id={'symbol'}
                    ref={autoSuggestRef}
                    defaultValue={params.symbol}
                    handleSubmitCustom={requestData}
                />
                <WindowPieces.ConfigContainer>
                    <LoadButton onClick={requestData}>LOAD</LoadButton>
                </WindowPieces.ConfigContainer>
            </WindowPieces.OptionsContainer>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} /> 
            ) : (
                <>
                    

                    <WindowPieces.Content>
                        <p className='text-primary fw-light px-2 py-1'>Viewing page {params.page}</p>
                        <ZenithTable1.Table 
                            tablehead={
                                <tr>
                                    <th>Transaction Type</th>
                                    <th>Transaction Date</th>
                                    <th>Filing Date</th>
                                    <th>Securities Transacted</th>
                                    <th>Price</th>
                                    <th>Security Name</th>
                                    <th>Reporting Name</th>
                                    <th>Type of Owner</th>
                                    <th>Filing URL</th>
                                </tr>
                            }
                            tablebody={
                                <>
                                    {data.map((item, index)=>{
                                        return (
                                        <tr key={index}>
                                            {item.transactionType? <td>{item.transactionType}</td>: unavailable}
                                            {item.transactionDate? <td>{item.transactionDate}</td>: unavailable}
                                            {item.filingDate? <td>{item.filingDate}</td>: unavailable}
                                            {item.securitiesTransacted? <td>{item.securitiesTransacted}</td>: unavailable}
                                            {item.price? <td>${item.price.toFixed(2)}</td>: unavailable}
                                            {item.securityName? <td>{item.securityName}</td>: unavailable}
                                            {item.reportingName? <td>{item.reportingName.toUpperCase()}</td>: unavailable}
                                            {item.typeOfOwner? <td>{item.typeOfOwner}</td>: unavailable}
                                            {item.link? <td><a style={{color: "var(--primary)"}}  target="_blank" rel="noopener noreferrer" href={item.link}>URL</a></td>: unavailable}
                                        </tr>
                                    )})}
                                </>
                            }

                        />
                    </WindowPieces.Content>
                    <WindowPieces.PageViewer
                        currentPage={params.page}
                        changePageFunc={changePage}
                        handlePageInput={handlePageInputChange}
                    />

                 
               
                    

              
                    
                </>
            )}
        </>
    );
};

export default InsiderTrading;