import React from "react";

const FigrIconWrapper = ({children, title}) => {
    return (
        <div className="figr-icon-wrapper">
            <div className="figr-icon" title={title}>
                {children}
            </div>
        </div>
    )
}

export default FigrIconWrapper