import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';
import getRootColorString from '../../../functions/helpers/getRootColorString';
const EarningsChart = ({ windowID, earningsData }) => {
    Chart.defaults.font.family = 'Inter';
    const textColor = getRootColorString("--text");
    const chartContainerRef = useRef(null);

    const titleTooltip = (tooltipItems) => {
        if (tooltipItems.length > 0) {
            return `Date: ${tooltipItems[0].label}`;
        }
    };

    const labelTooltip = (tooltipItem) => {
        return `${tooltipItem.dataset.label}: $${tooltipItem.parsed.y.toFixed(2)}`;
    };

    useEffect(() => {
        if (!earningsData || earningsData.length === 0) return;

        const labels = earningsData.map(item => item.date);
        const dataLength = earningsData.length;

        // Use indices for x values with padding
        const epsData = earningsData.map((item, index) => ({
            x: index / (dataLength - 1) * 80 + 10, // Spread from 10 to 90 instead of 0 to 100
            y: item.actualEarningResult,
            backgroundColor: item.actualEarningResult !== null && item.actualEarningResult < item.estimatedEarning 
                ? getRootColorString("--negative") 
                : getRootColorString("--positive"),
        })).filter(item => item.y !== null);

        const epsEstimatedData = earningsData.map((item, index) => ({
            x: index / (dataLength - 1) * 80 + 10, // Same spread as above
            y: item.estimatedEarning,
        })).filter(item => item.y !== null);

        const chart = new Chart(chartContainerRef.current, {
            type: 'scatter',
            data: {
                labels,
                datasets: [
                    {
                        label: 'Actual EPS',
                        radius: 6,
                        pointHitRadius: 2,
                        data: epsData,
                        borderColor: epsData.map(item => item.backgroundColor),
                        backgroundColor: epsData.map(item => item.backgroundColor),
                        borderWidth: 1,
                        showLine: false,
                        pointStyle: 'circle',
                    },
                    {
                        label: 'Estimated EPS',
                        radius: 6,
                        pointHitRadius: 2,
                        data: epsEstimatedData,
                        borderColor: textColor,
                        backgroundColor: 'transparent',
                        showLine: false,
                        pointStyle: 'circle',
                        pointBorderWidth: 1,
                    },
                ],
            },
            options: {
                animation: false,
                scales: {
                    x: {
                        type: 'linear',
                        min: 0,
                        max: 100,
                        ticks: {
                            // This is the key change
                            callback: (value) => {
                                // Convert the 0-100 scale value back to an index
                                const index = Math.round((value - 10) * (dataLength - 1) / 80);
                                if (index >= 0 && index < labels.length) {
                                    return labels[index];
                                }
                                return '';  // Return empty string for values outside our data range
                            },
                            color: textColor,
                            autoSkip: false,
                            maxRotation: 90,
                            minRotation: 45
                        },
                        grid: {
                            display: false,
                            color: '#444',
                        },
                        title: {
                            display: true,
                            text: 'Fiscal Date',
                            color: textColor,
                        },
                    },
                    y: {
                        ticks: {
                            color: textColor,
                            callback: (value) => `$${value.toFixed(2)}`
                        },
                        grid: {
                            display: true,
                            color: '#444',
                        },
                        title: {
                            display: true,
                            text: 'EPS',
                            color: textColor,
                        },
                    },
                },
                interaction: {
                    intersect: false,
                    mode: 'x',
                },
                plugins: {
                    htmlLegend: {
                        containerID: windowID + ' legend-container',
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        ...constants.CHARTJS_TOOLTIP_STYLE,
                        callbacks: {
                            title: titleTooltip,
                            label: labelTooltip
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            plugins: [constants.CHARTJS_HTML_LEGEND_PLUGIN],
        });

        return () => {
            chart.destroy();
        };
    }, [earningsData, windowID, textColor]);

    return (
        <>
            <div id={windowID + ' legend-container'}></div>
            <div className='position-relative' style={{ height: '300px' }}>
                <canvas ref={chartContainerRef}></canvas>
            </div>
        </>
    );
};

export default EarningsChart;