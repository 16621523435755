import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';
import ChartSettingsComponent from '../../ui/small/ChartSettingsComponent';
import CustomModal1 from '../../modals/CustomModal1';
import getRootColorString from '../../../functions/helpers/getRootColorString';
import SettingsConfigButton from '../../ui/small/buttons/SettingsConfigButton';

const LineChart = ({windowID, lineData}) => {
    Chart.defaults.font.family = 'Inter';
    
    const chartContainerRef = useRef(null);
    const textColor = getRootColorString("--text");
    const primaryColor = getRootColorString("--primary");

    useEffect(() => {
        if (!lineData || lineData.length === 0) return;

        // Format the data for ChartJS
        const formattedData = {
            labels: lineData.map(item => new Date(item.date).toLocaleDateString()),
            datasets: [{
                label: 'Value',
                data: lineData.map(item => item.value),
                borderColor: primaryColor,
                backgroundColor: primaryColor,
                tension: 0.4,
                pointRadius: 2,
                borderWidth: 2,
                fill: false
            }]
        };

        const chart = new Chart(chartContainerRef.current, {
            type: 'line',
            data: formattedData,
            options: {
                animation: false,
                scales: {
                    x: {
                        ticks: {
                            color: textColor,
                            maxRotation: 45,
                            minRotation: 45
                        },
                        grid: {
                            display: false,
                            color: '#444',
                        }
                    },
                    y: {
                        ticks: {
                            color: textColor
                        },
                        grid: {
                            color: 'rgba(255, 255, 255, 0.1)'
                        }
                    }
                },
                plugins: {
                    htmlLegend: {
                        containerID: windowID+' '+'legend-container',
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        ...constants.CHARTJS_TOOLTIP_STYLE,
                        callbacks: {
                            label: function(context) {
                                return `Value: ${context.parsed.y.toLocaleString()}`;
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    mode: 'index',
                    intersect: false
                }
            },
            plugins: [constants.CHARTJS_HTML_LEGEND_PLUGIN],
        });

        return () => {
            chart.destroy();
        };
    }, [lineData, windowID, textColor, primaryColor]);

    return (
        <>
            <div id={windowID+' '+'legend-container'}></div>
            <div className='position-relative' style={{ height: '100%', overflowX: "hidden", overflowY: "hidden" }}>
                <canvas ref={chartContainerRef}></canvas>
            </div>
        </>    
    );
};

export default LineChart;