import React from "react";
import constants from "../../constants";
import getRootColorString from "../../functions/helpers/getRootColorString";

function roundToNDecimals(number, n) {
    const factor = Math.pow(10, n); // 10 to the power of n
    const roundedNumber = Math.round(number * factor) / factor;
    return roundedNumber.toFixed(n); // Ensures `n` decimal places, including trailing zeros
}

const Percentage = ({value, numDecimals}) => {
    return (
        `${roundToNDecimals((value*100), numDecimals)}%`
    )
}

const NumberRounded = ({value, numDecimals}) => {
    return roundToNDecimals(value, numDecimals)
}

// const AccountingFormat = ({ value, numDecimals }) => { 
//     const formattedValue =
//       value < 0
//         ? `(${roundToNDecimals(Math.abs(value), numDecimals)})`
//         : roundToNDecimals(value, numDecimals);
  
//     const style = value < 0 ? { color: 'red' } : {};
  
//     return <span style={style}>{formattedValue}</span>;
//   };

const AccountingFormat = ({ value, numDecimals }) => { 
    const formatNumber = (number, decimals) => {
      // Round the number to the specified decimals
      const rounded = roundToNDecimals(number, decimals);
      // Format the number with commas
      return new Intl.NumberFormat('en-US', { 
        minimumFractionDigits: decimals, 
        maximumFractionDigits: decimals 
      }).format(rounded);
    };
  
    const formattedValue =
      value < 0
        ? `(${formatNumber(Math.abs(value), numDecimals)})`
        : formatNumber(value, numDecimals);
  
    const style = value < 0 ? { color: 'red' } : {};
  
    return <span style={style}>{formattedValue}</span>;
  };
  
  

const ConditionalColor = (value) => {
    if (value > 0) {
        return getRootColorString("--positive")
    }
    else if (value < 0) {
        return getRootColorString("--negative")
    }
    else {
        return 'white'
    }
}


export default { Percentage, NumberRounded, ConditionalColor, AccountingFormat }