import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constants from '../../../constants';
import WindowPieces from "../../ui/small/WindowPieces";
import MarketScannerAccordion from './ScannerAccordion';
import TVLWLineChartMultiSeries from '../../charting/tvlw/LineChartMultiSeries';
import WindowError from '../../misc/WindowError';
import LoadingSpinner from '../../misc/LoadingSpinner';
import FigrIconWrapper from "../../ui/small/FigrIconWrapper"
import showPremiumPrompt from '../../../functions/showPremiumPrompt';
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import Button2 from '../../ui/small/buttons/Button2';
import watchlistData from '../../../functions/memoryManagement/watchlistData';

const marketScannerAvailableSecurities = [
    { symbol: '^SPX', name: "S&P 500 Index", group: 'All', category: 'Popular Equity Indicies', checked: false },
    { symbol: '^DJI', name: "Dow Jones Industrial Average", group: 'All', category: 'Popular Equity Indicies', checked: false },
    { symbol: '^NDX', name: "Nasdaq 100 Index", group: 'All', category: 'Popular Equity Indicies', checked: false },
    { symbol: '^VIX', name: "Volatility Index", group: 'All', category: 'Popular Equity Indicies', checked: false },

    { symbol: 'XLY', name: 'Consumer Discretionary', securityName: 'Consumer Discretionary Select Sector SPDR Fund', group: 'All', category: 'Sector', checked: false },
    { symbol: 'XLP', name: 'Consumer Staples', securityName: 'Consumer Staples Select Sector SPDR Fund', group: 'All', category: 'Sector', checked: false },
    { symbol: 'XLE', name: 'Energy', securityName: 'Energy Select Sector SPDR Fund', group: 'All', category: 'Sector', checked: false },
    { symbol: 'XLF', name: 'Financial Services', securityName: 'Financial Select Sector SPDR Fund', group: 'All', category: 'Sector', checked: false },
    { symbol: 'XLV', name: 'Healthcare', securityName: 'Health Care Select Sector SPDR Fund', group: 'All', category: 'Sector', checked: false },
    { symbol: 'XLI', name: 'Industrials', securityName: 'Industrials Select Sector SPDR Fund', group: 'All', category: 'Sector', checked: false },
    { symbol: 'XLB', name: 'Materials', securityName: 'Materials Select Sector SPDR Fund', group: 'All', category: 'Sector', checked: false },
    { symbol: 'VNQ', name: 'Real Estate', securityName: 'Vanguard Real Estate ETF', group: 'All', category: 'Sector', checked: false },
    { symbol: 'QQQ', name: 'Technology', securityName: 'Invesco QQQ Trust Series I', group: 'All', category: 'Sector', checked: false },
    { symbol: 'VOX', name: 'Telecommunications', securityName: 'Vanguard Communication Services ETF', group: 'All', category: 'Sector', checked: false },
    { symbol: 'XLU', name: 'Utilities', securityName: 'Utilities Select Sector SPDR Fund', group: 'All', category: 'Sector', checked: false },

    { symbol: 'SPY', name: 'SPY', securityName: 'SPDR S&P 500 ETF Trust', group: 'Large-Cap', category: 'Asset Class Size', checked: false },
    { symbol: 'IVV', name: 'IVV', securityName: 'iShares Core S&P 500 ETF', group: 'Large-Cap', category: 'Asset Class Size', checked: false },
    { symbol: 'VOO', name: 'VOO', securityName: 'Vanguard S&P 500 ETF', group: 'Large-Cap', category: 'Asset Class Size', checked: false },
    { symbol: 'DIA', name: 'DIA', securityName: 'SPDR Dow Jones Industrial Average ETF Trust', group: 'Mega-Cap', category: 'Asset Class Size', checked: false },
    { symbol: 'MGK', name: 'MGK', securityName: 'Vanguard Mega Cap Growth ETF', group: 'Mega-Cap', category: 'Asset Class Size', checked: false },
    { symbol: 'VTWO', name: 'VTWO', securityName: 'Vanguard Russell 2000 ETF', group: 'Micro-Cap', category: 'Asset Class Size', checked: false },
    { symbol: 'IJS', name: 'IJS', securityName: 'iShares S&P Small-Cap 600 Value ETF', group: 'Micro-Cap', category: 'Asset Class Size', checked: false },
    { symbol: 'IJT', name: 'IJT', securityName: 'iShares S&P Small-Cap 600 Growth ETF', group: 'Micro-Cap', category: 'Asset Class Size', checked: false },

    { symbol: 'UCO', name: 'Crude Oil', securityName: 'ProShares Ultra Bloomberg Crude Oil', group: 'Energy', category: 'Commodities', checked: false },
    { symbol: 'BOIL', name: 'Natural Gas', securityName: 'ProShares Ultra Bloomberg Natural Gas', group: 'Energy', category: 'Commodities', checked: false },
    { symbol: 'UGA', name: 'Gasoline', securityName: 'United States Gasoline Fund LP', group: 'Energy', category: 'Commodities', checked: false },
    { symbol: 'DBE', name: 'Broad Energy', securityName: 'Invesco DB Energy Fund', group: 'Energy', category: 'Commodities', checked: false },
    { symbol: 'GLD', name: 'Gold', securityName: 'SPDR Gold Shares', group: 'Metals', category: 'Commodities', checked: false }, 
    { symbol: 'CPER', name: 'Copper', securityName: 'United States Copper Index Func', group: 'Metals', category: 'Commodities', checked: false },
    { symbol: 'CORN', name: 'Corn', securityName: 'Teucrium Corn Fund', group: 'Metals', category: 'Commodities', checked: false },
    { symbol: 'PALL', name: 'Palladium', securityName: 'Abrdn Physical Palladium Shares ETF', group: 'Metals', category: 'Commodities', checked: false },
    { symbol: 'PPLT', name: 'Platinum', securityName: 'Abrdn Physical Platinum Shares ETF', group: 'Metals', category: 'Commodities', checked: false },
    { symbol: 'SLV', name: 'Silver', securityName: 'iShares Silver Trust', group: 'Metals', category: 'Commodities', checked: false },
    { symbol: 'DBB', name: 'Broad Industrial Metals', securityName: 'Invesco DB Base Metals Fund', group: 'Metals', category: 'Commodities', checked: false },
    { symbol: 'GLTR', name: 'Broad Precious Metals', securityName: 'Abrdn Physical Precious Metals Basket Shares ETF', group: 'Metals', category: 'Commodities', checked: false },
    { symbol: 'SOYB', name: 'Soybeans', securityName: 'Teucrium Soybean Fund', group: 'Agriculture', category: 'Commodities', checked: false },
    { symbol: 'CANE', name: 'Sugar', securityName: 'Teucrium Sugar Fund', group: 'Agriculture', category: 'Commodities', checked: false },
    { symbol: 'WEAT', name: 'Wheat', securityName: 'Teucrium Wheat Fund', group: 'Agriculture', category: 'Commodities', checked: false },
    { symbol: 'DBA', name: 'Broad Agriculture', securityName: 'Invesco DB Agriculture Fund', group: 'Agriculture', category: 'Commodities', checked: false },

    { symbol: 'ARGT', name: 'Argentina', securityName: 'Global X MSCI Argentina ETF', group: 'Latin America', category: 'Countries', checked: false },
    { symbol: 'EWZ', name: 'Brazil', securityName: 'iShares MSCI Brazil ETF', group: 'Latin America', category: 'Countries', checked: false },
    { symbol: 'ECH', name: 'Chile', securityName: 'iShares MSCI Chile ETF', group: 'Latin America', category: 'Countries', checked: false },
    { symbol: 'GXG', name: 'Columbia', securityName: 'Global X MSCI Columbia ETF', group: 'Latin America', category: 'Countries', checked: false },
    { symbol: 'ILF', name: 'Broad Latin America', securityName: 'iShares Latin America 40 ETF', group: 'Latin America', category: 'Countries', checked: false },
    { symbol: 'SPY', name: 'United States', securityName: 'SPDR S&P 500 ETF Trust', group: 'North America', category: 'Countries', checked: false },
    { symbol: 'EWC', name: 'Canada', securityName: 'iShares MSCI Canada ETF', group: 'North America', category: 'Countries', checked: false },
    { symbol: 'EWW', name: 'Mexico', securityName: 'iShares MSCI Mexico ETF', group: 'North America', category: 'Countries', checked: false },
    { symbol: 'EWU', name: 'United Kingdom', securityName: 'iShares MSCI United Kingdom ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'EWG', name: 'Germany', securityName: 'iShares MSCI Germany ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'EWL', name: 'Switzerland', securityName: 'iShares MSCI Switzerland ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'EWI', name: 'Italy', securityName: 'iShares MSCI Italy ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'EWQ', name: 'France', securityName: 'iShares MSCI France ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'EWD', name: 'Sweden', securityName: 'iShares MSCI Sweden ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'EDEN', name: 'Denmark', securityName: 'iShares MSCI Denmark ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'GREK', name: 'Greece', securityName: 'Global X MSCI Greece ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'VGK', name: 'Broad Europe', securityName: 'Vanguard FTSE Europe ETF', group: 'Europe', category: 'Countries', checked: false },
    { symbol: 'EWJ', name: 'Japan', securityName: 'iShares MSCI Japan ETF', group: 'Asia', category: 'Countries', checked: false },
    { symbol: 'EWT', name: 'Taiwan', securityName: 'iShares MSCI Taiwan ETF', group: 'Asia', category: 'Countries', checked: false },
    { symbol: 'EWY', name: 'South Korea', securityName: 'iShares MSCI South Korea ETF', group: 'Asia', category: 'Countries', checked: false },
    { symbol: 'EWA', name: 'Australia', securityName: 'iShares MSCI Australia ETF', group: 'Asia', category: 'Countries', checked: false },
    { symbol: 'ENZL', name: 'New Zealand', securityName: 'MSCI New Zealand All Cap Top 25 Capped Index', group: 'Asia', category: 'Countries', checked: false },
    { symbol: 'IPAC', name: 'Broad Developed Asia', securityName: 'iShares Core MSCI Pacific ETF', group: 'Asia', category: 'Countries', checked: false },

    { symbol: 'VCIT', name: 'Corporate Intermediate-Term', securityName: 'Vanguard Intermediate-Term Corporate Bond ETF', group: 'Intermediate-Term', category: 'Fixed Income', checked: false },
    { symbol: 'VGIT', name: 'Treasury Intermediate-Term', securityName: 'Vanguard Intermediate-Term Treasury ETF', group: 'Intermediate-Term', category: 'Fixed Income', checked: false },
    { symbol: 'BIV', name: 'Bond Intermediate-Term', securityName: 'Vanguard Intermediate-Term Bond ETF', group: 'Intermediate-Term', category: 'Fixed Income', checked: false },
    { symbol: 'VCLT', name: 'Corporate Long-Term', securityName: 'Vanguard Long-Term Corporate Bond ETF', group: 'Long-Term', category: 'Fixed Income', checked: false },
    { symbol: 'VGLT', name: 'Treasury Long-Term', securityName: 'Vanguard Long-Term Treasury ETF', group: 'Long-Term', category: 'Fixed Income', checked: false },
    { symbol: 'BLV', name: 'Bond Long-Term', securityName: 'Vanguard Long-Term Bond ETF', group: 'Long-Term', category: 'Fixed Income', checked: false },
    { symbol: 'VGSH', name: 'Treasury Short-Term', securityName: 'Vanguard Short-Term Treasury ETF', group: 'Short-Term', category: 'Fixed Income', checked: false },
    { symbol: 'BSV', name: 'Bond Short-Term', securityName: 'Vanguard Short-Term Bond ETF', group: 'Short-Term', category: 'Fixed Income', checked: false },

];

const mergeWatchlistWithSecurities = async () => {
    // Load watchlist data
    const loadedWatchlistData = await watchlistData.loadWatchlist();
  
    
    // Transform watchlist data into the market scanner format
    const watchlistSecurities = Object.entries(loadedWatchlistData).map(([groupName, symbols]) => 
        symbols.map(symbol => ({
            symbol,
            name: symbol,  // Using symbol as name since watchlist might not have names
            securityName: symbol,
            group: groupName,
            category: 'Watchlists',
            checked: false
        }))
    ).flat();
   
    return [...marketScannerAvailableSecurities, ...watchlistSecurities];
};



const MarketScanner = ({windowID, initialSymbolsList = [] }) => {

    const DEFAULT_PARAMS ={
        symbols: initialSymbolsList,
        period: "1Y",
    }
    const ENDPOINT = '/market-scanner/';
    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    const [params, setParams] = useState(paramsToUse)

    const [stockData, setStockData] = useState(null)
    const [selectedPeriod, setSelectedPeriod] = useState('1D')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [isAccordionOpen, setIsAccordionOpen] = useState(false)
    const [marketScannerSecuritiesData, setMarketScannerSecuritiesData] = useState([]);

    const pollingInterval = useRef(null)
    const contentContainerRef = useRef();

    useEffect(() => {
        const loadSecuritiesData = async () => {
            try {
                const combinedSecurities = await mergeWatchlistWithSecurities();
                setMarketScannerSecuritiesData(
                    combinedSecurities.map(security => ({
                        ...security,
                        checked: initialSymbolsList.includes(security.symbol)
                    }))
                );
            } catch (error) {
                console.error("Error loading securities data:", error);
                setMarketScannerSecuritiesData(marketScannerAvailableSecurities)
            }
        };

        loadSecuritiesData();
    }, []);


    const handleCheckboxChange = (isChecked, symbol) => {
        setParams((prevParams) => {
            const updatedSymbols = isChecked
                ? [...prevParams.symbols, symbol]
                : prevParams.symbols.filter((item) => item !== symbol);
   
            return {
                ...prevParams,
                symbols: updatedSymbols,
            };
        });
    };

    const updateCheckedSecurities = (checkedSecurities, marketScannerAvailableSecurities) => {
        return marketScannerAvailableSecurities.map(security => ({
            ...security,
            checked: checkedSecurities.includes(security.symbol), // Set 'checked' based on matching symbols
        }));
    };

    const clearSymbols = ()=>{
        setParams((prevParams)=> {
            return {
                ...prevParams,
                symbols: []
            }
        })
    }

    
    const requestData = () => {
        setLoading(true); 
        setError(null);

        updateLayoutItem(windowID, {
            symbol:  "N/A",
            windowParameters: params,
        });
        const layout = getGlobalLayout();
        dashboardLayout.saveLayout(layout);
        

        constants.api.post(constants.BACKEND_URL + ENDPOINT, params)
            .then((response) => {
                const responseData = response.data

                const status = responseData.status
                const data = responseData.data

                
                if (status === "error" && responseData.code && responseData.code === "ACCESS_DENIED") {
                    setParams(prevParams => ({
                        ...prevParams,
                        symbols: prevParams.symbols.slice(0, -1)
                    }));
                    setError(responseData.message);
                    showPremiumPrompt(responseData.message)

                } else if (status === "success") {
                    setStockData(data);
                }
            })
            .catch((error) => {
                console.error('Error during POST request:', error);
                setError("Failed to load data.");
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });
    };
        
    useEffect(() => {
        requestData(); 
    }, [params]);

    const updateParams = (e) => {
        const { id, value } = e.target;
    
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const colors = constants.getColorPallete()

    return (
        <div className="p-0 w-100 h-100 d-flex flex-row">
            {isAccordionOpen && (
                <div 
                className='p-2 d-flex flex-column justify-content-between' 
                style={{
                    borderRight: "1px solid var(--secondary)", 
                    width: `${isAccordionOpen? '30%': '0px'}`,
                    maxWidth: "300px",
                    minWidth: "200px",
                    
                    }}>
                    <MarketScannerAccordion 
                        data={marketScannerSecuritiesData} 
                        checkedArray={params.symbols}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                    <WindowPieces.SquareSelect
                        id={'period'}
                        valueList={['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y', '3Y', '5Y', '10Y']} 
                        innerHTMLList={['Timeframe: 1D', 'Timeframe: 5D', 'Timeframe: 1M', 'Timeframe: 3M', 'Timeframe: 6M','Timeframe: YTD', 'Timeframe: 1Y', 'Timeframe: 3Y', 'Timeframe: 5Y', 'Timeframe 10Y']}
                        value={params.period}
                        onChange={updateParams}
                        fullWidth={true}

                    />
                    <div className='my-2 w-100'>
                        <Button2 fullWidth={true} onClick={()=>clearSymbols()}>Clear Scanner</Button2>
                    </div>
                </div>
            )}
            <WindowPieces.ChartContainer ref={contentContainerRef}>
            {loading ? (
                <LoadingSpinner/>
            ) : (
                <div className='position-relative h-100'>
                    <div title="Toggle Open/Close Market Scanner Accordion" style={{position: 'absolute', top: '0px', left: '0px', margin: "0.5em", zIndex: '3'}}>
                        <FigrIconWrapper>
                            {isAccordionOpen ? (
                                <svg onClick={()=>setIsAccordionOpen(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                                </svg>
                            ) : (
                                <svg onClick={()=>setIsAccordionOpen(true)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                </svg>
                            )}
                        </FigrIconWrapper>
                    </div>
                   
                    {(stockData && Object.keys(stockData).length > 0) ?  (
                        <>
                            <div className='d-flex flex-row' style={{position: 'absolute', top: '0px', left: '35px', margin: "0.5em", zIndex: '3', color: "var(--text)"}}>
                            <div style={{border: "1px solid var(--secondary)", padding: "0.5em", borderRadius: "0px"}}>
                                {params.symbols.map((symbol, index)=>(
                                    <div className='d-flex flex-row align-items-center'>
                                        <div style={{height: "10px", width: "10px", backgroundColor: colors[index % colors.length]}}></div>
                                        <div className='ms-2'>{marketScannerSecuritiesData.find(security=>security.symbol===symbol)?.name}</div>
                                    </div>
                                ))}
                            </div>
                            {/* <div className='ms-3' style={{color: "var(--secondary)", fontSize: "1.5em"}}>Timeframe: {params.period}</div> */}
                        </div>
                        <TVLWLineChartMultiSeries 
                            params={params} 
                            seriesData={stockData} 
                            contentContainerRef={contentContainerRef}/>
                        </>
                    ): <WindowError message={"No Securities Selected..."}/>}
                </div>  
                )}
            </WindowPieces.ChartContainer>
        </div>
    );
};

export default MarketScanner;