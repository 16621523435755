import React from 'react';
import Button2 from '../ui/small/buttons/Button2';

function WindowErrorReload({message, reload}) {
    return (
        <div style={{fontSize: "14px", color: "var(--negative-c)", backgroundColor: "var(--theme)"}} className='d-flex flex-column justify-content-center fw-bold align-items-center w-100 h-100 p-5'>
            <div>{message}</div>
            <Button2 onClick={reload}>Reload</Button2>
        </div>
    )
}

export default WindowErrorReload

