import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';

const PieChart = ({ data }) => {

    Chart.defaults.font.family =  'Inter'
    const chartContainerRef = useRef(null);
    const colors = constants.getColorPallete()


    useEffect(() => {
       

        const filteredData = data.filter(item => item.exposure > 0);
      

        const chart = new Chart(chartContainerRef.current, {
            type: 'pie',
            data: {
                labels: filteredData.map(item => item.industry),
                datasets: [{
                    data: filteredData.map(item => item.exposure),
                    backgroundColor: colors,
                    hoverOffset: 4,
                    borderWidth: 1,
                }]
            },
            options: {
                animation: false,
                responsive: true,
                plugins: {
                legend: {
                    display: false,
                    position: 'right',
                },
                tooltip: {
                    callbacks: {
                    label: function(context) {
                        return `${context.label}: ${context.raw.toFixed(2)}%`;
                    }
                    }
                }
                }
            }
        });

        return () => {
            chart.destroy();
        };
    }, [data]);

    return (
        <canvas ref={chartContainerRef} style={{ width: '200px', height: '300px'}}></canvas>
    );
};

export default PieChart;