import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import constants from '../../../constants';
import getRootColorString from '../../../functions/helpers/getRootColorString';



const TVLWLineChartMultiSeries = ({ seriesData, params, contentContainerRef }) => {
    /////////////////////////////////////////////////////////////////////////// CHART SETTINGS STATES AND HELPER FUNCTIONS
    const [currentSettings, setCurrentSettings] = useState({
        chartBackground: getRootColorString("--theme"),
        textColor: getRootColorString("--text"),
        lineColor: getRootColorString("--primary"),
        showArea: true,
        showGridLines: false,
        gridColor: 'white',
        showWatermark: true,
        watermarkColor: getRootColorString("--text")
    })
    const [adjustedSettings, setAdjustedSettings] = useState({
    })

    const setAdjustedSetting = (key, value) => {
        setAdjustedSettings((prev) => ({
            ...prev,
            [key]: value,
        }));
    };
    
    const chartContainerRef = useRef();
    const chartRef = useRef(null); // Ref for chart instance
    const multiLineSeriesRef = useRef(null); // Ref for chart instance
    const pollingInterval = useRef(null);
    let resizeAnimationFrame;

    const [multiLineSeriesData, setMultiLineSeriesData] = useState(seriesData);
    
    const [tooltipValues, setTooltipValues] = useState({time: null, value: null})

    const colors = constants.getColorPallete()

    const initializeChart = () => {
        const chart = createChart(chartContainerRef.current, {
            rightPriceScale: {
                scaleMargins: {
                    top: 0.4, // leave some space for the legend
                    bottom: 0.15,
                },
            },
            
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            



            handleScroll: false, // Disable horizontal and vertical dragging
            handleScale: {
                axisPressedMouseMove: false, // Prevent scaling while dragging the axis
                pinch: false, // Prevent scaling with pinch gestures
                mouseWheel: false, // Prevent scaling with the mouse wheel
            },
            

            timeScale: {
                timeVisible: true,
                secondsVisible: true,
                rightOffset: 30,
                barSpacing: 10,
                fixLeftEdge: true,
                fixRightEdge: false,
                handleScroll: false,
                handleScale: false,
            },
            crosshair: {
                mode: 0, // Disable crosshair movement beyond series
            },
            layout: {
                background: { color: currentSettings.chartBackground },
                textColor: currentSettings.textColor,
                fontFamily: 'Inter',
            },
            grid: {
                vertLines: { visible: currentSettings.showGridLines, color: currentSettings.gridColor },
                horzLines: { visible: currentSettings.showGridLines, color: currentSettings.gridColor },
            },
            rightPriceScale: {
                minimumWidth: 30,
                visible: true,
                
            },
        });

        
        const multiLineSeries = Object.entries(seriesData).map(([symbol, data], index)=>{
            const lineSeries = chart.addLineSeries({
                color: colors[index % colors.length],
                lineWidth: 1,
                title: symbol,
                priceLineVisible: false,
                priceFormat: {
                    type: "percent"
                }
            });
            // lineSeries.setData(data)
            return lineSeries
        })

        multiLineSeriesRef.current=multiLineSeries
        chartRef.current = chart; // Store chart in the ref


        ////////////// CANDLESTICK TOOLTIP
        const getLastBar = series => {
     
            const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
            return series.dataByIndex(lastIndex);
        };
        const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);
        const setTooltip = (time, open, high, low, close) => {
            setTooltipValues({
                time: time,
                open: formatPrice(open),
                high: formatPrice(high),
                low: formatPrice(low),
                close: formatPrice(close)
            })
        };

     








        return multiLineSeries;
    };

    const handleResize = () => {
        if (!chartRef.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (entries.length === 0 || entries[0].target !== contentContainerRef.current) return;
            const { width, height } = entries[0].contentRect;

            if (height > 0 && width > 0) { // Only resize if dimensions are valid
                if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
                resizeAnimationFrame = requestAnimationFrame(() => {
                    chartRef.current.resize(width, height - 5);
                    chartRef.current.timeScale().fitContent();
                });
            }
        });

        resizeObserver.observe(contentContainerRef.current);

        return () => {
            resizeObserver.disconnect();
            if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
        };
    };


    
    useEffect(() => {
        const multiLineSeries = initializeChart();

        multiLineSeries.forEach((series, index) => {
            const symbol = Object.keys(multiLineSeriesData)[index]; // Get the corresponding key (symbol)
            if (symbol && multiLineSeriesData[symbol]) {
                series.setData(multiLineSeriesData[symbol]); // Set the data for this series
                
            }
        });

        multiLineSeries[0].createPriceLine({
            price: 0,
            color: getRootColorString("--text")
        })


        chartRef.current.timeScale().fitContent();
        const fetchData =() => {
            constants.api.post(constants.BACKEND_URL+'/market-scanner/', params)
                .then(response => {
                    const responseData = response.data
    
                    const status = responseData.status
                    const data = responseData.data
                    
                    if (status === 'success') {
                        setMultiLineSeriesData(data); // Update state
                        // if (seriesRef.current) {
                            //     seriesRef.current.setData(data); // Replace the entire series
                            // }
                        }    
                        
                    })
                .catch(error => {
                    console.error('Error during POST request:', error);
                });
            }
        fetchData()

        pollingInterval.current = setInterval(fetchData, 60000); 
        const cleanupResize = handleResize();


        return () => {
            cleanupResize();
            chartRef.current.remove();
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current); 
            }
        };
    }, [currentSettings, seriesData]);

    useEffect(()=>{ 
        if (multiLineSeriesData && chartRef.current) {
            multiLineSeriesRef.current.forEach((series,index)=>{
            const symbol = Object.keys(multiLineSeriesData)[index]; // Get the corresponding key (symbol)
            if (symbol && multiLineSeriesData[symbol]) {
                series.setData(multiLineSeriesData[symbol]); // Set the data for this series
            }
            })
        }
    }, [multiLineSeriesData])


    return (
        <>
            <div className='h-100 w-100' ref={chartContainerRef}></div>
        </>
    )
};

export default TVLWLineChartMultiSeries;